import { set } from '../../../fp/object';
import { ofType } from '../../../frp/operators/ofType';
import { combineWithLatestFrom } from '../../../frp/operators/combineWithLatestFrom';
import { withLatestValuesFrom } from '../../../frp/operators/withLatestValuesFrom';
import { map, mergeMap, pluck, filter } from 'rxjs/operators';
import { lookup } from 'services/staffs/xe-staffs-svc';
import { toMenuNodeRequestFn } from '../../../operators/toMenuNodeRequestFn';
import { toCreateRequest$Epic } from '../../../staffData/epics';
import {
  DID_UPDATE_NEW_PROVIDER,
  ON_PROVIDER_CHANGE,
  RESPONSE_SEARCH_EXISTING_PROVIDERS,
  SHOULD_SEARCH_EXISTING_PROVIDERS,
  SHOULD_SELECT_PROVIDER_MATCH,
  SHOULD_UPDATE_STAFF,
  SHOULD_UPDATE_STAFF_CATEGORY,
} from './actions';

export const toSearchStaffEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_SEARCH_EXISTING_PROVIDERS),
    pluck('value'),
    filter(
      ({ FamilyName, GivenName } = {}) =>
        FamilyName &&
        GivenName &&
        FamilyName.length >= 2 &&
        GivenName.length >= 2
    ),
    combineWithLatestFrom(state$.pipe(toMenuNodeRequestFn())),
    mergeMap(([{ FamilyName = '', GivenName = '' }, toRequest$]) => {
      return lookup(
        { FamilyName, GivenName, IncludeNPI: true, IncludeAll: false },
        {},
        toRequest$()
      );
    }),
    map((results) => ({
      type: RESPONSE_SEARCH_EXISTING_PROVIDERS,
      value: results,
    }))
  );
};

export const toSetProviderToSelectedMatchEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_SELECT_PROVIDER_MATCH),
    pluck('value'),
    map((providerMatch) => {
      const {
        AccountID,
        StaffID,
        XeStaffSpecialty = [],
        XeStaffAddress = [],
        FamilyName,
        GivenName,
        NationalProviderID,
        EnterpriseID,
      } = providerMatch;

      return {
        type: DID_UPDATE_NEW_PROVIDER,
        value: {
          AccountID,
          StaffID,
          FamilyName,
          GivenName,
          EnterpriseID,
          NationalProviderID,
          XeStaffSpecialty: XeStaffSpecialty,
          XeStaffAddress: XeStaffAddress[0] || {},
          ResourceTypeID: 'STAFF',
        },
      };
    })
  );
};

const toUpdateProviderCategoryEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_UPDATE_STAFF_CATEGORY),
    pluck('value'),
    map((value) => {
      const newStaffID = value === 'FACILITY' ? { FamilyName: '(F)' } : {};
      return {
        type: DID_UPDATE_NEW_PROVIDER,
        value: {
          ...newStaffID,
          ResourceTypeID: value,
        },
      };
    })
  );
};

const toUpdateProviderEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_UPDATE_STAFF),
    pluck('value'),
    withLatestValuesFrom(state$, 'StaffID'),
    map(([{ path, value }, StaffID]) => {
      return {
        type: DID_UPDATE_NEW_PROVIDER,
        value: set(path, value, StaffID),
      };
    })
  );
};

export const dispatchProviderInformationChangedEpic$ = (action$) => {
  return action$.pipe(
    ofType(DID_UPDATE_NEW_PROVIDER),
    pluck('value'),
    map((value) => ({ type: ON_PROVIDER_CHANGE, value }))
  );
};

export default [
  toSearchStaffEpic$,
  toSetProviderToSelectedMatchEpic$,
  toUpdateProviderCategoryEpic$,
  toUpdateProviderEpic$,
  dispatchProviderInformationChangedEpic$,
  /* xnetjs create/find epic */
  toCreateRequest$Epic,
];
