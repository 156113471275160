import isObject from 'lodash/fp/isObject';
import unset from 'lodash/fp/unset';
import update from 'lodash/fp/update';
import isEqual from 'lodash/fp/isEqual';

import { get, set } from '../fp/object';
import { isEmpty } from '../fp/pred';

import { getSingletonAJVInstance } from './jsonSchemaValidator';
import { apply as merge } from './mergePatch';
import { schemaSet, schemaUpdate } from './schemaTypeBuilder';
import { combineReducers, identity } from '../fp/fp';

export const META_SCHEMA = 'metaSchema';

export const INSTANCE = 'instance';
export const CHANGED_PROPS = 'changedProps';
export const SCHEMA_VALIDATION_PROPS = 'schemaValidatedProps';
export const VALIDITY = 'validity';
export const VALID = 'valid';
export const CHANGED = 'changed';
export const DEFAULT_STATE = 'defaultState';
export const COMPARISON_STATE = 'comparisonState';
export const SCHEMA_STATE = 'schemaState';

export const MERGE_PATCH = 'MERGE_PATCH';
export const UPSERT = 'UPSERT';
export const DELETE = 'DELETE';
export const RESET_TO_INITIAL = 'RESET_TO_INITIAL';
//Rename me
export const REVERSE_MERGE_PATCH = 'REVERSE_MERGE_PATCH';

/**General, candidates to move */
const treeMap =
  (projection = identity) =>
  (o) => {
    if (Array.isArray(o)) {
      return o.map((value, i, a) => {
        const result = projection(value, i, a);
        if (isObject(result) || Array.isArray(result)) {
          return treeMap(projection)(result);
        }

        return result;
      });
    } else {
      return Object.entries(o).reduce((acc, [key, value]) => {
        const result = projection(value, key, o);
        if (isObject(result) || Array.isArray(result)) {
          return {
            ...acc,
            [key]: treeMap(projection)(result),
          };
        }

        return {
          ...acc,
          [key]: result,
        };
      }, {});
    }
  };

const recursivelyUnset = (path, object) => {
  if (path === undefined) {
    return Array.isArray(object) ? [] : {};
  }
  const segments = path.split('.');
  const s1 = unset(path, object);

  if (segments.length == 1) {
    return s1;
  }

  const shorterPath = segments.slice(0, -1).join('.');
  const includingObject = get(shorterPath, s1);

  if (!onlyDefinesSchema(includingObject)) {
    return s1;
  }

  return recursivelyUnset(shorterPath, s1);
};

const isScoped = (scope /*dont default scope please*/, { type = '' }) => {
  return type.startsWith(scope);
};

const toScopedReducer = (scope, reducer) => {
  return (state, action) => {
    const { type } = action;
    if (!isScoped(scope, action)) return state;

    return reducer(state, { ...action, type: type.replace(`${scope}/`, '') });
  };
};

const toMissingKeys = (s0 = {}, s1 = {}) => {
  const s0Entries = Object.entries(s0);
  const s1Entries = Object.entries(s1);

  return s0Entries.reduce((missing, [s0Key, s0Value]) => {
    const found = s1Entries.find(([s1Key]) => s1Key === s0Key);
    if (found) return missing;

    if (isObject(s0Value) || Array.isArray(s0Value)) {
      const nestedMissingKeys = toMissingKeys(s0Value);
      if (Object.keys(nestedMissingKeys).length == 0) return missing;

      return {
        ...missing,
        [s0Key]: nestedMissingKeys,
      };
    }

    return {
      ...missing,
      [s0Key]: true,
    };
  }, {});
};

const equals = (x, y) => x == y;
//trying to make an exhaustive merge with
const toChangedTree = (obj1 = {}, obj2 = {}, compare = equals) => {
  const obj1Entries = Object.entries(obj2);
  const missingObj2Keys = toMissingKeys(obj1, obj2);

  return obj1Entries.reduce((tree, [key, value]) => {
    if (compare(obj1[key], value)) {
      return tree;
    }

    if (isObject(value) || Array.isArray(value)) {
      const nestedTree = toChangedTree(obj1[key], value, compare);
      if (Object.keys(nestedTree).length == 0) return tree;

      return {
        ...tree,
        [key]: nestedTree,
      };
    }

    return {
      ...tree,
      [key]: true,
    };
  }, missingObj2Keys);
};
/**** */

const rootPathReducer = (state, { type, value } = {}) => {
  const lastValue = state;
  if (lastValue == value) {
    return state;
  }

  if (type === DELETE) return undefined;
  if (type === UPSERT) return { ...value };
  if (type === MERGE_PATCH) return merge(state, value);

  if (type === REVERSE_MERGE_PATCH) {
    return merge(value, state);
  }

  return state;
};
export const toVerbActionReducer =
  (initialState = {}) =>
  (state = initialState, { type, path, value } = {}) => {
    if (path === undefined) return rootPathReducer(state, { type, value });
    if (type === DELETE) return recursivelyUnset(path, state);

    const lastValue = get(path, state);
    if (lastValue == value) {
      return state;
    }

    if (type === UPSERT) return set(path, value, state);
    if (type === MERGE_PATCH)
      return update(path, (prev) => merge(prev, value), state);
    // if (type === RESET_TO_INITIAL)
    //   return set(path, get(path, initialState), state);

    if (type === REVERSE_MERGE_PATCH) {
      return update(path, (prev = {}) => merge(value, prev), state);
    }

    return state;
  };

export const toMetaSchemaReducer =
  (initialState = {}) =>
  (state = initialState, { type, path, value } = {}) => {
    if (path === undefined) return rootPathReducer(state, { type, value });
    if (type === DELETE) return recursivelyUnset(path, state);

    const { [path]: lastValue } = state;

    if (lastValue == value) {
      return state;
    }

    if (type === UPSERT) {
      return {
        ...state,
        [path]: value,
      };
    }

    if (type === MERGE_PATCH)
      return update(path, (prev) => merge(prev, value), state);
    // if (type === RESET_TO_INITIAL)
    //   return set(path, get(path, initialState), state);

    if (type === REVERSE_MERGE_PATCH) {
      return update(path, (prev = {}) => merge(value, prev), state);
    }

    return state;
  };

export const toSchemaVerbActionReducer = (schema) => {
  return (initialState = {}) =>
    (state = initialState, { type, path, value } = {}) => {
      if (path === undefined) return rootPathReducer(state, { type, value });
      if (type === DELETE) return recursivelyUnset(path, state);

      const lastValue = get(path, state);
      if (lastValue == value) {
        return state;
      }

      if (value === '' || value === undefined) {
        const initialValue = get(path, initialState);
        //This is the case where it started undefined... someone typed a space... then got rid of it
        if (initialValue === undefined) {
          return recursivelyUnset(path, state);
        }
      }

      if (type === UPSERT) return schemaSet(schema, path, value, state);
      if (type === MERGE_PATCH)
        return schemaUpdate(schema, path, (prev) => merge(prev, value), state);
      // if (type === RESET_TO_INITIAL)
      //   return set(path, get(path, initialState), state);

      if (type === REVERSE_MERGE_PATCH) {
        return schemaUpdate(
          schema,
          path,
          (prev = {}) => merge(value, prev),
          state
        );
      }

      return state;
    };
};

const toDerivedChangedReducer = (comparisonState = {}) => {
  return (changedState, { type, value, path }) => {
    if (path === undefined)
      return rootPathReducer(changedState, { type, value });

    const prevChanged = get(path, changedState);
    const initialValue = get(path, comparisonState);
    //    const defaultValue = get(path, defaults);

    if ((value === '' || value === undefined) && initialValue === undefined) {
      //This is the case where it started undefined... someone typed a space... then got rid of it
      return recursivelyUnset(path, changedState);
    }

    //const atInitialOrDefault = (initialValue === value) || (initialValue === undefined && value === defaultValue);
    const nextChanged = !isEqual(initialValue, value);

    if (prevChanged === nextChanged) return changedState;

    return nextChanged
      ? set(path, nextChanged, changedState)
      : recursivelyUnset(path, changedState);
  };
};

const toDerivedValidationReducer = (validator) => {
  return (schemaState, instanceState = undefined) => {
    const { '': topLevelSchema = {}, ...rest } = schemaState;

    const { baseSchema } = topLevelSchema;

    return Object.entries(rest).reduce((validationState, [path, schema]) => {
      const { baseSchema: nestedBaseSchema } = schema;
      const valueAtPath = get(path, instanceState);
      if (valueAtPath === undefined) {
        return validationState;
      }

      const nestedResult = toSchemaValidityState(
        validator,
        nestedBaseSchema,
        valueAtPath
      );

      if (Object.keys(nestedResult).length > 0) {
        return set(path, nestedResult, validationState);
      } else {
        return recursivelyUnset(path, validationState);
      }
    }, toSchemaValidityState(validator, baseSchema, instanceState));
  };
};

const derivedDefaultStateReducer = (schemaState) => {
  return Object.entries(schemaState).reduce((acc, [path, schema]) => {
    const defaultState = toDefaultedObjectFromSchema(schema);
    if (path == '') {
      return { ...acc, ...defaultState };
    }
    return set(path, defaultState, acc);
  }, {});
};

const toSchemaValidityState = (validator, baseSchema, instance) => {
  validator.validate(baseSchema, instance);

  const { errors } = validator;

  const validityState = (errors || []).reduce((validityState, errObj) => {
    const {
      instancePath,
      /*message,*/ params: { property, missingProperty } = {},
    } = errObj;
    const prop = missingProperty || property || '';
    const path = [instancePath.slice(1), prop].filter((x) => x).join('.');
    return set(path, errObj, validityState);
  }, {});

  return validityState;
};

const justMessages = (o = {}) => {
  const { message } = o;

  return message !== undefined ? message : o;
};

export const toValidityState = (...validityStates) => {
  const mapped = validityStates.map(treeMap(justMessages));
  return mapped.reduce((acc, state) => {
    return merge(acc, state);
  });
};

const onlyDefinesSchema = (o) => {
  return (
    o === undefined ||
    isEmpty(o) ||
    (Object.keys(o).length === 1 && o['$schema'] !== undefined)
  );
};

const toDefaultedObjectFromSchema = (schema) => {
  const propertiesAggregate = (schema) => {
    const {
      baseSchema: { properties, ['$id']: id } = {},
      toReferencedSchemas,
    } = schema;
    const references = toReferencedSchemas();
    return Object.entries(properties).reduce(
      (o, [path, declaration]) => {
        const { ['$ref']: ref, defaultValue } = declaration;
        if (defaultValue !== undefined) {
          return set(path, defaultValue, o);
        } else if (ref !== undefined) {
          const withoutHash = ref.slice(0, -1);
          const innerSchema = references.find(
            ({ name }) => name === withoutHash
          );
          if (!innerSchema) {
            console.error(
              'unreferenced innner schema during property defaulting'
            );
          }
          const innerProps = propertiesAggregate(innerSchema);
          if (!onlyDefinesSchema(innerProps)) {
            return set(path, innerProps, o);
          }
          return o;
        }
        return o;
      },
      { $schema: id }
    );
  };
  return propertiesAggregate(schema);
};

export const toJSONSchemaReducer = (
  initialValue,
  schema = {},
  baseValidator = getSingletonAJVInstance()
) => {
  const { baseSchema, registerSchema } = schema;

  const validator = registerSchema(baseValidator);

  const defaultState = toDefaultedObjectFromSchema(schema);

  //Here we can calculate default values
  //consider if we want to do validation once
  const schemaInstanceState = merge(defaultState, initialValue);

  const baseSchemaState = { '': schema };
  const schemaValidityState = toSchemaValidityState(
    validator,
    baseSchema,
    schemaInstanceState
  );

  const validityState = toValidityState(schemaValidityState);

  //all of the things we were doing before
  const initialState = {
    //We are going to have to duplicate some code to get a valid initial state
    //I am not sure how, in the redux world we are going to get an initial reduction
    //to the invalid properties so that our startup state is right
    //May move this merge later and keep these distinct.... right now need someway to handle
    [INSTANCE]: schemaInstanceState,
    [SCHEMA_STATE]: baseSchemaState,
    [DEFAULT_STATE]: defaultState,
    [COMPARISON_STATE]: schemaInstanceState,
    [SCHEMA_VALIDATION_PROPS]: schemaValidityState,
    [VALIDITY]: validityState,
    [VALID]: Object.keys(validityState).length == 0,
    [CHANGED]: false,
  };

  const instanceReducer = toSchemaVerbActionReducer(schema)(
    get(INSTANCE, initialState)
  );

  const derivedValidityReducer = toDerivedValidationReducer(validator);

  const schemaStateReducer = toScopedReducer(
    META_SCHEMA,
    toMetaSchemaReducer(get(SCHEMA_STATE, initialState))
  );

  return (state, action) => {
    if (state == undefined) return initialState;

    const prevSchemaState = get(SCHEMA_STATE, state);
    const nextSchemaState = schemaStateReducer(prevSchemaState, action);

    const prevDefaultState = get(DEFAULT_STATE, state);
    const nextDefaultState =
      prevSchemaState === nextSchemaState
        ? prevDefaultState
        : derivedDefaultStateReducer(nextSchemaState);

    const prevInstanceState = get(INSTANCE, state);
    const nextInstanceState =
      nextDefaultState === prevDefaultState
        ? instanceReducer(prevInstanceState, action)
        : instanceReducer(
            instanceReducer(prevInstanceState, {
              type: REVERSE_MERGE_PATCH,
              value: nextDefaultState,
            }),
            action
          );

    if (
      prevInstanceState === nextInstanceState &&
      prevSchemaState === nextSchemaState
    )
      return state;

    const prevComparisonState = get(COMPARISON_STATE, state);
    const nextComparisonState =
      nextDefaultState === prevDefaultState
        ? prevComparisonState
        : merge(nextDefaultState, initialValue);

    if (prevInstanceState === nextInstanceState) {
      return {
        ...state,
        [SCHEMA_STATE]: nextSchemaState,
        [DEFAULT_STATE]: nextDefaultState,
        [COMPARISON_STATE]: nextComparisonState,
      };
    }

    const nextChangedState = isScoped(META_SCHEMA, action)
      ? // The way `toChangedTree` was invoked meant that we'd never get any changed diff when a metaSchema action
        // gets dispatched. This was problematic if we were already editing a form and added a new item in
        // a list, for example. We lose all of the previous changed state we had accumlated up to that point.
        // ? toChangedTree(nextComparisonState, nextInstanceState, () => true)
        toChangedTree(
          nextComparisonState,
          nextInstanceState,
          state.changed ? equals : () => true
        )
      : toDerivedChangedReducer(nextComparisonState)(
          get(CHANGED_PROPS, state),
          action
        );

    const nextSchemaValidationProps = derivedValidityReducer(
      nextSchemaState,
      nextInstanceState
    );

    const nextValidityState = toValidityState(nextSchemaValidationProps);

    return {
      ...state,
      [INSTANCE]: nextInstanceState,
      [SCHEMA_STATE]: nextSchemaState,
      [DEFAULT_STATE]: nextDefaultState,
      [COMPARISON_STATE]: nextComparisonState,
      [CHANGED_PROPS]: nextChangedState,
      [SCHEMA_VALIDATION_PROPS]: nextSchemaValidationProps,
      [VALIDITY]: nextValidityState,
      [VALID]: Object.keys(nextValidityState).length == 0,
      [CHANGED]: Object.keys(nextChangedState).length > 0,
    };
  };
};

export const withDefaultJSONSchemaReducer = (...additionalReducers) => {
  return (initialValue, schema, validator) => {
    //base JSONSchemaReducer should be first, open to changing this later
    //For now the assumption is the default should have priority. (PDD)
    return combineReducers(
      toJSONSchemaReducer(initialValue, schema, validator),
      ...additionalReducers
    );
  };
};
