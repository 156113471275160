import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { toUIValid } from '../../schema/UIValidationProjection';
import PropTypes from 'prop-types';
import DefaultView from './DefaultView';
import reducers from './reducers';
import { useReducer, useRef } from 'react';
import { searchDiagnoses } from 'services/diagnosis/xe-diagnosis-svc';
import { useXeQuery } from '../../data/useXeQuery';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { useSchema } from '../../schema/SchemaReducer';
import { identity } from '../../fp/fp';

const reducer = combinePredicatedReducers(...reducers);

export const XeDiagnosisWidget = (props) => {
  const {
    dataElementName,
    value,
    dataPath,
    valueFn = identity,
    onChange = NOOP_FUNCTION,
    className,
    clearButton,
    clearOnSelect,
    disabled,
    formatter,
    itemRender,
    onValidation = NOOP_FUNCTION,
    ...rest
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const [reducedState, dispatch] = useReducer(reducer, EMPTY_OBJECT);
  const { searchText = '' } = reducedState;

  const { onValueChange = onChange, onValidationChange = onValidation } =
    useSchema(dataPath);

  const comboBoxRef = useRef();

  const { data: results = [] } = useXeQuery(
    searchDiagnoses(
      {
        searchText,
        active: true,
      },
      (x) => x
    ),
    { enabled: searchText.trim().length > 0 }
  );

  return (
    <DefaultView
      {...rest}
      dataElementName={dataElementName}
      value={value}
      results={results}
      comboBoxRef={comboBoxRef}
      dispatch={dispatch}
      className={className}
      clearButton={clearButton}
      clearOnSelect={clearOnSelect}
      disabled={disabled}
      formatter={formatter}
      itemRender={itemRender}
      onChange={(v) => {
        onValueChange(valueFn(v));
        onValidationChange(toUIValid(comboBoxRef.current.validity));
      }}
    />
  );
};

XeDiagnosisWidget.propTypes = {
  valueFn: PropTypes.func,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
};

export default XeDiagnosisWidget;
