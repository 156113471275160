import { useReducer, useState } from 'react';
import { searchProviders } from 'services/providers/xe-providers-svc';
import { Grid } from '../../../components/Grid';
import { Panel } from '../../../components/Panel';
import { Popup } from '../../../components/Popup/';
import {
  Footer,
  FooterButton,
} from '../../../components/Popup/components/Footer';
import { combinePredicatedReducers } from '../../../connection/toConnectionDef';
import { EMPTY_OBJECT } from '../../../constants';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { useXeQuery } from '../../../data/useXeQuery';
import { identity } from '../../../fp/fp';
import { isNil } from '../../../fp/pred';
import { RESOURCE_TYPE_IDS } from '../../XeStaffSearchWidget/constants';
import {
  SHOULD_CLEAR_ADVANCED_SEARCH,
  SHOULD_SEARCH_ADVANCED,
  SHOULD_UPDATE_ADVANCED_SEARCH,
} from '../../XeStaffSearchWidget/picker/actions';
import { AddPopup } from '../../XeStaffSearchWidget/picker/components/AddPopup';
import StaffSearchHeader from '../../XeStaffSearchWidget/picker/components/StaffSearchHeader';
import { advancedColumnDefs } from '../../XeStaffSearchWidget/picker/constants';
import reducers from '../../XeStaffSearchWidget/picker/reducers';
import { ProviderSearchAdvanced } from './components/ProviderSearchAdvanced';

const reducer = combinePredicatedReducers(...reducers);

export const NetworkProviderPicker = (props) => {
  const { onClose, onChange, initialData, initialFilters } = props;

  const labels = useXeLabels();

  const [showAdd, setShowAdd] = useState(false);
  const [viewAdd, setViewAdd] = useState(false);

  const [state, dispatch] = useReducer(reducer, {
    advancedSearchConfig: initialFilters,
  });

  const {
    advancedSearchConfig: currentFilterState,
    advancedSearchQueryParams,
  } = state;

  const { data: advancedResults = initialData, isFetching } = useXeQuery(
    searchProviders(advancedSearchQueryParams ?? EMPTY_OBJECT, identity),
    {
      enabled: !isNil(advancedSearchQueryParams),
      onSuccess: () => setShowAdd(true),
    }
  );

  const {
    searchZip,
    searchText,
    searchSpecialty,
    searchStatus,
    searchInNetworkOnly,
    searchResourceTypeId = RESOURCE_TYPE_IDS.ALL,
    searchActive = true,
    allSearchCriteriaAvailable,
    ...restFilterState
  } = currentFilterState;

  return (
    <>
      <Popup title={labels.StaffSearch} size="large">
        <div
          className="staff-search vertical-flex-container flex-1"
          data-xe-feature-name="NetworkProviderPicker"
        >
          <Panel
            className="staff-search-popup-wrapper__panel"
            shouldOverflow={false}
          >
            <StaffSearchHeader
              staffName={searchText}
              searchEnabled={true}
              isClearFilterDisabled={
                !searchText &&
                !searchSpecialty &&
                searchResourceTypeId === RESOURCE_TYPE_IDS.ALL &&
                !searchInNetworkOnly &&
                searchActive
              }
              showAllFilters={allSearchCriteriaAvailable}
              onUpdateSearchText={({ value } = {}) =>
                dispatch({
                  type: SHOULD_UPDATE_ADVANCED_SEARCH,
                  value: {
                    key: 'searchText',
                    value,
                  },
                })
              }
              onToggleExpandFilters={() =>
                dispatch({
                  type: SHOULD_UPDATE_ADVANCED_SEARCH,
                  value: {
                    key: 'allSearchCriteriaAvailable',
                    value: !allSearchCriteriaAvailable,
                  },
                })
              }
              onSearch={() => {
                dispatch({
                  type: SHOULD_SEARCH_ADVANCED,
                  value: {
                    ...restFilterState,
                    searchText,
                    resourceTypeId: searchResourceTypeId,
                    zipPostalCode: searchZip,
                    specialty: searchSpecialty,
                    withInNetworkOnly: searchInNetworkOnly,
                    active: searchActive,
                  },
                });
              }}
              onClear={() =>
                dispatch({
                  type: SHOULD_CLEAR_ADVANCED_SEARCH,
                })
              }
              showAdd={showAdd}
              shouldAddNewItem={() => {
                setViewAdd(true);
              }}
            />
            {allSearchCriteriaAvailable && (
              <ProviderSearchAdvanced
                zip={searchZip}
                specialty={searchSpecialty}
                status={searchStatus}
                inNetworkOnly={searchInNetworkOnly}
                resourceTypeId={searchResourceTypeId}
                active={searchActive}
                onUpdateZip={({ value } = {}) =>
                  dispatch({
                    type: SHOULD_UPDATE_ADVANCED_SEARCH,
                    value: {
                      key: 'searchZip',
                      value,
                    },
                  })
                }
                onUpdateSpecialty={({ value } = {}) =>
                  dispatch({
                    type: SHOULD_UPDATE_ADVANCED_SEARCH,
                    value: {
                      key: 'searchSpecialty',
                      value,
                    },
                  })
                }
                onUpdateScope={({ value } = {}) => {
                  dispatch({
                    type: SHOULD_UPDATE_ADVANCED_SEARCH,
                    value: {
                      key: 'searchResourceTypeId',
                      value,
                    },
                  });
                }}
                onUpdateInNetworkOnly={(value) => {
                  dispatch({
                    type: SHOULD_UPDATE_ADVANCED_SEARCH,
                    value: {
                      key: 'searchInNetworkOnly',
                      value,
                    },
                  });
                }}
                onUpdateActive={(value) => {
                  dispatch({
                    type: SHOULD_UPDATE_ADVANCED_SEARCH,
                    value: {
                      key: 'searchActive',
                      value,
                    },
                  });
                }}
              />
            )}
          </Panel>
          <Panel
            data-component-name="StaffSearchDataGrid"
            className="staff-search-data-container"
          >
            <Grid
              onRowClicked={({ data } = {}) => onChange(data)}
              columnDefs={advancedColumnDefs}
              data={advancedResults}
              isLoading={isFetching}
            />
          </Panel>
        </div>
        <Footer>
          <FooterButton
            dataElementName="networkProviderWidgetPicker__close"
            onClick={onClose}
          >
            {labels.Close}
          </FooterButton>
        </Footer>
      </Popup>
      {viewAdd ? (
        <AddPopup
          onClose={() => {
            setViewAdd(false);
          }}
          onChange={onChange}
        />
      ) : null}
    </>
  );
};
