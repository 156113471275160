/**
 * Returns a string of class names with appended modifiers in BEM syntax
 * @param {*} baseClassName
 * @param {Object|[...String]} modifiers
 */
export const withClassNameModifiers = (baseClassName, ...modifiers) => {
  if (!modifiers.length) return baseClassName;
  const toBEMmodifier = (m) => `${baseClassName}--${m}`;
  const [firstModifier] = modifiers;

  if (typeof firstModifier === 'string') {
    return [
      baseClassName,
      ...modifiers.filter((m) => !!m).map(toBEMmodifier),
    ].join(' ');
  }

  const modifiersObject = firstModifier;

  return [
    baseClassName,
    ...Object.entries(modifiersObject)
      .filter(([, condition]) => condition)
      .map(([modifier]) => toBEMmodifier(modifier)),
  ].join(' ');
};

export default withClassNameModifiers;
