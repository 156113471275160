import { pluck } from '../../fp/object';
import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { toUIValid } from '../../schema/UIValidationProjection';
import { useSchema } from '../../schema/SchemaReducer';

import { useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { MaskedComboBox } from '../components/MaskedComboBox';
import { SHOULD_SEARCH_BY_TEXT } from './actions';
import reducers from './reducers';
import { useXeQuery } from '../../data/useXeQuery';
import { browse } from 'services/health-plans/xe-health-plans-svc';
import { EMPTY_ARRAY, NOOP_FUNCTION } from '../../constants';

/**
 * @typedef {import("services/generated/types").AssetXeHealthPlan} HealthPlan
 */

const reducer = combinePredicatedReducers(...reducers);

// NOTE: This is an example of TypeScript's JSDoc type declarations, including the difference
// between optional vs. non-optional properties and different supported syntax use cases (CJP)
// /**
//  * @typedef {Object} SpecialType - creates a new type named 'SpecialType'
//  * @property {string} prop1 - a string property of SpecialType (note the lack of "[]"" around prop1)
//  * @property {number} prop2 - a number property of SpecialType
//  * @property {number=} prop3 - an optional number property of SpecialType (note the "="" after the number type)
//  * @prop {number} [prop4] - an optional number property of SpecialType (note the "[]" around prop4)
//  * @prop {number} [prop5=42] - an optional number property of SpecialType with default
//  */

const defaultLabelFn = pluck('Name');

/**
 *  @typedef {Object} customProps
 *  @property {String | Number} [value]
 *  @property {String} [dataPath]
 *  @property {boolean} [required]
 *  @property {(x:HealthPlan) => void} [onChange]
 *  @property {String} [className]
 *  @property {boolean} [clearOnSelect]
 *  @property {boolean} [disabled]
 *  @property {String} [placeholder]
 *  @property {(x: boolean) => void} [onValidation] */

/**
 *
 * @param {customProps & import('@progress/kendo-react-dropdowns').ComboBoxProps} props
 */
export const XeHealthPlanWidget = (props) => {
  const {
    dataElementName = '',
    value: propsValue,
    dataPath,
    onChange = NOOP_FUNCTION,
    className,
    clearOnSelect,
    disabled,
    onValidation = NOOP_FUNCTION,
    required,
    labelFn = defaultLabelFn,
    ...rest
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const {
    value = propsValue,
    onValueChange = onChange,
    onValidationChange = onValidation,
  } = useSchema(dataPath);

  /**
   * TODO: Fix the type for `useReducer$`. typescript is resolving it to a (symbol | ((action: any) => any))[] which isn't right
   * @type {any[]}
   */
  const [state = {}, dispatch] = useReducer(reducer);

  const { data: [healthPlanData] = EMPTY_ARRAY } = useXeQuery(
    browse(
      {
        healthPlanId: value,
      },
      (x) => x
    ),
    { enabled: !!value }
  );

  /** @type {{ searchParams?: { searchText?: string }}} */
  const { searchText = '' } = state;

  const comboBoxRef = useRef();

  const { data: results } = useXeQuery(
    browse({ searchText }, (x) => x),
    {
      enabled: searchText?.trim().length > 0,
    }
  );

  return (
    <>
      <MaskedComboBox
        dataElementName={dataElementName}
        {...rest}
        results={results}
        required={required}
        data-xe-widget-name="XeHealthPlanWidget"
        comboBoxRef={comboBoxRef}
        clearOnSelect={clearOnSelect}
        disabled={disabled}
        value={healthPlanData}
        labelFn={labelFn}
        data={results}
        onChange={(value) => {
          const valueId = pluck('HealthPlanID')(value);
          const currentComboBox = comboBoxRef.current || { validity: false };
          const uiValid = toUIValid(currentComboBox.validity);
          onValueChange(valueId);
          onValidationChange(uiValid);
        }}
        onEnter={(value) => dispatch({ type: SHOULD_SEARCH_BY_TEXT, value })}
        className={className}
      />
    </>
  );
};

XeHealthPlanWidget.propTypes = {
  valueFn: PropTypes.func,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  labelFn: PropTypes.func,
  dataElementName: PropTypes.string,
};
