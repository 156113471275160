import { identity } from '../fp/fp';
import { toActionValueReducer } from '../reducers';

/**
 * @param {string} pathKey - key to used for namespacing
 * @return {function} a toActionValueReducer reducer that will reduce to provided key inside the namespacing
 */
export const toTransientKeyReducer =
  (pathKey) =>
  (key, projection = identity) =>
    toActionValueReducer([pathKey, key], projection);

/**
 * @param {string} key - key or array of keys to clear from state
 * @return {Object} state with the key being undefined
 */
// TODO: We should clear the key vs setting to undefined, but there is something wierd in the rendering cycles (SG)
export const clearTransientKey = (key) => (state) => ({
  ...state,
  [key]: undefined,
});
