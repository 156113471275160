import { isObjectLike } from '../fp/pred';
import castPath from 'lodash/_castPath';

/*
  "$schema" : {
    "const" : "com.thrasys.test.objectWithComplexPK.json#"
  }
*/
export const isConstant = (declaration = {}) => {
  const { ['const']: constant } = declaration;
  return constant !== undefined;
};

/*
  "RightID" : {
    "$ref" : "com.thrasys.xnet.app.assets.AssetXeAppRights.json#"
  }
*/
export const isRefDeclaration = (declaration = {}) => {
  const { ['$ref']: ref } = declaration;
  return ref !== undefined;
};

/*
  "items" : {
    "$ref" : "com.thrasys.xnet.app.xmlobjects.addtemplatedusers.AddTemplatedUsersRequest$XeUserData-XeAccountGroup.json#"
  },
  "type" : "array"
  },
*/
export const isItemsDeclaration = (declaration = {}) => {
  const { items, type } = declaration;
  return type === 'array' && items;
};

/*
  "RightID" : {
    "oneOf" : [ {
      "type" : "string",
      "maxLength" : 60
    }, {
      "$ref" : "com.thrasys.xnet.app.assets.AssetXeAppRights.json#"
    } ]
*/
export const isOneOfDeclaration = (declaration = {}) => {
  const { oneOf, anyOf } = declaration;
  const oneOrAnyOf = anyOf || oneOf;
  return oneOrAnyOf !== undefined;
};

/*
  "RightID" : {
    "type" : "string",
    "maxLength" : 60
  }
*/
export const isSimpleTypeDeclaration = (declaration = {}) => {
  const { type } = declaration;
  return type !== undefined && type !== 'array' && type !== 'object';
};

export const isObjectDeclaration = (declaration = {}) => {
  const { type } = declaration;
  return type === 'object';
};

export const isKeyDeclaration = (declaration = {}) => {
  const { primaryKey } = declaration;

  return !!primaryKey;
};

export const isSchemaPrimitive = (value) => {
  const type = typeof value;

  return (
    type === 'number' ||
    type === 'string' ||
    type === 'boolean' ||
    value === null
  );
};

export const toEmptyReferencedSchemas = () => [];
export const findRelatedSchema =
  (references = [], upstreamFinder = toEmptyReferencedSchemas) =>
  (ref = '') => {
    const withoutHash = ref.slice(0, -1);
    const innerSchema =
      references.find(({ name }) => name === withoutHash) ||
      upstreamFinder(ref);
    return innerSchema;
  };

export const toValidDeclaration = (
  validator,
  declarations = [],
  value = undefined
) => {
  const testValue =
    isObjectLike(value) && value['$schema']
      ? { ...value, $schema: undefined }
      : value;

  const actualFromDeclaration = declarations.find((declaration) => {
    return validator.validate(declaration, testValue);
  });

  return actualFromDeclaration;
};

const conversionTable = {
  integer: (value) => {
    const numVal = parseInt(value);
    return isNaN(numVal) ? undefined : numVal;
  },
  number: (value) => {
    if (value !== null) {
      const numVal = Number(value);
      return isNaN(numVal) ? undefined : numVal;
    }
    return undefined;
  },
  string: (value) => {
    if (value !== null && value !== undefined) {
      return String(value);
    }
    return value;
  },
  boolean: Boolean,
  null: () => null,
};

export const convertToSimpleType = (type, value) => {
  if (type === typeof value) {
    return value;
  }

  return conversionTable[type](value);
};

const innerGetKeyDeclarations = (schemaOrDeclaration) => {
  const { properties = {} } = schemaOrDeclaration;

  return Object.entries(properties)
    .filter(([, { primaryKey }]) => primaryKey)
    .reduce((acc, [k, v]) => {
      return {
        ...acc,
        [k]: v,
      };
    }, {});
};

export const getKeyDeclarations = (schema) => {
  const { baseSchema = {} } = schema;

  return innerGetKeyDeclarations(baseSchema);
};

export const getKeyName = (declaration) => {
  const [key] = Object.keys(declaration);
  return key;
};

export const getKeyNameByType =
  (type) =>
  (objectDeclaration = {}) => {
    const keyProps = innerGetKeyDeclarations(objectDeclaration);
    const entry =
      Object.entries(keyProps).find(([k, v]) => {
        return ([, { primaryKey }]) => primaryKey == type;
      }) || [];

    return entry[0];
  };

export const getSuppliedKeyName = getKeyNameByType('supplied');
export const getGeneratedKeyName = getKeyNameByType('generated');

export const getPropertyDefinitionNamed = (
  objectDeclaration = {},
  name = ''
) => {
  const { properties } = objectDeclaration;

  return properties && properties[name];
};

export const getDeclarationNode = (
  referencedSchemas,
  pathSegments = [],
  declaration = {},
  required = false
) => {
  const [head, ...rest] = pathSegments;

  if (head === undefined) {
    return {
      referencedSchemas,
      declaration,
      required,
    };
  }

  if (isObjectDeclaration(declaration)) {
    const { properties = {}, required = [] } = declaration;
    return getDeclarationNode(
      referencedSchemas,
      rest,
      properties[head],
      required.includes(head)
    );
  } else if (isOneOfDeclaration(declaration)) {
    const { oneOf, anyOf } = declaration;
    const oneOrAnyOf = anyOf || oneOf || [];
    const complexType = oneOrAnyOf.find((d) => !isSimpleTypeDeclaration(d));
    return getDeclarationNode(referencedSchemas, pathSegments, complexType);
  } else if (isItemsDeclaration(declaration)) {
    const { items } = declaration;
    //so did they give us a prop path of an index path
    if (isNaN(head)) {
      return getDeclarationNode(referencedSchemas, pathSegments, items);
    } else {
      return getDeclarationNode(referencedSchemas, rest, items);
    }
  } else if (isRefDeclaration(declaration)) {
    const { ['$ref']: ref } = declaration;

    const schema = findRelatedSchema(referencedSchemas)(ref);

    const {
      baseSchema: baseSchema = {},
      toReferencedSchemas = toEmptyReferencedSchemas,
    } = schema;

    const nextReferencedSchemas = [
      ...referencedSchemas,
      ...toReferencedSchemas(),
    ];

    return getDeclarationNode(nextReferencedSchemas, pathSegments, baseSchema);
  }

  //what in the world is this?
  throw new Error('Currently an unsupported declaration');
};

export const getSchemaNode = (schema = {}, path) => {
  const {
    baseSchema,
    toReferencedSchemas = toEmptyReferencedSchemas,
    //registerSchema
  } = schema;

  if (!baseSchema) {
    //If we honestly don't have a schema... don't fight through trying to recursively identify a node
    return undefined;
  }

  const references = toReferencedSchemas();
  const pathSegments = castPath(path);

  return getDeclarationNode([...references, schema], pathSegments, baseSchema);
};

export const hasKeyDeclarationNamed = (objectDeclaration = {}, name = '') => {
  return isKeyDeclaration(getPropertyDefinitionNamed(objectDeclaration, name));
};
