export const toUIValid = (validity = {}) => {
  const {
    valueMissing,
    typeMismatch,
    patternMismatch,
    tooLong,
    tooShort,
    rangeUnderflow,
    rangeOverflow,
    stepMismatch,
    badInput,
  } = validity;

  return !(
    valueMissing ||
    typeMismatch ||
    patternMismatch ||
    tooLong ||
    tooShort ||
    rangeUnderflow ||
    rangeOverflow ||
    stepMismatch ||
    badInput
  );
};

// Added customError flag. SNETEXT-220(MF)
export const toUIValidForDate = (validity = {}) => {
  const {
    valueMissing,
    typeMismatch,
    patternMismatch,
    tooLong,
    tooShort,
    rangeUnderflow,
    rangeOverflow,
    stepMismatch,
    badInput,
    customError,
  } = validity;

  return !(
    valueMissing ||
    typeMismatch ||
    patternMismatch ||
    tooLong ||
    tooShort ||
    rangeUnderflow ||
    rangeOverflow ||
    stepMismatch ||
    badInput ||
    customError
  );
};
