import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import {
  SHOULD_UPDATE_SEARCH_RESULTS,
  SHOULD_SELECT_ITEM,
  SHOULD_UPDATE_ITEM,
  SHOULD_UPDATE_SEARCH_FILTER,
} from './actions';

const toDefaultState = () => {
  return { results: [] };
};

export default [
  when((state) => state === undefined)(toDefaultState),
  when(isType(SHOULD_UPDATE_SEARCH_RESULTS))(toActionValueReducer('results')),
  when(isType(SHOULD_SELECT_ITEM, SHOULD_UPDATE_ITEM))(
    toActionValueReducer('value'),
    toActionValueReducer('results', () => [])
  ),
  when(isType(SHOULD_UPDATE_SEARCH_FILTER))(toActionValueReducer('searchText')),
];
