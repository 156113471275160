import { useReducer } from 'react';
import { UIControlLabel } from '../../components';
import { SHOULD_SEARCH, RESPONSE_SEARCH, CLEAR_RESULTS } from './actions';
import { MaskedComboBox } from '../components/MaskedComboBox';
import PropTypes from 'prop-types';
import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import { searchDRG } from 'services/diagnosis/xe-diagnosis-svc';
import { useXeQuery } from '../../data/useXeQuery';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';

const resultReducers = [
  when(isType(RESPONSE_SEARCH))(toActionValueReducer('queryResults')),
  when(isType(CLEAR_RESULTS))(toActionValueReducer('queryResults', () => [])),
  when(isType(SHOULD_SEARCH))(toActionValueReducer('searchText')),
];

const reducer = combinePredicatedReducers(...resultReducers);

export const XeDRGWidget = (props) => {
  const {
    dataElementName = '',
    className,
    value,
    onChange,
    descriptor,
    descriptorClassName,
    required,
    ...rest
  } = props;

  const [reducedState, dispatch] = useReducer(reducer, EMPTY_OBJECT);

  const { searchText = '' } = reducedState;

  const { data: results = EMPTY_ARRAY } = useXeQuery(
    searchDRG(
      {
        searchText,
        active: true,
      },
      (x) => x
    ),
    { enabled: searchText.trim().length > 0 }
  );

  return (
    <>
      {descriptor && (
        <UIControlLabel
          dataElementName={
            !!dataElementName ? `${dataElementName}__drgLabel` : 'drgLabel'
          }
          className={descriptorClassName}
          required={required}
        >
          {descriptor}
        </UIControlLabel>
      )}
      <MaskedComboBox
        dataElementName={dataElementName}
        {...rest}
        value={value}
        data={results}
        onChange={(value) => {
          onChange(value);
          dispatch({ type: CLEAR_RESULTS });
        }}
        onEnter={(value) => {
          dispatch({ type: SHOULD_SEARCH, value });
        }}
        className={`${className} stretch-x`}
        data-xe-widget-name="XeDRGSearchWidget"
      />
    </>
  );
};

XeDRGWidget.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  clearOnSelect: PropTypes.bool,
  descriptor: PropTypes.string,
  descriptorClassName: PropTypes.string,
  required: PropTypes.string,
};
