import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import DefaultView from './views/DefaultView';
import reducers from './reducers';
import { useReducer } from 'react';
import { browse } from 'services/enterprises/xe-enterprises-svc';
import { EMPTY_OBJECT } from '../../constants';
import { useXeQuery } from '../../data/useXeQuery';

const reducer = combinePredicatedReducers(...reducers);

export const XeEnterpriseWidget = (props) => {
  const {
    onChange,
    className,
    clearButton,
    disabled,
    formatter,
    clearOnSelect,
    comboClassName,
    value,
  } = props;

  const [state = {}, dispatch] = useReducer(reducer, EMPTY_OBJECT);
  const { searchText = '' } = state;

  const { data } = useXeQuery(
    browse({ searchText }, (x) => x),
    { enabled: searchText.trim().length > 0 }
  );

  return (
    <DefaultView
      {...state}
      results={data}
      dispatch={dispatch}
      onChange={onChange}
      className={className}
      clearButton={clearButton}
      disabled={disabled}
      formatter={formatter}
      clearOnSelect={clearOnSelect}
      comboClassName={comboClassName}
      value={value}
    />
  );
};
