import { Checkbox } from '../../../../components/Checkbox';
import { DatePicker } from '../../../../components/DatePicker';
import { DropDownList } from '../../../../components/DropDownList';
import { TelephoneInput } from '../../../../components/TelephoneInput';
import { TextInput } from '../../../../components/TextInput';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { useXeRefData } from '../../../../contexts/XeRefDataContext';
import { pluck } from '../../../../fp/object';

const PatientSearchAdvanced = (props) => {
  const labels = useXeLabels();
  const { XeSex } = useXeRefData();

  const sexOptions = [{ text: '' }, ...XeSex];

  return (
    <div className="display-grid xe-patient-search__advanced-search">
      <TextInput
        dataElementName="patientAdvanceSearch__familyName"
        descriptor={labels.FamilyName}
        dataPath="familyName"
      />
      <TextInput
        dataElementName="patientAdvanceSearch__givenName"
        descriptor={labels.GivenName}
        dataPath="givenName"
      />
      <DatePicker
        dataElementName="patientAdvanceSearch__dob"
        descriptor={labels.DateOfBirthShort}
        format="local-date-time"
        dataPath="searchDob"
      />
      <DropDownList
        dataElementName="patientAdvanceSearch__gender"
        descriptor={labels.Sex}
        data={sexOptions}
        dataPath="sexId"
        labelFn={pluck('text')}
        valueFn={pluck('id')}
      />
      <TelephoneInput
        dataElementName="patientAdvanceSearch__phone"
        descriptor={labels.Phone}
        dataPath="searchPhone"
      />
      <TextInput
        dataElementName="patientAdvanceSearch__city"
        descriptor={labels.City}
        dataPath="searchCity"
      />
      <Checkbox
        dataElementName="patientAdvanceSearch__isDead"
        label={labels.Deceased}
        dataPath="isDead"
        wrapperClassName="patient-search-advanced__deceased-checkbox"
      />
    </div>
  );
};

export default PatientSearchAdvanced;
