import { isObjectLike, isDate } from '../fp/pred';

/**
 * Test if a value is a plain object.
 * @param {*} val - A value.
 * @return {boolean} true if `val` is object like and not a date.
 */
const isObject = (val) => isObjectLike(val) && !isDate(val);

const arrayApply = (origin, patch, visited = {}) => {
  if (!Array.isArray(origin)) {
    return patch;
  }

  const result = origin.slice();

  patch.forEach((patchVal, key) => {
    if (patchVal === undefined) {
      delete result[key];
    } else {
      if (!visited.has(patchVal)) {
        result[key] = apply(result[key], patchVal, visited);
      } else {
        result[key] = patchVal;
      }
    }
  });

  return result;
};

export const apply = (origin, patch, visited = new Set()) => {
  //Note we have visited this item so that we don't end up in infinite recursion
  visited.add(patch);

  if (Array.isArray(patch)) {
    return arrayApply(origin, patch, visited);
  }

  if (!isObject(patch)) {
    // If the patch is not an object, it replaces the origin.
    return patch;
  }

  const result = !isObject(origin)
    ? // Non objects are being replaced.
      {}
    : // Make sure we never modify the origin.
      Object.assign({}, origin);

  Object.keys(patch).forEach((key) => {
    const patchVal = patch[key];
    if (patchVal === undefined) {
      delete result[key];
    } else {
      if (!visited.has(patchVal)) {
        result[key] = apply(result[key], patchVal, visited);
      } else {
        result[key] = patchVal;
      }
    }
  });
  return result;
};

export default apply;
