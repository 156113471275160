import {
  useScopedDispatch,
  useScopedSelector,
} from '../../../../hooks/scopedReducer';
import { pluck } from '../../../../fp/object';
import { isObjectLike } from '../../../../fp/pred';
import { useCallback, useMemo, useState } from 'react';
import { ButtonBar } from '../../../../components/ButtonBar';
import { DropDownList } from '../../../../components/DropDownList';
import { Flexbox } from '../../../../components/Flexbox';
import { IconButton } from '../../../../components/IconButton';
import { Input } from '../../../../components/Input';
import { Label, HeaderLabel } from '../../../../components/Label';
import { List } from '../../../../components/List';
import { Panel } from '../../../../components/Panel';
import { Table } from '../../../../components/Table';
import { TelephoneInput } from '../../../../components/TelephoneInput';
import { Grid } from '../../../../components/Grid';
import { ZipCodeInput } from '../../../../components/ZipCodeInput';
import { ADD } from '../../../../icons';
import {
  SHOULD_SEARCH_EXISTING_PROVIDERS,
  SHOULD_SELECT_PROVIDER_MATCH,
  SHOULD_UPDATE_STAFF,
  SHOULD_UPDATE_STAFF_CATEGORY,
} from '../actions';
import { IndividualFields } from '../components/IndividualFields';
import { OrganizationFields } from '../components/OrganizationFields';
import useContactCategoryOptions from '../../../../hooks/useContactCategoryOptions';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { useXeRefData } from '../../../../contexts/XeRefDataContext';
import '../styles.css';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../constants';

const toCityState = (address) => {
  const { City, StateProvince } = address;
  if (!City && !StateProvince) return '';
  if (!City) return StateProvince;
  if (!StateProvince) return City;
  return `${City}, ${StateProvince}`;
};

const matchingProvidersColumnDefs = [
  {
    headerLabelKey: 'Name',
    valueGetter: ({ data: { FamilyName, GivenName } = {} }) =>
      FamilyName && GivenName && `${FamilyName}, ${GivenName}`,
  },
  {
    headerLabelKey: 'NPI',
    field: 'NationalProviderID',
  },
  {
    headerLabelKey: 'Address',
    valueGetter: ({ data: { XeStaffAddress = [] } = {} }) => {
      const maybeAddress = XeStaffAddress[0];
      if (!maybeAddress) return '';
      const { AddressLine1, AddressLine2 } = maybeAddress;

      const cityState = toCityState(maybeAddress);
      if (!AddressLine1) {
        return cityState;
      }
      if (!AddressLine2) {
        return cityState ? `${AddressLine1}, ${cityState}` : AddressLine1;
      }
      if (!cityState) {
        return `${AddressLine1} ${AddressLine2}`;
      }
      return `${AddressLine1} ${AddressLine2}, ${cityState}`;
    },
  },
  {
    headerLabelKey: 'Phone',
    valueGetter: ({ data: { XeStaffAddress = [] } = {} }) => {
      const maybeAddress = XeStaffAddress[0];
      if (!maybeAddress) return '';
      const { ContactPhone = '' } = maybeAddress;
      return ContactPhone;
    },
  },
  {
    headerLabelKey: 'Specialty',
    valueGetter: ({ data: { XeStaffSpecialty = [] } = {} }) =>
      XeStaffSpecialty.length ? XeStaffSpecialty[0].Name : '',
  },
  {
    headerLabelKey: 'Source',
    field: 'EnterpriseID.EnterpriseID',
  },
  {
    headerLabelKey: 'ID',
    field: 'StaffID.StaffID',
  },
];

const ContactCategoryBar = (props) => {
  const { onSelect, selectedContactCategory } = props;

  const labels = useXeLabels();
  const contactCategoryOptions = useContactCategoryOptions();

  return (
    <ButtonBar
      data={contactCategoryOptions}
      labelFn={({ nameKey }) => labels[nameKey]}
      //TODO: We need to revist ButtonBar mismatches in type of data
      //and selectedItem is causing issues here. Don't want to risk breaking
      //ButtonBar in other places, so just adding check here.
      keyFn={(obj = {}) => (isObjectLike(obj) ? obj.value : obj)}
      onSelect={onSelect}
      selectedItem={selectedContactCategory}
    />
  );
};

const AddProviderHeader = () => {
  const labels = useXeLabels();
  return (
    <HeaderLabel
      dataElementName="addProvider__specialities__label"
      className="add-provider__header padding-left-medium"
    >
      {labels.Specialties}
    </HeaderLabel>
  );
};

const AddIcon = (props) => {
  const { disabled, onClick } = props;
  const labels = useXeLabels();
  return (
    <IconButton
      dataElementName="addProvider__add"
      disabled={disabled}
      description={labels.Add}
      icon={ADD}
      onClick={onClick}
    />
  );
};

const Specialties = (props) => {
  const { specialties, onListUpdated } = props;
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);

  const addSpecialty = () => {
    const {
      item: {
        Active,
        Name,
        ID: SpecialtyID,
        HierarchyLevel: SpecialtyRank,
      } = {},
    } = selectedSpecialty;
    const newSpecialty = {
      Active,
      Name,
      SpecialtyID,
      SpecialtyRank,
    };
    const isDuplicate = selectedSpecialties.some(
      (specialty) => specialty.SpecialtyID === newSpecialty.SpecialtyID
    );

    if (isDuplicate) return;

    const nextSpecialties = [...selectedSpecialties, newSpecialty];
    setSelectedSpecialty(null);
    setSelectedSpecialties(nextSpecialties);

    onListUpdated(nextSpecialties);
  };

  return (
    <Flexbox direction="column" className="add-provider-specialties">
      <Flexbox justifyContent="space-between" alignItems="center">
        <AddProviderHeader />
        <Flexbox alignItems="center" className="padding-right-medium">
          <DropDownList
            data={specialties}
            dataElementName="addProvider__specialities"
            labelFn={pluck('text')}
            value={selectedSpecialty}
            onChange={setSelectedSpecialty}
            placeholder="-"
          />
          <AddIcon disabled={!selectedSpecialty} onClick={addSpecialty} />
        </Flexbox>
      </Flexbox>
      <Panel
        dataElementName="addProvider__specialitiesPanel"
        className="add-provider-specialties__list flex-1"
      >
        <List
          data={selectedSpecialties}
          renderItem={({ item, index }) => (
            <div className="add-provider-specialties__list-item" key={index}>
              {pluck('Name')(item)}
            </div>
          )}
        />
      </Panel>
    </Flexbox>
  );
};

const AddStaffPopupView = () => {
  const existingProviders =
    useScopedSelector(pluck('existingProviders')) ?? EMPTY_ARRAY;
  const StaffID = useScopedSelector(pluck('StaffID')) ?? EMPTY_OBJECT;

  const { XeStaffAddress = EMPTY_OBJECT, ResourceTypeID } = StaffID;

  const {
    AddressLine1 = '',
    AddressLine2 = '',
    City = '',
    StateProvince = '',
    ZipPostalCode = '',
    Phone = '',
    ContactName = '',
    ContactPhone = '',
    Email = '',
    Fax = '',
    MobilePhone = '',
  } = XeStaffAddress;

  const dispatch = useScopedDispatch();

  const { XeNameTitle, XeStateProvince, XeSpecialty } = useXeRefData();

  const onStaffUpdate = useCallback(
    (path, value) => {
      dispatch({
        type: SHOULD_UPDATE_STAFF,
        value: { path, value },
      });
    },
    [dispatch]
  );

  const onStaffCategoryChange = useCallback(
    ({ value }) => dispatch({ type: SHOULD_UPDATE_STAFF_CATEGORY, value }),
    [dispatch]
  );

  const InformationComponent = useMemo(() => {
    const isStaff = ResourceTypeID === 'STAFF';
    if (isStaff) {
      return (props) => (
        <IndividualFields XeNameTitle={XeNameTitle} {...props} />
      );
    }
    return OrganizationFields;
  }, [ResourceTypeID, XeNameTitle]);

  const labels = useXeLabels();

  return (
    <div className="flex-1 overflow-auto">
      <Flexbox justifyContent="space-between">
        <div />
        <ContactCategoryBar
          onSelect={onStaffCategoryChange}
          selectedContactCategory={ResourceTypeID}
        />
        <div />
      </Flexbox>
      <Table.Layout className="add-provider__inputs padding-vertical-small">
        <InformationComponent
          StaffID={StaffID}
          onStaffUpdate={onStaffUpdate}
          shouldSearchExistingProviders={(staffId) =>
            dispatch({
              type: SHOULD_SEARCH_EXISTING_PROVIDERS,
              value: staffId,
            })
          }
        />
        <Table.Row>
          <Table.Cell>
            <HeaderLabel className="add-provider__header">
              {labels.Address}
            </HeaderLabel>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__addressLine1__label">
              {labels.LineOne}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Input
              type="text"
              dataElementName="addProvider__addressLine1"
              className="app-input"
              value={AddressLine1}
              onChange={(value) =>
                onStaffUpdate('XeStaffAddress.AddressLine1', value)
              }
            />
          </Table.Cell>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__addressLine2__label">
              {labels.LineTwo}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Input
              type="text"
              className="app-input"
              dataElementName="addProvider__addressLine2"
              value={AddressLine2}
              onChange={(value) =>
                onStaffUpdate('XeStaffAddress.AddressLine2', value)
              }
              disabled={!AddressLine1}
            />
          </Table.Cell>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__city__label">
              {labels.City}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Input
              type="text"
              dataElementName="addProvider__city"
              className="app-input"
              value={City}
              onChange={(value) => onStaffUpdate('XeStaffAddress.City', value)}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__state__label">
              {labels.State}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <DropDownList
              dataElementName="addProvider__state"
              data={XeStateProvince}
              onChange={(value) =>
                onStaffUpdate('XeStaffAddress.StateProvince', value)
              }
              value={StateProvince}
              valueFn={pluck('id')}
              labelFn={pluck('text')}
              placeholder={'...'}
            />
          </Table.Cell>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__zip__label">
              {labels.Zip}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <ZipCodeInput
              className="app-input"
              dataElementName="addProvider__zip"
              value={ZipPostalCode}
              onChange={(value) =>
                onStaffUpdate('XeStaffAddress.ZipPostalCode', value)
              }
            />
          </Table.Cell>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__email__label">
              {labels.EmailAddress}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Input
              type="email"
              dataElementName="addProvider__email"
              className="app-input"
              value={Email}
              onChange={(value) => onStaffUpdate('XeStaffAddress.Email', value)}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__phone__label">
              {labels.Phone}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <TelephoneInput
              dataElementName="addProvider__phone"
              value={Phone}
              onChange={(value) => onStaffUpdate('XeStaffAddress.Phone', value)}
            />
          </Table.Cell>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__mobilePhone__label">
              {labels.MobilePhone}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <TelephoneInput
              value={MobilePhone}
              dataElementName="addProvider__mobilePhone"
              onChange={(value) =>
                onStaffUpdate('XeStaffAddress.MobilePhone', value)
              }
            />
          </Table.Cell>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__fax__label">
              {labels.Fax}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <TelephoneInput
              value={Fax}
              dataElementName="addProvider__fax"
              onChange={(value) => onStaffUpdate('XeStaffAddress.Fax', value)}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__contactName__label">
              {labels.ContactName}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Input
              type="text"
              dataElementName="addProvider__contactName"
              className="app-input"
              value={ContactName}
              onChange={(value) =>
                onStaffUpdate('XeStaffAddress.ContactName', value)
              }
            />
          </Table.Cell>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__contactPhone__label">
              {labels.ContactPhone}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <TelephoneInput
              value={ContactPhone}
              dataElementName="addProvider__contactPhone"
              onChange={(value) =>
                onStaffUpdate('XeStaffAddress.ContactPhone', value)
              }
            />
          </Table.Cell>
        </Table.Row>
      </Table.Layout>
      <Panel
        dataElementName="addProvider__editClinicalContactsPanel"
        className="panel-data-table edit-clinical-contacts-component__panel-data-table"
      >
        <div className="ag-grid-data-table vertical-flex-container relative">
          <Grid
            data={existingProviders}
            columnDefs={matchingProvidersColumnDefs}
            onRowClicked={({ data }) =>
              data &&
              dispatch({ type: SHOULD_SELECT_PROVIDER_MATCH, value: data })
            }
            rowHeight="30"
            minColWidth="50"
          />
        </div>
      </Panel>
      <Specialties
        specialties={XeSpecialty}
        onListUpdated={(value) => onStaffUpdate('XeStaffSpecialty', value)}
      />
    </div>
  );
};

export default AddStaffPopupView;
