import { combineEpics } from 'redux-observable';
import {
  combinePredicatedReducers,
  combineViews,
  combineCallbacks,
  toCallback
} from '../../../connection/toConnectionDef';
import { when } from '../../../predication/when';
import { isType } from '../../../connection/toConnectedComponent';
import { toXeConnectedComponent } from '../../../compositions/XeConnectedComponent';
import epics from './epics';
import reducers from './reducers';
import DefaultView from './views/DefaultView';
import { ON_PROVIDER_CHANGE } from './actions';
import {
  isValidObjectWithTextStringAt,
  isValidPhoneNumber,
  isValidEmail,
  isValidZip,
  isRequiredIfItExistAt,
  isValidTextString,
} from '../../../validators/inputPreds';
import { and } from '../../../fp/pred';

// TODO: moving all this at least into AddProvider so it's at least somewhere central, but long term this component
// should be refactored to use SchemaReducer and validation (JCM)

const isProviderSavable = and(
  //Required
  isValidObjectWithTextStringAt('GivenName'),
  isValidObjectWithTextStringAt('FamilyName'),
  // Not Required but if they exist they must pass validation for text fields
  isRequiredIfItExistAt('SecondName', isValidTextString),
  isRequiredIfItExistAt(
    'NationalProviderID',
    (n) => isValidTextString && n.length === 10
  ),
  isRequiredIfItExistAt('DEANumber', isValidTextString),
  isRequiredIfItExistAt('XeStaffAddress.AddressLine1', isValidTextString),
  isRequiredIfItExistAt('XeStaffAddress.AddressLine2', isValidTextString),
  isRequiredIfItExistAt('XeStaffAddress.City', isValidTextString),
  isRequiredIfItExistAt('XeStaffAddress.ContactName', isValidTextString),
  // Not Required but if phones exist they must pass validation
  isRequiredIfItExistAt('XeStaffAddress.Phone', isValidPhoneNumber),
  isRequiredIfItExistAt('XeStaffAddress.MobilePhone', isValidPhoneNumber),
  isRequiredIfItExistAt('XeStaffAddress.Fax', isValidPhoneNumber),
  isRequiredIfItExistAt('XeStaffAddress.ContactPhone', isValidPhoneNumber),
  // Not Required but if zip exist they must pass validation
  isRequiredIfItExistAt('XeStaffAddress.ZipPostalCode', isValidZip),
  // Not Required but if email exist they must pass validation
  isRequiredIfItExistAt('XeStaffAddress.Email', isValidEmail)
);

const identifier = 'AddProvider';

const views = [when(() => true)(DefaultView)];

export const AddProvider = toXeConnectedComponent(
  identifier,
  combinePredicatedReducers(...reducers),
  combineEpics(...epics),
  combineViews(...views),
  combineCallbacks(
    when(isType(ON_PROVIDER_CHANGE))(
      toCallback('onProviderInformationChange', ({ value }) => value),
      toCallback('onCanSave', ({ value }) => isProviderSavable(value))
    )
  )
);
export default AddProvider;
