import { isType } from '../../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../../reducers';
import { when } from '../../../predication/when';
import {
  clearTransientKey,
  toTransientKeyReducer,
} from '../../../legacy/transient';
import {
  CLEAR_PATIENTS,
  CLOSE_POPUP,
  OPEN_POPUP,
  RESPONSE_SEARCH_PATIENTS,
  SELECT_PATIENT,
  SHOULD_CLOSE_ADD_PATIENT,
  SHOULD_VIEW_ADD_PATIENT,
  UPDATE_CAN_ADD_PATIENT,
} from './actions';
import { ADD_PATIENT_POPUP_KEY, DEFAULT_STATE } from './constants';

const toAddPatientPopupActionValueReducer = toTransientKeyReducer(
  ADD_PATIENT_POPUP_KEY
);

export default [
  when((state) => state === undefined)(() => DEFAULT_STATE),
  when(isType(OPEN_POPUP))(toActionValueReducer('openPopup', () => true)),
  when(isType(SELECT_PATIENT, CLOSE_POPUP))(
    toActionValueReducer('openPopup', () => false)
  ),
  when(isType(RESPONSE_SEARCH_PATIENTS))(toActionValueReducer('patients')),
  when(isType(CLEAR_PATIENTS, SELECT_PATIENT, CLOSE_POPUP))(
    toActionValueReducer('patients', () => [])
  ),
  when(isType(SELECT_PATIENT))(toActionValueReducer('selectedPatient')),
  when(isType(SHOULD_VIEW_ADD_PATIENT))(
    toAddPatientPopupActionValueReducer({})
  ),
  when(isType(SHOULD_CLOSE_ADD_PATIENT))(
    clearTransientKey(ADD_PATIENT_POPUP_KEY)
  ),
  when(isType(UPDATE_CAN_ADD_PATIENT))(toActionValueReducer('canAddPatient')),
];
