import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import { isNil } from '../../fp/pred';
import { pluck } from '../../fp/object';
import {
  SHOULD_UPDATE_IPID,
  SHOULD_SEARCH,
  SHOULD_UPDATE_IPID_FROM_AUTOCOMPLETE,
  SHOULD_UPDATE_IPID_FROM_ADVANCED_SEARCH,
} from './actions';

export default [
  when(
    isType(
      SHOULD_UPDATE_IPID_FROM_AUTOCOMPLETE,
      SHOULD_UPDATE_IPID_FROM_ADVANCED_SEARCH
    )
  )(
    toActionValueReducer('selectedIPID', (selection) => {
      if (isNil(selection)) {
        return undefined;
      }

      return pluck('IPID')(selection);
    })
  ),
  when(isType(SHOULD_UPDATE_IPID))(toActionValueReducer('selectedIPID')),
  when(isType(SHOULD_SEARCH))(toActionValueReducer('searchText')),
];
