import window from '../globals/window';

export const fromLocalStorage = (storageKey) => {
  /***
   * Looks for a node in local storage.
   * If it finds one, it produces it
   ***/
  let si = window.localStorage && window.localStorage.getItem(storageKey);
  return si ? JSON.parse(si) : undefined;
};

/* eslint-disable no-empty */
export const toLocalStorage = (storageKey, value) => {
  try {
    //Necessary because Safari lies. It will tell you localStorage is available but...
    //if you try to use it and you are in private mode, it then throws an error
    window.localStorage.setItem(storageKey, JSON.stringify(value));
  } catch (error) {}
};
