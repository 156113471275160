import { combineEpics } from 'redux-observable';
import { combinePredicatedReducers } from '../../../connection/toConnectionDef';
import { useReducer$ } from '../../../hooks/useReducer$';
import { useEffect$ } from '../../../hooks/useEffect$';
import { ofType } from '../../../frp/operators/ofType';
import { useMenuNode } from '../../../contexts/XeMenuNodeContext';
import { useCallback } from 'react';
import { pluck, tap } from 'rxjs/operators';
import { UserAction } from '../../../components/UserAction';
import { useEnterprise } from '../../../contexts/XeEnterpriseContext';
import { FUNCTION } from '../../../contexts/XeMenuNodeContext/constants';
import { CLOSE_POPUP, OPEN_POPUP, SELECT_PATIENT } from './actions';
import { ADD_PATIENT_POPUP_KEY } from './constants';
import epics from './epics';
import reducers from './reducers';
import DefaultView from './views/DefaultView';
import AddPatientPopup from './XeAddPatientPopupWrapper';
import { of } from 'rxjs';
import { usePathNavigate } from '../../../hooks/usePathNavigate';
import { NOOP_FUNCTION } from '../../../constants';

const reducer = combinePredicatedReducers(...reducers);
const epic = combineEpics(...epics);

export const XePatientSearch = (props) => {
  const {
    onChange = NOOP_FUNCTION,
    onClose = NOOP_FUNCTION,
    disableRouting,
    MenuType,
  } = props;

  const menuNode = useMenuNode();
  const enterprise = useEnterprise();

  const navigate = usePathNavigate();

  const epicWithDeps = useCallback(
    (action$, state$) =>
      epic(action$, state$, {
        menuNode$: of(menuNode),
        enterprise$: of(enterprise),
      }),
    [menuNode, enterprise]
  );

  const [state, dispatch, action$] = useReducer$(reducer, epicWithDeps);
  const { openPopup, [ADD_PATIENT_POPUP_KEY]: addPatientPopup } = state;

  useEffect$(
    () => action$.pipe(ofType(SELECT_PATIENT), pluck('value'), tap(onChange)),
    [action$, onChange]
  );
  useEffect$(
    () => action$.pipe(ofType(SELECT_PATIENT, CLOSE_POPUP), tap(onClose)),
    [action$, onClose]
  );

  const onSelectPatient = (value = {}) => {
    dispatch({ type: SELECT_PATIENT, value });
    if (!disableRouting) {
      const { IPID } = value;
      navigate(`~/DefaultSearchFeature/${IPID}`);
    }
  };

  if (!!MenuType && MenuType.includes(FUNCTION)) {
    return (
      <>
        <UserAction
          {...props}
          {...state}
          onClick={() => dispatch({ type: OPEN_POPUP })}
        />
        {openPopup && (
          <DefaultView
            {...state}
            onSelectPatient={onSelectPatient}
            dispatch={dispatch}
          />
        )}
        {addPatientPopup && (
          <AddPatientPopup
            {...props}
            {...state}
            onSelectPatient={onSelectPatient}
            dispatch={dispatch}
          />
        )}
      </>
    );
  }

  return (
    <>
      <DefaultView
        {...state}
        onSelectPatient={onSelectPatient}
        dispatch={dispatch}
      />
      {addPatientPopup && (
        <AddPatientPopup
          {...props}
          {...state}
          onSelectPatient={onSelectPatient}
          dispatch={dispatch}
        />
      )}
    </>
  );
};

export default XePatientSearch;
