import { toConfigReducer } from './data';

const toSerializableBody = (body) => {
  /*
   * See SYNUI-7071:
   * Requests that send FormData will have a body that's serialized to `{}`,
   * resulting in those requests sharing same cache key.
   * This attempts to transform the form data into something that can be
   * serialized by the query handler so that there is a distinction between
   * requests (JDM)
   */
  if (body instanceof FormData) {
    const formDataBodyAsObject = {};
    body.forEach((fdValue, fdKey) => {
      // We also can't serialize a file, so we'll have to try our best here
      // to get some distinguishing information
      if (fdValue instanceof File) {
        formDataBodyAsObject[fdKey] = `${fdValue.name}_${fdValue.size}`;
      } else {
        formDataBodyAsObject[fdKey] = fdValue;
      }
    });

    return formDataBodyAsObject;
  }

  return body;
};

export const toCacheKeyFromRequest = (config = {}) => {
  const { service, action, params, url, body, responseType, headers } = config;

  const serializableBody = toSerializableBody(body);

  return [
    service,
    action,
    params,
    url,
    serializableBody,
    responseType,
    headers,
  ];
};

export const neverCache = toConfigReducer('staleTime')(0);
export const maximumCache = toConfigReducer('staleTime')(Infinity);
