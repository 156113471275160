// ajv 7+ is incompatible with eslint's ajv peer dependencies in npm 7+
// We use an alias for our ajv package to avoid dependency conflicts
// See https://github.com/eslint/eslint/issues/13888
import Ajv from 'ajv8';
import { filter as filterObj } from '../fp/object';
import { isNil } from '../fp/pred';
import toFormats from './formats';
const formats = toFormats();

//We have two types of primary keys, supplied and generated.
//This code is only going to deal with generated for today as the rules around supplied are
//underdetermined on the client
const toPrimaryKeyValidator = function (
  primaryKeyProp,
  schema,
  parentSchema /*, schemaObjCxt */
) {
  const { baseId, root: { refs = {}, refVal = [] } = {} } = parentSchema;
  const filteredSchema = filterObj(schema, (v, k) => k !== 'primaryKey');
  const standardValidator = this.compile(filteredSchema);

  //The actual schema in question here could be the parent schema but, in a one of/any of relationship
  //or even just a straight ref relationship, it is not. Instead you must get the relevant schema
  //from ajv to check the properties
  const relevantSchema = refVal[refs[baseId] || -1] || parentSchema;

  const { properties = {} } = relevantSchema;

  const shouldBeApplied = !!properties.CreateTStamp;

  // Handles the case of the few schemas that have a primaryKey but not a CreateTStamp field
  if (!shouldBeApplied) return standardValidator;

  return (currentValue, dataPath, fullInstance) => {
    // Almost all objects on the xnet server shoud have a create date if they have a primary key field
    // We could make this field name dynamic as a property on the schema if necessary
    const { CreateTStamp } = fullInstance;

    // If this is a generated primary key, it should only have a value iff CreateTStamp has a value
    if (primaryKeyProp === 'generated' && isNil(CreateTStamp)) {
      return isNil(currentValue);
    }

    return standardValidator(currentValue, dataPath, fullInstance);
  };
};

// This function is for an unimplemented case where we would add a 'secondaryKey' to all CreateTStamp objects
// eslint-disable-next-line no-unused-vars
// const toSecondaryKeyValidator = function (
//   isSecondary,
//   dataPath,
//   parentDataObject
// ) {
//   const { schema: localSchema } = parentDataObject;
//   const fullSchema = toFullSchema(parentDataObject);

//   const primaryFields = Object.keys(
//     filterObj(fullSchema, (v, k) => {
//       return v.primaryKey;
//     })
//   );

//   const filteredSchema = filterObj(localSchema, (v, k) => k !== 'secondaryKey');
//   const standardValidator = this.compile(filteredSchema);

//   return (currentValue, dataPath, fullInstance) => {
//     const allDependenciesFulfilled = primaryFields.every(
//       (k) => fullInstance[k]
//     );
//     return (
//       allDependenciesFulfilled &&
//       standardValidator(currentValue, dataPath, fullInstance)
//     );
//   };
// };

const toAjvInstance = (schemas = []) => {
  const validator = new Ajv({
    formats,
    schemas,
    allErrors: true,
    // We can remove this if we move from our custom 'defaultValue' property to the standard 'default'
    strictSchema: false,
  });

  validator.addKeyword({
    keyword: 'primaryKey',
    compile: toPrimaryKeyValidator,
  });
  // validator.addKeyword({ keyword: 'secondaryKey', compile: toSecondaryKeyValidator });

  return validator;
};

let instance = undefined;
export const getSingletonAJVInstance = () => {
  if (instance === undefined) {
    instance = toAjvInstance();
  }
  return instance;
};

// export const withAddedSchemas = (schemas = {}) => (validator) => {
//   Object.entries(schemas).forEach(([name, schema]) => {
//     const cachedSchema = validator.getSchema(name);
//     if (cachedSchema === undefined) {
//       validator.addSchema(schema);
//     }
//   });
//   return validator;
// };
