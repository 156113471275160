import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import { SHOULD_UPDATE_QUERY_STRING } from './actions';

const toDefaultState = () => {
  return { results: [] };
};

export default [
  when((state) => state === undefined)(toDefaultState),
  when(isType(SHOULD_UPDATE_QUERY_STRING))(toActionValueReducer('searchText')),
];
