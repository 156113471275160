import { useReducer } from 'react';
import { MaskedComboBox } from '../components/MaskedComboBox';
import {
  ORDER_CODES_RESPONSE,
  SHOULD_SEARCH_ORDER_CODES,
  SHOULD_SELECT_ITEM,
} from './actions';
import { castFunction } from '../../fp/fp';
import PropTypes from 'prop-types';
import { toServiceCodeName } from '../../utils/itemRenderers';
import { isNil } from '../../fp/pred';
import { EMPTY_OBJECT } from '../../constants';
import { useXeQuery } from '../../data/useXeQuery';
import { searchServiceCodes } from 'services/service-codes/xe-service-codes-svc';
import useOnOutsideValueChange from '../../hooks/useOnOutsideValueChange';

const reducer = (state, action) => {
  const { type, value } = action;

  switch (type) {
    case ORDER_CODES_RESPONSE: {
      return {
        ...state,
        orderCodes: value,
      };
    }
    case SHOULD_SELECT_ITEM: {
      return {
        ...state,
        value,
        orderCodes: [],
      };
    }
    case SHOULD_SEARCH_ORDER_CODES: {
      return {
        ...state,
        searchText: value?.trim() || '',
      };
    }
    default:
      return state;
  }
};

export const XeServiceCodeWidget = (props) => {
  const {
    dataElementName = '',
    isModifier,
    value: propsValue,
    onChange,
    serviceTypeId,
    ...rest
  } = props;

  const [state = {}, dispatch] = useReducer(reducer, EMPTY_OBJECT);

  const lastValueRef = useOnOutsideValueChange(propsValue, (value) =>
    dispatch({ type: SHOULD_SELECT_ITEM, value })
  );

  const { value, searchText } = state;

  const { data: results } = useXeQuery(
    searchServiceCodes(
      {
        searchText,
        isModifier,
        serviceTypeId,
      },
      (x) => x
    ),
    {
      enabled: searchText?.length > 0,
    }
  );

  return (
    <MaskedComboBox
      dataElementName={dataElementName}
      {...rest}
      data={results}
      data-xe-widget-name="XeServiceCodeWidget"
      clearOnSelect={isNil(propsValue)}
      onChange={(value) => {
        lastValueRef.current = value;
        dispatch({ type: SHOULD_SELECT_ITEM, value });
        castFunction(onChange)(value);
      }}
      value={value}
      labelFn={toServiceCodeName}
      onEnter={(value) => {
        dispatch({
          type: SHOULD_SEARCH_ORDER_CODES,
          value,
        });
      }}
    />
  );
};

XeServiceCodeWidget.propTypes = {
  clearButton: PropTypes.bool,
  dataElementName: PropTypes.string,
};
