import PropTypes from 'prop-types';
import DateRangeToggleBar from './components/DateRangeToggleBar';
import { DateTimeFormatNamePropType } from '../../g11n/ISODates';
import { UIControlLabel } from '../../components';
import { EMPTY_ARRAY } from '../../constants';

export const XeDateRangeWidget = (props) => {
  const {
    dataElementName = '',
    className = '',
    dateKeys = EMPTY_ARRAY,
    format,
    value,
    onChange,
    descriptorClassName,
    required,
    descriptor,
  } = props;

  return (
    <>
      {descriptor && (
        <UIControlLabel
          data-element-name={
            !!dataElementName ? `${dataElementName}__label` : 'label'
          }
          className={descriptorClassName}
          required={required}
        >
          {descriptor}
        </UIControlLabel>
      )}
      <div
        className={`${className} date-range-widget inline-block padding-horizontal-medium`}
        data-component-name="DateRangeWidget"
        data-element-name={dataElementName}
      >
        <DateRangeToggleBar
          dataElementName={dataElementName}
          data={dateKeys}
          value={value}
          onChange={onChange}
          format={format}
        />
      </div>
    </>
  );
};

XeDateRangeWidget.propTypes = {
  className: PropTypes.string,
  dateKeys: PropTypes.array,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  format: DateTimeFormatNamePropType,
};

export default XeDateRangeWidget;
