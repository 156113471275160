import { DateTime } from 'luxon';
import { or, isDate } from '../fp/pred';
import { identity } from '../fp/fp';
import { HEADER_CONTENT_TYPE } from './constants';

const existy = (x) => x !== null && x !== undefined;
const isEmptyString = (x) => x === '';
const isLuxonDateTime = DateTime.isDateTime;
const toISODate = (d) => d.toISOString();

const ParamProjections = [
  {
    pred: or(isDate, isLuxonDateTime),
    proj: toISODate,
  },
];

const toProjection = (v) => {
  const match = ParamProjections.find(({ pred }) => pred(v));
  if (!match) return identity;
  return match.proj;
};

const toParamValue = (v) => encodeURIComponent(toProjection(v)(v));

export const toQueryString = (params = {}) => {
  const str = Object.keys(params)
    .filter((k) => existy(params[k]) && !isEmptyString(params[k]))
    .map((k) => {
      const paramValue = params[k];

      if (Array.isArray(paramValue)) {
        return paramValue.map((v) => `${k}=${toParamValue(v, k)}`).join('&');
      }

      return `${k}=${toParamValue(params[k], k)}`;
    })
    .join('&');
  return str && `?${str}`;
};

export const toConfigReducer =
  (name) =>
  (value) =>
  (config = {}) => {
    return {
      ...config,
      [name]: value,
    };
  };

export const toHeaderReducer =
  (header) =>
  (value) =>
  (config = {}) => {
    const {
      headers: {
        [header]: existingHeader, //eslint-disable-line no-unused-vars
        ...restHeaders
      } = {},
      ...restConfig
    } = config;

    if (value === undefined)
      return { ...restConfig, headers: { ...restHeaders } };
    return { ...restConfig, headers: { ...restHeaders, [header]: value } };
  };

const toPossibleBody = (body, headers = {}) => {
  const { [HEADER_CONTENT_TYPE]: contentType } = headers;

  if (existy(body) && contentType === 'application/json') {
    return {
      body: JSON.stringify(body),
    };
  }

  return existy(body)
    ? {
        body,
      }
    : {};
};

export const toFetchConfig = ({
  method = 'GET',
  credentials = 'include',
  url = '',
  params = {},
  headers = {},
  responseType,
  body,
  signal,
} = {}) => {
  return {
    signal,
    method,
    headers,
    responseType: responseType || 'json',
    url: `${url}${toQueryString(params)}`,
    ...toPossibleBody(body, headers),
  };
};
