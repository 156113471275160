export const SHOULD_SEARCH_EXISTING_PROVIDERS =
  'addProvider/action/shouldSearchExistingProviders';
export const SHOULD_SELECT_PROVIDER_MATCH =
  'addProvider/action/shouldSelectContactMatch';
export const SHOULD_UPDATE_STAFF = 'addProvider/action/shouldUpdateStaff';
export const SHOULD_UPDATE_STAFF_CATEGORY =
  'addProvider/action/shouldUpdateStaffCategory';

export const DID_UPDATE_NEW_PROVIDER =
  'addProvidier/action/didUpdateSelectedContact';

export const RESPONSE_SEARCH_EXISTING_PROVIDERS =
  'addProvider/response/searchExistingProviders';

export const ON_PROVIDER_CHANGE = 'addProvider/callback/onProviderChange';
