import { get, isShallowEqual } from '../fp/object';
import {
  useReducer,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { toVerbActionReducer, INSTANCE } from './JSONSchemaReducer';
import { BehaviorSubject } from 'rxjs';
import { useFactory } from '../hooks/useFactory';
import { SchemaContext } from './SchemaReducer';
import { isObjectLike } from '../fp/pred';

const toFullPath = (parentPath = '', path) => {
  return parentPath.length > 0 ? `${parentPath}.${path}` : path;
};

export const SchemalessProducer = (props) => {
  const { onChange, initialValue } = props;

  const schemalessReducer = useFactory(() => {
    const verbReducer = toVerbActionReducer(initialValue);
    return (state = {}, action) => {
      const { [INSTANCE]: instance } = state;

      const newInstance = verbReducer(instance, action);

      if (newInstance === instance) {
        return state;
      }

      return {
        [INSTANCE]: newInstance,
      };
    };
  }, [initialValue]);

  const [state, dispatch] = useReducer(
    schemalessReducer,
    undefined,
    schemalessReducer
  );

  // NOTE: We made the conscious decision to only call onChange if the state changes,
  // not if a new onChange function is provided. This case did not seem to exist and this was
  // causing other concerns.
  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const contextValue$ = useFactory(
    () =>
      new BehaviorSubject({
        dispatch,
        state,
        fullPath: '',
      }),
    [dispatch]
  );

  useEffect(() => {
    contextValue$.next({
      dispatch,
      state,
      fullPath: '',
    });
  }, [dispatch, state, contextValue$]);

  return (
    <SchemaContext.Provider value={contextValue$}>
      {props.children}
    </SchemaContext.Provider>
  );
};

export const SchemalessConsumerProducer = (props) => {
  const { dataPath } = props;
  const [, forceRender] = useReducer((s) => s + 1, 0);

  const parentContext$ = useContext(SchemaContext);
  const lastParentContextValue = useRef();

  useEffect(() => {
    const subscription = parentContext$.subscribe((value) => {
      const { fullPath: nextFullPath, state: { instance: nextInstance } = {} } =
        value || {};
      const { fullPath: prevFullPath, state: { instance: prevInstance } = {} } =
        lastParentContextValue.current || {};

      if (
        !lastParentContextValue.current ||
        !isShallowEqual(
          get(toFullPath(nextFullPath, dataPath), nextInstance),
          get(toFullPath(prevFullPath, dataPath), prevInstance)
        )
      ) {
        lastParentContextValue.current = value;
        forceRender({});
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [parentContext$, dataPath]);

  if (!parentContext$.value) {
    console.error(
      `SchemalessReducer Declared with a dataPath of ${dataPath} but outside of a parent SchemalessReducer`
    );
  }

  const { fullPath: parentPath, dispatch } = parentContext$.value || {};

  const fullPath = toFullPath(parentPath, dataPath);

  const currentParentValue = parentContext$.value;
  const contextValue$ = useFactory(() => {
    return new BehaviorSubject({
      ...currentParentValue,
      fullPath,
    });
  }, [dispatch]);

  useEffect(() => {
    contextValue$.next({
      ...currentParentValue,
      fullPath,
    });
  }, [currentParentValue, fullPath, contextValue$]);

  return (
    <SchemaContext.Provider value={contextValue$}>
      {props.children}
    </SchemaContext.Provider>
  );
};

export const SchemalessReducer = (props) => {
  const { dataPath, initialValue } = props;

  let counter = useRef(0);

  const schemaCounter = useMemo(() => {
    counter.current++;

    const schemaKey = `schemaCounter_${counter.current}`;

    return schemaKey;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return dataPath === undefined ? (
    <SchemalessProducer {...props} key={schemaCounter} />
  ) : (
    <SchemalessConsumerProducer {...props} />
  );
};

export const useSchemalessDispatch = (appendPath = true) => {
  const contextValue$ = useContext(SchemaContext);
  const lastContextValue = useRef();
  const [, forceRender] = useReducer((s) => s + 1, 0);
  useEffect(() => {
    const subscription = contextValue$.subscribe((value) => {
      const { dispatch } = value;
      if (
        !lastContextValue.current ||
        lastContextValue.current.dispatch !== dispatch
      ) {
        lastContextValue.current = value;
        forceRender({});
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [contextValue$]);

  const { value: { dispatch, fullPath } = {} } = contextValue$;

  const memoizedDispatch = useCallback(
    (v) => {
      //if (!appendPath || !fullPath) return dispatch(v);
      if (!appendPath || !fullPath) {
        if (!isObjectLike(v) && !isObjectLike(v.value)) {
          return dispatch(v);
        }

        return dispatch(v);
      }

      const { path: providedPath } = v;

      return dispatch({
        ...v,
        path:
          typeof providedPath === 'string'
            ? toFullPath(fullPath, providedPath)
            : fullPath,
      });
    },
    [appendPath, dispatch, fullPath]
  );

  return memoizedDispatch;
};
