export const RESPONSE_SEARCH_PATIENTS = 'response/searchPatients';

export const SHOULD_SEARCH = 'action/ShouldSearch';
export const SELECT_PATIENT = 'action/SelectPatient';
export const CLEAR_PATIENTS = 'action/CLEAR_PATIENTS';
export const CLOSE_POPUP = 'action/CLOSE_POPUP';
export const OPEN_POPUP = 'action/OPEN_POPUP';
export const UPDATE_CAN_ADD_PATIENT = 'action/updateCanAddPatient';

export const SHOULD_VIEW_ADD_PATIENT = 'shouldViewAddPatient';
export const SHOULD_CLOSE_ADD_PATIENT = 'shouldCloseAddPatient';
