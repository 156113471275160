import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useXeRights } from '../../../../contexts/XeUserRightsContext';
import { isObjectLike } from '../../../../fp/pred';
import { pluck } from '../../../../fp/object';
import { Table } from '../../../../components/Table';
import { Input } from '../../../../components/Input';
import { Label } from '../../../../components/Label';
import { DropDownList } from '../../../../components/DropDownList';
import { useXeLabels } from '../../../../contexts/XeLabelContext';

export const IndividualFields = (props) => {
  const { StaffID, XeNameTitle, onStaffUpdate, shouldSearchExistingProviders } =
    props;

  const {
    FamilyName = '',
    GivenName = '',
    NationalProviderID = '',
    SecondName = '',
    DEANumber,
    NameTitleID,
    ExternalID,
  } = StaffID;

  const { EDIT_STAFF_EXTERNALID } = useXeRights();

  const onGivenNameChange = useCallback(
    (value) => onStaffUpdate('GivenName', value),
    [onStaffUpdate]
  );

  const onFamilyNameChange = useCallback(
    (value) => onStaffUpdate('FamilyName', value),
    [onStaffUpdate]
  );

  const onSecondNameChange = useCallback(
    (value) => onStaffUpdate('SecondName', value),
    [onStaffUpdate]
  );

  const onNationalProviderIDChange = useCallback(
    (value) => onStaffUpdate('NationalProviderID', value),
    [onStaffUpdate]
  );

  const onDEANumberChange = useCallback(
    (value) => onStaffUpdate('DEANumber', value),
    [onStaffUpdate]
  );

  const onNameTitleIDChange = useCallback(
    (value) => onStaffUpdate('NameTitleID', value),
    [onStaffUpdate]
  );

  const onExternalIDChange = useCallback(
    (value) => onStaffUpdate('ExternalID', value),
    [onStaffUpdate]
  );

  const selectedNameTitleID = useMemo(() => {
    const selectedNameTitleID = isObjectLike(NameTitleID)
      ? NameTitleID.NameTitleID
      : NameTitleID;
    return selectedNameTitleID;
  }, [NameTitleID]);

  const labels = useXeLabels();

  return (
    <>
      <Table.Row>
        <Table.Cell className="align-right add-provider__input-label">
          <Label dataElementName="addProvider__givenName__label" required>
            {labels.GivenName}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Input
            type="text"
            className="app-input"
            dataElementName="addProvider__givenName"
            value={GivenName}
            onChange={onGivenNameChange}
            onBlur={() => shouldSearchExistingProviders(StaffID)}
          />
        </Table.Cell>
        <Table.Cell className="align-right add-provider__input-label">
          <Label required dataElementName="addProvider__familyName__label">
            {labels.FamilyName}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Input
            type="text"
            className="app-input"
            value={FamilyName}
            dataElementName="addProvider__familyName"
            onChange={onFamilyNameChange}
            onBlur={() => shouldSearchExistingProviders(StaffID)}
          />
        </Table.Cell>
        <Table.Cell className="align-right add-provider__input-label">
          <Label dataElementName="addProvider__secondName__label">
            {labels.MiddleName}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Input
            type="text"
            className="app-input"
            value={SecondName}
            dataElementName="addProvider__secondName"
            onChange={onSecondNameChange}
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell className="align-right add-provider__input-label">
          <Label dataElementName="addProvider__title__label">
            {labels.Title}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <DropDownList
            data={XeNameTitle}
            value={selectedNameTitleID}
            valueFn={pluck('id')}
            dataElementName="addProvider__title"
            onChange={onNameTitleIDChange}
            labelFn={pluck('text')}
          />
        </Table.Cell>
        <Table.Cell className="align-right add-provider__input-label">
          <Label dataElementName="addProvider__NPI__label">{labels.NPI}</Label>
        </Table.Cell>
        <Table.Cell>
          <Input
            type="text"
            dataElementName="addProvider__NPI"
            className="app-input"
            value={NationalProviderID}
            onChange={onNationalProviderIDChange}
          />
        </Table.Cell>
        <Table.Cell className="align-right add-provider__input-label">
          <Label dataElementName="addProvider__DEANumber__label">
            {labels.DEANumber}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Input
            type="text"
            dataElementName="addProvider__DEANumber"
            className="app-input"
            value={DEANumber}
            onChange={onDEANumberChange}
          />
        </Table.Cell>
      </Table.Row>
      {!!EDIT_STAFF_EXTERNALID && (
        <Table.Row>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__id__label">{labels.ID}</Label>
          </Table.Cell>
          <Table.Cell>
            <Input
              type="text"
              dataElementName="addProvider__id"
              className="app-input"
              value={ExternalID}
              onChange={onExternalIDChange}
            />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

IndividualFields.propTypes = {
  StaffID: PropTypes.object.isRequired,
  XeNameTitle: PropTypes.array.isRequired,
  onStaffUpdate: PropTypes.func.isRequired,
  shouldSearchExistingProviders: PropTypes.func.isRequired,
};
