export const HEADER_XE_ENTERPRISE_ID = 'xe-enterprise-id';
export const HEADER_XE_APPLICATION = 'xe-application';
export const HEADER_XE_TIMEZONE = 'xe-timezone';
export const HEADER_XE_ENTERPRISE_SCOPE = 'xe-enterprise-scope';

export const HEADER_XE_PAGESIZE = 'xe-pagesize';
export const HEADER_XE_PAGEOFFSET = 'xe-pageoffset';
export const HEADER_XE_QUERYSORT = 'xe-querysort';

export const HEADER_CONTENT_TYPE = 'Content-Type';
