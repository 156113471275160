import { getSingletonAJVInstance } from './jsonSchemaValidator';
import {
  isConstant,
  toEmptyReferencedSchemas,
  findRelatedSchema,
  isSimpleTypeDeclaration,
  isRefDeclaration,
  isItemsDeclaration,
  isOneOfDeclaration,
  convertToSimpleType,
  isObjectDeclaration,
  toValidDeclaration,
  hasKeyDeclarationNamed,
  getSuppliedKeyName,
  getGeneratedKeyName,
  getPropertyDefinitionNamed,
  isSchemaPrimitive,
} from './schemaTraversal';
import { isEmptyObject } from '../fp/pred';

/*** compound predicates ****/
const isSchemaConstant = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key
) => {
  return (
    key === '$schema' &&
    isConstant(fromDeclaration) &&
    isConstant(toDeclaration)
  );
};

const isSimpleToSimple = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration
) => {
  return (
    isSimpleTypeDeclaration(fromDeclaration) &&
    isSimpleTypeDeclaration(toDeclaration)
  );
};

const isItemsToItems = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration
) => {
  return (
    isItemsDeclaration(fromDeclaration) && isItemsDeclaration(toDeclaration)
  );
};

const isNonItemsToItems = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration
) => {
  return (
    !isItemsDeclaration(fromDeclaration) && isItemsDeclaration(toDeclaration)
  );
};

const isObjectToObject = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration
) => {
  return (
    isObjectDeclaration(fromDeclaration) && isObjectDeclaration(toDeclaration)
  );
};

const isFromRef = (validator, schemaFinder, fromDeclaration) => {
  return isRefDeclaration(fromDeclaration);
};

const isToRef = (validator, schemaFinder, fromDeclaration, toDeclaration) => {
  return isRefDeclaration(toDeclaration);
};

const isFromOneOf = (validator, schemaFinder, fromDeclaration) => {
  return isOneOfDeclaration(fromDeclaration);
};

const isToOneOf = (validator, schemaFinder, fromDeclaration, toDeclaration) => {
  return isOneOfDeclaration(toDeclaration);
};

const isPrimaryKeyPluck = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key,
  parent
) => {
  if (
    !(
      isObjectDeclaration(fromDeclaration) &&
      isSimpleTypeDeclaration(toDeclaration)
    )
  ) {
    return false;
  }

  return (
    hasKeyDeclarationNamed(fromDeclaration, key) ||
    getSuppliedKeyName(fromDeclaration) ||
    getGeneratedKeyName(fromDeclaration)
  );
};

const isPrimaryKeyExpansion = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key
) => {
  if (
    !(
      isSimpleTypeDeclaration(fromDeclaration) &&
      isObjectDeclaration(toDeclaration)
    )
  ) {
    return false;
  }

  return hasKeyDeclarationNamed(toDeclaration, key);
};

/*** Transformation functions ****/
const setSchemaType = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value
) => {
  const { ['const']: constant } = toDeclaration;

  return constant;
};

const simpleToSimple = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value
) => {
  const { type, nullable = false } = toDeclaration;

  if (value === null) {
    return nullable ? value : undefined;
  }

  return convertToSimpleType(type, value);
};

const itemsToItems = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value
) => {
  const { items: fromItems } = fromDeclaration;
  const { items: toItems } = toDeclaration;

  return value.map((value, index, array) =>
    transformDeclaration(
      validator,
      schemaFinder,
      fromItems,
      toItems,
      value,
      index,
      array
    )
  );
};

const nonItemsToItems = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key,
  parent
) => {
  const { items: toItems } = toDeclaration;

  const transformedValue = transformDeclaration(
    validator,
    schemaFinder,
    fromDeclaration,
    toItems,
    value,
    key,
    parent
  );
  return [transformedValue];
};

const objectToObject = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value
) => {
  const { properties: fromProperties = {}, ['$id']: fromID } = fromDeclaration;
  const { properties: toProperties = {}, ['$id']: toID } = toDeclaration;

  if (fromID === toID && value['$schema'] === toID) {
    //we should be able to do a very early bail here and save lots of energy
    return value;
  }

  const newObject = Object.entries(value).reduce((newInstance, [k, v]) => {
    const fromDeclaration = fromProperties[k];
    const toDeclaration = toProperties[k];

    if (!toDeclaration) {
      return newInstance; //prune the key, it's not in the toSchema
    }

    const migratedValue = transformDeclaration(
      validator,
      schemaFinder,
      fromDeclaration,
      toDeclaration,
      v,
      k,
      value
    );

    if (isEmptyObject(migratedValue)) {
      return newInstance;
    }

    return {
      ...newInstance,
      [k]: migratedValue,
    };
  }, {});

  //Technically this is redundant in the case of a transformation as, if all objects were right, this should already be done
  //but trying for belt and suspenders here
  if (!isEmptyObject(newObject) && toID && !newObject['$schema']) {
    return {
      $schema: toID,
      ...newObject,
    };
  }

  return newObject;
};

const resolvedFromOneOf = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key,
  parent
) => {
  const { oneOf, anyOf } = fromDeclaration;
  const oneOrAnyOf = anyOf || oneOf || [];

  const actualFromDeclaration = toValidDeclaration(
    validator,
    oneOrAnyOf,
    value
  );

  return transformDeclaration(
    validator,
    schemaFinder,
    actualFromDeclaration,
    toDeclaration,
    value,
    key,
    parent
  );
};

const toBestGuessDeclaration = (oneOf, value) => {
  return oneOf.find((declaration) => {
    return (
      (isSimpleTypeDeclaration(declaration) && isSchemaPrimitive(value)) ||
      (!isSimpleTypeDeclaration(declaration) && !isSchemaPrimitive(value))
    );
  });
};

const resolvedToOneOf = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key,
  parent
) => {
  const { oneOf, anyOf } = toDeclaration;
  const oneOrAnyOf = anyOf || oneOf || [];

  const actualToDeclaration =
    toValidDeclaration(validator, oneOrAnyOf, value) ||
    toBestGuessDeclaration(oneOrAnyOf, value);

  return transformDeclaration(
    validator,
    schemaFinder,
    fromDeclaration,
    actualToDeclaration,
    value,
    key,
    parent
  );
};

const resolveFromRef = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key,
  parent
) => {
  const { ['$ref']: fromRef } = fromDeclaration;
  const fromSchema = schemaFinder(fromRef);

  const {
    baseSchema: fromBaseSchema = {},
    toReferencedSchemas = toEmptyReferencedSchemas,
  } = fromSchema;

  const nextSchemaFinder = findRelatedSchema(
    toReferencedSchemas(),
    schemaFinder
  );

  return transformDeclaration(
    validator,
    nextSchemaFinder,
    fromBaseSchema,
    toDeclaration,
    value,
    key,
    parent
  );
};

const resolveToRef = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key,
  parent
) => {
  const { ['$ref']: fromRef } = toDeclaration;

  const toSchema = schemaFinder(fromRef);
  const {
    baseSchema: toBaseSchema = {},
    toReferencedSchemas = toEmptyReferencedSchemas,
  } = toSchema;

  const nextSchemaFinder = findRelatedSchema(
    toReferencedSchemas(),
    schemaFinder
  );

  return transformDeclaration(
    validator,
    nextSchemaFinder,
    fromDeclaration,
    toBaseSchema,
    value,
    key,
    parent
  );
};

const resolvedToSimplePK = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key
) => {
  //fallback order.... always try to use the one with the proper name but if that won't work.... fall back
  const propName = getPropertyDefinitionNamed(fromDeclaration, key)
    ? key
    : getGeneratedKeyName(fromDeclaration) ||
      getSuppliedKeyName(fromDeclaration);
  const declaration = getPropertyDefinitionNamed(fromDeclaration, propName);

  return transformDeclaration(
    validator,
    schemaFinder,
    declaration,
    toDeclaration,
    value[propName],
    key,
    value
  );
};

const resolvedToObjectPK = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key,
  parent
) => {
  const declaration = getPropertyDefinitionNamed(toDeclaration, key);
  const { ['$id']: id } = toDeclaration;

  //If we want to figure out how to get other properties like EnterpriseID in... this is the place
  return {
    $schema: id,
    [key]: transformDeclaration(
      validator,
      schemaFinder,
      fromDeclaration,
      declaration,
      value,
      key,
      parent
    ),
  };
};

const transformPaths = [
  [isSchemaConstant, setSchemaType],
  [isSimpleToSimple, simpleToSimple],
  [isItemsToItems, itemsToItems],
  [isNonItemsToItems, nonItemsToItems],
  [isObjectToObject, objectToObject],
  [isFromRef, resolveFromRef],
  [isToRef, resolveToRef],
  [isFromOneOf, resolvedFromOneOf],
  [isToOneOf, resolvedToOneOf],
  [isPrimaryKeyPluck, resolvedToSimplePK],
  [isPrimaryKeyExpansion, resolvedToObjectPK],
  [() => true, () => undefined],
];

export const transformDeclaration = (
  validator,
  schemaFinder,
  fromDeclaration,
  toDeclaration,
  value,
  key,
  parent
) => {
  const foundCase = transformPaths.find(([pred]) => {
    return pred(
      validator,
      schemaFinder,
      fromDeclaration,
      toDeclaration,
      value,
      key,
      parent
    );
  });

  const [, proj] = foundCase;

  //console.log('Matched case # ', transformPaths.indexOf(foundCase));
  //console.log('recurse: ----------------');

  return proj(
    validator,
    schemaFinder,
    fromDeclaration,
    toDeclaration,
    value,
    key,
    parent
  );
};

export const transformSchema = (
  fromSchema,
  toSchema,
  validator = getSingletonAJVInstance()
) => {
  const {
    baseSchema: fromBaseSchema = {},
    toReferencedSchemas: toReferencedFromSchemas = toEmptyReferencedSchemas,
    registerSchema: registerFromSchema,
  } = fromSchema;

  const {
    baseSchema: toBaseSchema = {},
    toReferencedSchemas: toReferencedToSchemas = toEmptyReferencedSchemas,
    registerSchema: registerToSchema,
  } = toSchema;

  registerFromSchema(validator);
  registerToSchema(validator);

  const fromReferences = toReferencedFromSchemas();
  const toReferences = toReferencedToSchemas();
  const schemaFinder = findRelatedSchema([
    ...fromReferences,
    fromSchema,
    ...toReferences,
    toSchema,
  ]);

  return (instance) => {
    const isValid = validator.validate(fromBaseSchema, instance);

    if (!isValid && validator) {
      const errors = validator.errors || [];
      console.error('Invalid schema transformation', ...errors);
      throw new Error('The above error occurred when transforming a schema');
    }

    //take me out when debugging, helpful optimization but makes things unclear
    if (fromBaseSchema === toBaseSchema) {
      //same schema and we are already valid, make this much easier
      return instance;
    }

    return transformDeclaration(
      validator,
      schemaFinder,
      fromBaseSchema,
      toBaseSchema,
      instance,
      undefined,
      instance
    );
  };
};
