import { Grid } from '../../../../components';
import { Popup, toDefaultPopupFooter, Panel } from '../../../../components';
import {
  SHOULD_CLEAR_ADVANCED_SEARCH,
  SHOULD_SEARCH_ADVANCED,
  SHOULD_UPDATE_ADVANCED_SEARCH,
} from '../actions';
import StaffSearchAdvanced from '../components/StaffSearchAdvanced';
import StaffSearchHeader from '../components/StaffSearchHeader';
import { advancedColumnDefs } from '../constants';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../constants';

export const XeStaffSearchPicker = (props) => {
  const {
    dispatch,
    onClose,
    onChange,
    onOpenAdd,
    advancedResults: results = EMPTY_ARRAY,
    advancedSearchConfig = EMPTY_OBJECT,
    showAdd,
    isFetching,
  } = props;

  const {
    searchZip,
    searchText,
    searchSpecialty,
    searchStatus,
    allSearchCriteriaAvailable,
  } = advancedSearchConfig;

  const labels = useXeLabels();

  return (
    <Popup
      dataElementName="staffSearchWidget___pickerPopup"
      FooterComponent={toDefaultPopupFooter({ onClose })}
      title={labels.StaffSearch}
      size="large"
    >
      <div
        className="staff-search vertical-flex-container flex-1"
        data-xe-feature-name="XeStaffSearchPicker"
      >
        <Panel
          className="staff-search-popup-wrapper__panel"
          shouldOverflow={false}
        >
          <StaffSearchHeader
            staffName={searchText}
            searchEnabled={true}
            isClearFilterDisabled={
              searchText === '' && searchZip === '' && searchSpecialty === ''
            }
            showAllFilters={allSearchCriteriaAvailable}
            onUpdateSearchText={({ value } = {}) =>
              dispatch({
                type: SHOULD_UPDATE_ADVANCED_SEARCH,
                value: {
                  key: 'searchText',
                  value,
                },
              })
            }
            onToggleExpandFilters={() =>
              dispatch({
                type: SHOULD_UPDATE_ADVANCED_SEARCH,
                value: {
                  key: 'allSearchCriteriaAvailable',
                  value: !allSearchCriteriaAvailable,
                },
              })
            }
            onSearch={() => {
              dispatch({
                type: SHOULD_SEARCH_ADVANCED,
                value: {
                  searchText,
                  zipPostalCode: searchZip,
                  specialty: searchSpecialty,
                  status: searchStatus,
                },
              });
            }}
            onClear={() =>
              dispatch({
                type: SHOULD_CLEAR_ADVANCED_SEARCH,
              })
            }
            showAdd={showAdd}
            shouldAddNewItem={onOpenAdd}
          />
          {allSearchCriteriaAvailable && (
            <StaffSearchAdvanced
              zip={searchZip}
              specialty={searchSpecialty}
              status={searchStatus}
              onUpdateZip={({ value } = {}) =>
                dispatch({
                  type: SHOULD_UPDATE_ADVANCED_SEARCH,
                  value: {
                    key: 'searchZip',
                    value,
                  },
                })
              }
              onUpdateSpecialty={({ value } = {}) =>
                dispatch({
                  type: SHOULD_UPDATE_ADVANCED_SEARCH,
                  value: {
                    key: 'searchSpecialty',
                    value,
                  },
                })
              }
              onUpdateStatus={({ value } = {}) =>
                dispatch({
                  type: SHOULD_UPDATE_ADVANCED_SEARCH,
                  value: {
                    key: 'searchStatus',
                    value,
                  },
                })
              }
            />
          )}
        </Panel>
        <Panel
          data-component-name="StaffSearchDataGrid"
          className="staff-search-data-container"
        >
          <Grid
            onRowClicked={({ data } = {}) => onChange(data)}
            columnDefs={advancedColumnDefs}
            data={results}
            isLoading={isFetching}
          />
        </Panel>
      </div>
    </Popup>
  );
};

export default XeStaffSearchPicker;
