import { ofType } from '../frp/operators/ofType';
import { exists } from '../frp/operators/exists';
import { combineWithLatestFrom } from '../frp/operators/combineWithLatestFrom';
import { ofChangedPropWhenExists } from '../frp/operators/ofChangedPropWhenExists';
import { map, mergeMap, pluck } from 'rxjs/operators';
import { REQUEST_CREATE_STAFF, RESPONSE_CREATE_STAFF } from './actions';
import { DEFAULT_CONTACT_TYPE } from './constants';
import { createOrFind } from 'services/staffs/xe-staffs-svc';
import { toMenuNodeRequestFn } from '../operators/toMenuNodeRequestFn';

export const toCreateRequest$Epic = (action$, state$) =>
  action$.pipe(
    ofType(REQUEST_CREATE_STAFF),
    pluck('value'),
    exists(),
    combineWithLatestFrom(
      state$.pipe(
        ofChangedPropWhenExists(
          'contexts',
          'enterprise',
          'userData',
          'EnterpriseID'
        )
      ),
      state$.pipe(toMenuNodeRequestFn())
    ),
    mergeMap(([staff, EnterpriseID, toRequest$]) => {
      const { XeStaffAddress } = staff;
      return createOrFind(
        {
          ...staff,
          EnterpriseID,
          XeStaffAddress: [
            { ...XeStaffAddress, ContactInfoTypeID: DEFAULT_CONTACT_TYPE },
          ],
        },
        {},
        toRequest$()
      );
    }),
    map((results) => ({
      type: RESPONSE_CREATE_STAFF,
      value: results,
    }))
  );

export default [toCreateRequest$Epic];
