import { useRef, useEffect, useState } from 'react';
import { pluck } from '../../fp/object';
import { isNil } from '../../fp/pred';
import { IconButton, TogglableList, Flexbox } from '../../components';
import { REMOVE, ADD } from '../../icons';
import './styles.css';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { ViewSwitcher } from '../../components/ViewSwitcher';
import { EMPTY_ARRAY, EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { useScopedSelector } from '../../hooks/scopedReducer';

export const EditPatientDetailsLayout = (props) => {
  const {
    useRecipientsIndex = () => EMPTY_ARRAY,
    list = EMPTY_ARRAY,
    listRenderItem,
    dataItemKey = '',
    onSelectItem = NOOP_FUNCTION,
    canAdd,
    onAdd,
    canDeletePred,
    onDelete,
    keyFn,
    Form,
    Header,
    onChange,
    context,
  } = props;

  const touchedContactData =
    useScopedSelector(pluck('touchedContactData')) || EMPTY_ARRAY;

  const labels = useXeLabels();
  const lastLengthRef = useRef(list.length);
  // If useRecipientsIndex is not provided from a HOC, local state is used. SNETEXT-333 (MF)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [recipientsIndex = currentIndex, setRecipientsIndex = setCurrentIndex] =
    useRecipientsIndex();

  const selectedItem = list[recipientsIndex];

  useEffect(() => {
    const lastLength = lastLengthRef.current;
    if (list.length < lastLength) {
      setRecipientsIndex(0);
    } else if (list.length > lastLength) {
      setRecipientsIndex(lastLength);
    }
    lastLengthRef.current = list.length;
  }, [list.length]);

  return (
    <Flexbox className="flex-1">
      <div className="overflow-auto flex-shrink-0">
        <Flexbox
          dataElementName="editPatientDetails__header"
          direction="row"
          alignItems="center"
        >
          {Header && <Header />}
          {canAdd && (
            <IconButton
              icon={ADD}
              description={labels.Add}
              onClick={onAdd}
              dataElementName="add"
            />
          )}
        </Flexbox>
        <div
          className="overflow-auto"
          data-element-name="editPatientDetailsLayout__list"
        >
          <TogglableList
            data={list}
            value={selectedItem}
            onChange={({ item, index }) => {
              setRecipientsIndex(index);
              onSelectItem(item, index);
            }}
            renderItem={({ item, index }) => {
              const { valid } = item;
              const { valid: touchedValid = true } =
                touchedContactData[index] || EMPTY_OBJECT;
              const validity = isNil(valid) ? touchedValid : valid;
              return (
                <Flexbox
                  dataElementName={`item${index}`}
                  className={`flex-1 edit-patient-details-layout__list-item ${
                    validity
                      ? ''
                      : 'edit-patient-details-layout__list-item--invalid'
                  }`}
                  justifyContent="space-between"
                >
                  {listRenderItem({ item, index })}
                  {canDeletePred(item) && (
                    <IconButton
                      icon={REMOVE}
                      description={labels.Remove}
                      onClick={() => {
                        onDelete({ item, index });
                      }}
                      dataElementName="delete"
                    />
                  )}
                </Flexbox>
              );
            }}
            showItemPred={(item) => {
              const isActive = dataItemKey
                ? pluck(dataItemKey, 'Active')(item)
                : pluck('Active')(item);
              return isActive !== false;
            }}
            NoItemsElement={
              <div className="edit-patient-details-layout__list-item">
                {labels.NoResults}
              </div>
            }
            keyFn={keyFn}
          />
        </div>
      </div>
      <div className="vertical-separator" />
      <div className="overflow-auto flex-1">
        <ViewSwitcher activeViewId={recipientsIndex} lazyRenderViews={false}>
          {list.map((listItem, index) => {
            return (
              <ViewSwitcher.View
                className="view-switcher"
                viewId={index}
                key={keyFn ? keyFn(listItem, index) : index}
              >
                <Form
                  item={listItem}
                  index={index}
                  onChange={onChange}
                  context={context}
                />
              </ViewSwitcher.View>
            );
          })}
        </ViewSwitcher>
      </div>
    </Flexbox>
  );
};
