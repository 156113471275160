import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBar,
  Icon,
  CustomDateRangePickerPopup,
} from '../../../components';
import { CUSTOM_DATE_RANGE } from '../../../icons';
import { DateTimeFormatNamePropType } from '../../../g11n/ISODates';
import { EMPTY_ARRAY, EMPTY_OBJECT, NOOP_FUNCTION } from '../../../constants';
import { CUSTOM, CUSTOM_SEPARATOR } from '../../../dates/dateOptions';
import { useXeLabels } from '../../../contexts/XeLabelContext';
import { toTooltip } from '../utils';

const isCustomKey = (key) => key === CUSTOM || key.includes(CUSTOM_SEPARATOR);
const toLabelFn = (dateButtonRef) => (key) => {
  const labels = useXeLabels();

  if (isCustomKey(key)) {
    return (
      <Icon
        dataElementName="customDateRange"
        icon={CUSTOM_DATE_RANGE}
        ref={dateButtonRef}
        className="date-range-calendar-svg"
      />
    );
  }

  return labels[key] || key;
};

const toInitialDateSelection = (key) => {
  if (!key.includes(CUSTOM_SEPARATOR)) return null;

  const [start, end] = key.split(CUSTOM_SEPARATOR);
  return { start, end };
};

export const DateRangeToggleBar = (props) => {
  const {
    data = EMPTY_ARRAY,
    value = '',
    onChange,
    format,
    dataElementName = '',
  } = props;
  const dateButtonRef = useRef();
  const [showCustomRangePopup, setShowCustomRangePopup] = useState(false);

  const initialDateSelection = toInitialDateSelection(value);

  return (
    <div>
      <ButtonBar
        dataElementName={
          !!dataElementName
            ? `${dataElementName}__buttonBar`
            : 'dateRange__buttonBar'
        }
        data={data}
        selectedItem={value.includes(CUSTOM_SEPARATOR) ? CUSTOM : value}
        onSelect={(value) => {
          if (isCustomKey(value)) {
            if (showCustomRangePopup) return;
            setShowCustomRangePopup(true);
          } else {
            onChange(value);
          }
        }}
        labelFn={toLabelFn(dateButtonRef)}
        tooltipFn={toTooltip}
      />
      {/* TODO: it looks like the picker takes a value that is a different shape than what it passes back out */}
      {/* Might be able to clean this up, but the picker IS used outside of this widget, so have to be careful about that */}
      <CustomDateRangePickerPopup
        showPopup={showCustomRangePopup}
        value={initialDateSelection}
        anchor={dateButtonRef.current}
        onClose={() => setShowCustomRangePopup(false)}
        onChange={({ range = EMPTY_OBJECT } = EMPTY_OBJECT) => {
          const { from = NOOP_FUNCTION, to = NOOP_FUNCTION } = range;
          const fromDate = from();
          const toDate = to();

          onChange(`${fromDate}|${toDate}`);
          setShowCustomRangePopup(false);
        }}
        format={format}
      />
    </div>
  );
};

DateRangeToggleBar.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  format: DateTimeFormatNamePropType,
};

export default DateRangeToggleBar;
