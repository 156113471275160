import {
  toEmptyReferencedSchemas,
  findRelatedSchema,
  isSimpleTypeDeclaration,
  isRefDeclaration,
  isItemsDeclaration,
  isOneOfDeclaration,
  isSchemaPrimitive,
  convertToSimpleType,
  isObjectDeclaration,
  hasKeyDeclarationNamed,
  getPropertyDefinitionNamed,
  getSchemaNode,
} from './schemaTraversal';
import { isObjectLike, isEmptyObject } from '../fp/pred';

/*** compound predicates ****/
const isSimpleToSimple = (schemaFinder, declaration, value) => {
  return isSimpleTypeDeclaration(declaration) && isSchemaPrimitive(value);
};

const isArrayToItems = (schemaFinder, declaration, value) => {
  return isItemsDeclaration(declaration) && Array.isArray(value);
};

const isNonArrayToItems = (schemaFinder, declaration, value) => {
  return isItemsDeclaration(declaration) && !Array.isArray(value);
};

const isObjectToObject = (schemaFinder, declaration, value) => {
  return (
    isObjectDeclaration(declaration) &&
    !Array.isArray(value) &&
    isObjectLike(value)
  );
};

const isToRef = (schemaFinder, declaration) => {
  return isRefDeclaration(declaration);
};

const isToOneOf = (schemaFinder, declaration) => {
  return isOneOfDeclaration(declaration);
};

const isPrimaryKeyPluck = (schemaFinder, declaration, value, key) => {
  if (
    !(
      isObjectLike(value) &&
      !Array.isArray(value) &&
      isSimpleTypeDeclaration(declaration)
    )
  ) {
    return false;
  }

  //Pattern is that we have an object named foo, with a key named foo
  return value[key] !== undefined;
};

const isPrimaryKeyExpansion = (schemaFinder, declaration, value, key) => {
  if (!(isSchemaPrimitive(value) && isObjectDeclaration(declaration))) {
    return false;
  }

  return hasKeyDeclarationNamed(declaration, key);
};

/*** Transformation functions ****/
const simpleToSimple = (schemaFinder, declaration, value) => {
  const { type, nullable = false } = declaration;

  if (value === null) {
    return nullable ? value : undefined;
  }

  return convertToSimpleType(type, value);
};

const arrayToItems = (schemaFinder, declaration, value) => {
  const { items } = declaration;

  return value.map((value, index, array) =>
    castDeclaration(schemaFinder, items, value, index, array)
  );
};

const nonArrayToItems = (schemaFinder, declaration, value, key, parent) => {
  const { items } = declaration;

  const castValue = castDeclaration(schemaFinder, items, value, key, parent);
  if (castValue === undefined) {
    return castValue;
  }

  return [castValue];
};

const objectToObject = (schemaFinder, declaration, value) => {
  const { properties = {}, ['$id']: id } = declaration;
  const { ['$schema']: schema } = value;

  if (schema === id) {
    //early bail.... this claims to be the type we need
    return value;
  }

  const newObject = Object.entries(value).reduce((newInstance, [k, v]) => {
    const toDeclaration = properties[k];

    if (!toDeclaration) {
      return newInstance; //prune the key, it's not in the toSchema
    }

    const migratedValue = castDeclaration(
      schemaFinder,
      toDeclaration,
      v,
      k,
      value
    );

    if (migratedValue === undefined || isEmptyObject(migratedValue)) {
      return newInstance;
    }

    return {
      ...newInstance,
      [k]: migratedValue,
    };
  }, {});

  if (id && !isEmptyObject(newObject)) {
    return {
      $schema: id,
      ...newObject,
    };
  }

  return newObject;
};

const toRef = (schemaFinder, declaration, value, key, parent) => {
  const { ['$ref']: ref } = declaration;

  const schema = schemaFinder(ref);

  const {
    baseSchema: baseSchema = {},
    toReferencedSchemas = toEmptyReferencedSchemas,
  } = schema;

  const nextSchemaFinder = findRelatedSchema(
    toReferencedSchemas(),
    schemaFinder
  );

  return castDeclaration(nextSchemaFinder, baseSchema, value, key, parent);
};

const toSortValue = (preferredType) => (o) => {
  const baseValue = (isObjectLike(o) && Object.keys(o).length) || 1;

  return preferredType === typeof o ? baseValue + 10 : baseValue;
};

const toOneOf = (schemaFinder, declaration, value, key, parent) => {
  const { oneOf, anyOf } = declaration;
  const oneOrAnyOf = anyOf || oneOf || [];

  const possibilities = oneOrAnyOf
    .map((declaration) => {
      return castDeclaration(schemaFinder, declaration, value, key, parent);
    })
    .filter((x) => x !== undefined);

  const sortProjection = toSortValue(typeof value);

  const ordered = possibilities.sort(
    (a, b) => sortProjection(b) - sortProjection(a)
  );
  const [selected] = ordered;

  return selected;
};

const toSimplePK = (schemaFinder, declaration, value, key) => {
  return castDeclaration(schemaFinder, declaration, value[key], key, value);
};

const toObjectPK = (schemaFinder, declaration, value, key, parent) => {
  const pkDeclaration = getPropertyDefinitionNamed(declaration, key);
  const { ['$id']: id } = declaration;

  //If we want to figure out how to get other properties like EnterpriseID in... this is the place
  return {
    $schema: id,
    [key]: castDeclaration(schemaFinder, pkDeclaration, value, key, parent),
  };
};

const castPaths = [
  [isSimpleToSimple, simpleToSimple],
  [isArrayToItems, arrayToItems],
  [isNonArrayToItems, nonArrayToItems],
  [isObjectToObject, objectToObject],
  [isToRef, toRef],
  [isToOneOf, toOneOf],
  [isPrimaryKeyPluck, toSimplePK],
  [isPrimaryKeyExpansion, toObjectPK],
  [() => true, () => undefined],
];

const castDeclaration = (schemaFinder, declaration, value, key, parent) => {
  const foundCase = castPaths.find(([pred], index) => {
    return pred(schemaFinder, declaration, value, key, parent);
  });

  const [, proj] = foundCase;

  // console.log('Matched case # ', castPaths.indexOf(foundCase));
  // console.log('recurse: ----------------');

  return proj(schemaFinder, declaration, value, key, parent);
};

export const castSchema = (schema) => {
  const {
    baseSchema = {},
    toReferencedSchemas = toEmptyReferencedSchemas,
    //registerSchema
  } = schema;
  const references = toReferencedSchemas();
  const schemaFinder = findRelatedSchema([...references, schema]);

  return (instance) => {
    return castDeclaration(
      schemaFinder,
      baseSchema,
      instance,
      undefined,
      instance
    );
  };
};

export const castDeclarationAtPath = (schema, path) => {
  const {
    toReferencedSchemas = toEmptyReferencedSchemas,
    //registerSchema
  } = schema;
  const references = toReferencedSchemas();
  const { declaration, referencedSchemas } = getSchemaNode(schema, path);

  //TODO optimization point, we are adding way too many schemas here.... in truth we just need the schemas for the path
  //but need to get this working first
  const schemaFinder = findRelatedSchema([
    ...references,
    ...referencedSchemas,
    schema,
  ]);

  return (instance) => {
    return castDeclaration(
      schemaFinder,
      declaration,
      instance,
      undefined,
      instance
    );
  };
};
