import {
  emailRegxString,
  phoneNumberRegxString,
  zipCodeRegxString,
  spacesOnlyRegexString,
  faxNumberRegxString,
} from './inputRegxStrings';
import { not, or, and, isString, exists } from '../fp/pred';

const objPluck = (k) => {
  const keys = k.split('.');
  return keys.length > 1
    ? (obj) => objPluck(keys.slice(1).join('.'))(obj[keys[0]])
    : (obj) => obj && obj[keys[0]];
};

/**
 * Takes a value and returns true or false if the value is 5 length digit
 * @param {string} zip argument to validate
 * @returns {boolean} isValid
 */
export const isValidZip = (zip) => {
  const re = new RegExp(zipCodeRegxString);
  return re.test(zip);
};

/**
 * Takes a value and returns true or false if the value is an email
 * @param {string} email argument to validate
 * @returns {boolean} isValid
 */
export const isValidEmail = (email) => {
  const re = new RegExp(emailRegxString);
  return re.test(email);
};

/**
 * Takes a value and returns true or false if the value is an phone number
 * @param {string} phoneNumber argument to validate
 * @returns {boolean} isValid
 */
export const isValidPhoneNumber = (phoneNumber) => {
  const re = new RegExp(phoneNumberRegxString);
  return re.test(phoneNumber);
};

/**
 * faxes have special validity rules see SYNUI-7937
 * @param {string} faxNumber a potential fax number
 * @returns {boolean} isValid
 */
export const isValidFaxNumber = (faxNumber) => {
  const faxRegExp = new RegExp(faxNumberRegxString);
  const setOfEightZeros = new RegExp(/0{8}/);
  return faxRegExp.test(faxNumber) && !setOfEightZeros.test(faxNumber);
};

const emptySpacesOnlyRegex = new RegExp(spacesOnlyRegexString);
export const isSpacesOnly = (s) => emptySpacesOnlyRegex.test(s);
const isStringEmpty = (s) => s === '';
const existsAndIsNotEmptyString = and(exists, not(isStringEmpty));

// The only time we trim regardless of validity is if the value is just empty spaces (AZ)
export const handleInputTrim = ({ target: { value, validity } }) => {
  if (isSpacesOnly(value)) {
    return value.trim();
  }
  return validity.valid ? value.trim() : value;
};

/**
 * Takes a value and returns true or false if the value is a valid string input
 * Pred is used for required text fields
    undefined => false
    '' => false
    ' ' => false
    ' a ' => true
 * @param {string} s argument to validate 
 * @returns {boolean} isValid
 */
export const isValidTextString = and(
  isString,
  // is not
  not(or(isStringEmpty, isSpacesOnly))
);

/**
 * Takes a key 'k' and returns a predicate function.
 * The predicate function takes in an object, which will be plucked at 'k'
 * The value plucked will be predicated by isValidTextString function
 * @param {string} k key
 * @returns {function} isValidTextString predicate with the value at 'k'
 */
export const isValidObjectWithTextStringAt = (k) => (obj) =>
  isValidTextString(objPluck(k)(obj));

// A lot of forms have inputs that have validation only if they exist
// this pred wraps the pred and is only ran if it exist
// We default the exists  pred to be a combination of exists and is not an empty string
// This is the most common use case. In forms, a user can start typing a non required string and then empty that text box.
/**
 * Takes 2 predicate functions and returns a predicate function
 * The first predicate function will only run if the second predicate function is true
 * @param {function} pred a predicate function that will evaluate true or false if a value is passed in
 * @param {function} existPred a predicate function that will evaluate true or false if a value is passed in
 * @returns {function} a predicate function that will run the 'pred' if 'existPred' evaluates to true
 */
export const isRequiredIfItExist =
  (pred, existPred = existsAndIsNotEmptyString) =>
  (v) => {
    // if it doesnt exist is true
    if (!existPred(v)) return true;
    return pred(v);
  };

/**
 * Takes a key 'k', 'pred', 'existPred', and returns a predicate function
 * Same as isRequiredIfItExist except with the value plucked at 'k'
 * The value plucked will be predicated by isRequiredIfItExist function
 * @param {string} k key
 * @param {function} pred a predicate function that will evaluate true or false if a value is passed in
 * @param {function} existPred a predicate function that will evaluate true or false if a value is passed in
 * @returns {function} a predicate function that will run the 'pred' if 'existPred' evaluates to true
 */
export const isRequiredIfItExistAt = (k, pred, existPred) => (obj) =>
  isRequiredIfItExist(pred, existPred)(objPluck(k)(obj));
