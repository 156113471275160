import { isType } from '../../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../../reducers';
import { when } from '../../../predication/when';
import {
  SHOULD_CLEAR_ADVANCED_SEARCH,
  SHOULD_UPDATE_ADVANCED_SEARCH,
  SHOULD_SEARCH_ADVANCED,
} from './actions';
import { DEFAULT_ADVANCED_SEARCH_CONFIG } from './constants';

const toAdvancedSearchConfig = (state = {}, { value: valueObj }) => {
  const { key, value } = valueObj;

  return {
    ...state,
    advancedSearchConfig: {
      ...state.advancedSearchConfig,
      [key]: value,
    },
  };
};

const toResetAdvancedSearchConfig = (state = {}) => ({
  ...state,
  advancedSearchConfig: {
    ...state.advancedSearchConfig,
    ...DEFAULT_ADVANCED_SEARCH_CONFIG,
  },
});

export default [
  when(isType(SHOULD_CLEAR_ADVANCED_SEARCH))(
    toActionValueReducer('advancedResults', () => undefined),
    toResetAdvancedSearchConfig
  ),
  when(isType(SHOULD_UPDATE_ADVANCED_SEARCH))(toAdvancedSearchConfig),
  when(isType(SHOULD_SEARCH_ADVANCED))(
    toActionValueReducer('advancedSearchQueryParams')
  ),
];
