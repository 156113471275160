import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import {
  SHOULD_UPDATE_STAFF_ID,
  SET_PROVIDER_INFORMATION,
  SHOULD_SEARCH,
} from './actions';
import { isNil } from '../../fp/pred';
import { pluck } from '../../fp/object';

export default [
  when(isType(SET_PROVIDER_INFORMATION))(
    toActionValueReducer('newProviderInformation')
  ),
  when(isType(SHOULD_UPDATE_STAFF_ID))(
    toActionValueReducer('selectedStaff', (value) => {
      if (isNil(value)) {
        return undefined;
      }

      return {
        selectedStaffId: pluck('StaffID')(value),
        selectedStaffEnterpriseId: pluck('EnterpriseID', 'EnterpriseID')(value),
      };
    })
  ),
  when(isType(SHOULD_SEARCH))(toActionValueReducer('searchText')),
];
