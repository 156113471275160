import { cloneElement } from 'react';
import { Flexbox } from '../components/Flexbox';
import { Icon } from '../components/Icon';
import { SERVICE_GROUP_CAT_CONTENT_TYPE_ID } from '../components/WorklistUITemplateWrapper/constants';
import { ADVANCED_SEARCH } from '../icons';

export const toServiceCodeName = (code) => {
  if (!code) return '';

  const { Description, ShortDesc, Code } = code;
  if (!Description || !ShortDesc) return `(${Code})`;

  return `(${Code}) ${ShortDesc || Description}`;
};

export const ServiceCodeRender = (element, listItem) => {
  const { props: elementProps } = element;
  const { dataItem = {} } = listItem;
  const { CatContentTypeID } = dataItem;

  return cloneElement(
    element,
    elementProps,
    <Flexbox direction="row" alignItems="center">
      {CatContentTypeID === SERVICE_GROUP_CAT_CONTENT_TYPE_ID && (
        <Icon icon={ADVANCED_SEARCH} />
      )}
      {toServiceCodeName(dataItem)}
    </Flexbox>
  );
};
