import { combinePredicatedReducers } from '../../../connection/toConnectionDef';
import DefaultView from './views/DefaultView';
import reducers from './reducers';
import { useReducer, useState } from 'react';
import { useXeQuery } from '../../../data/useXeQuery';
import { searchProviders } from 'services/staffs/xe-staffs-svc';
import { AddPopup } from './components/AddPopup';
import { EMPTY_OBJECT } from '../../../constants';

const reducer = combinePredicatedReducers(...reducers);

export const XeStaffSearchPicker = (props) => {
  const { onClose, onChange, initialData, initialFilters } = props;
  const { isUserOnly = false } = initialFilters;

  const [state, dispatch] = useReducer(reducer, {
    advancedSearchConfig: initialFilters,
  });
  const { advancedSearchQueryParams } = state;

  const [showAdd, setShowAdd] = useState(false);
  const [viewAdd, setViewAdd] = useState(false);

  const { data: advancedResults = initialData, isFetching } = useXeQuery(
    searchProviders(
      {
        ...(advancedSearchQueryParams ?? EMPTY_OBJECT),
        active: true,
        isUser: true,
        isUserOnly,
      },
      (x) => x
    ),
    {
      enabled: !!advancedSearchQueryParams,
      onSuccess: () => setShowAdd(true),
    }
  );

  return (
    <>
      <DefaultView
        {...state}
        dispatch={dispatch}
        showAdd={showAdd}
        onClose={onClose}
        onChange={onChange}
        onOpenAdd={() => {
          setViewAdd(true);
        }}
        advancedResults={advancedResults}
        isFetching={isFetching}
      />
      {viewAdd ? (
        <AddPopup
          onClose={() => {
            setViewAdd(false);
          }}
          onChange={onChange}
        />
      ) : null}
    </>
  );
};
