import { isEmpty } from '../../fp/pred';
import { pluck } from '../../fp/object';
import PropTypes from 'prop-types';
import { SHOULD_UPDATE_SEARCH_FILTER } from './actions';
import { MaskedComboBox } from '../components/MaskedComboBox';

const toDescription = (resource) => {
  if (!resource || isEmpty(resource)) return '';

  return `(${pluck('Code')(resource)}) ${pluck('Description')(resource)}`;
};

const DefaultView = (props) => {
  const {
    dataElementName,
    dispatch,
    results,
    clearOnSelect = false,
    disabled = false,
    formatter,
    className = '',
    value,
    comboBoxRef,
    onChange,
    ...rest
  } = props;

  return (
    <MaskedComboBox
      dataElementName={dataElementName}
      {...rest}
      comboBoxRef={comboBoxRef}
      data-xe-widget-name="XeDiagnosisSearchWidget"
      clearOnSelect={clearOnSelect}
      disabled={disabled}
      value={value}
      labelFn={formatter || toDescription}
      data={results}
      onChange={onChange}
      onEnter={(value) => {
        dispatch({ type: SHOULD_UPDATE_SEARCH_FILTER, value });
      }}
      className={`${className} flex-1`}
    />
  );
};

DefaultView.propTypes = {
  className: PropTypes.string,
  clearButton: PropTypes.bool,
  disabled: PropTypes.bool,
  formatter: PropTypes.func,
  clearOnSelect: PropTypes.bool,
  comboClassName: PropTypes.string,
};

export default DefaultView;
