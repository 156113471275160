import { useState } from 'react';
import { createOrFind } from 'services/staffs/xe-staffs-svc';
import { Popup, toDefaultPopupFooter } from '../../../../components/Popup';
import { useEnterprise } from '../../../../contexts/XeEnterpriseContext';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { useXeMutation } from '../../../../data/useXeMutation';
import { pluck } from '../../../../fp/object';
import AddProvider from '../../../components/AddProvider';
import { identity } from '../../../../fp/fp';

export const AddPopup = (props) => {
  const { onChange, onClose } = props;

  const labels = useXeLabels();

  const enterpriseContext = useEnterprise();
  const EnterpriseID =
    pluck('userData', 'EnterpriseID')(enterpriseContext) || '';

  const [newProviderInformation, setNewProviderInformation] = useState(false);
  const [canSaveNewProvider, setCanSaveNewProvider] = useState(false);

  const { mutate: createStaffMutation } = useXeMutation(
    (data) => createOrFind(data, {}, identity),
    {
      enabled: false,
      onSuccess: (data) => {
        const { results } = data;
        if (results) {
          onChange(results);
        }
      },
    }
  );

  return (
    <Popup
      FooterComponent={toDefaultPopupFooter({
        confirmLabelKey: 'Add',
        onConfirm: () => {
          if (!!newProviderInformation.AccountID) {
            onChange({
              ...newProviderInformation,
              XeStaffAddress: [newProviderInformation.XeStaffAddress],
            });
          } else {
            createStaffMutation({
              ...newProviderInformation,
              XeStaffSpecialty: (
                newProviderInformation.XeStaffSpecialty || []
              ).map(({ Name, SpecialtyID }) => ({
                Name,
                SpecialtyID,
                SpecialtyRank: 'n',
              })),
              EnterpriseID,
              XeStaffAddress: [
                {
                  ...newProviderInformation.XeStaffAddress,
                  ContactInfoTypeID: 'MAIN',
                },
              ],
            });
          }
        },
        onClose: onClose,
        disableConfirm: !canSaveNewProvider,
      })}
      title={labels.AddStaff}
      size="large"
      className="add-staff-popup-view"
    >
      <AddProvider
        onProviderInformationChange={setNewProviderInformation}
        onCanSave={setCanSaveNewProvider}
      />
    </Popup>
  );
};
