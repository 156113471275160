import { EMPTY_ARRAY } from '../constants';
import { pluck } from '../fp/object';

const PROGRAM_BIT_KEY = 'ProgramBits';

const defaultToBits = pluck(PROGRAM_BIT_KEY);

export const toFilteredByProgramBits = (
  values = EMPTY_ARRAY,
  filterBits,
  toBits = defaultToBits
) => {
  return values.filter((x) => filterBits & toBits(x));
};
