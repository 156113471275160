import {
  SCHEMA_VALIDATION_PROPS,
  toValidityState,
  VALIDITY,
  VALID
} from '../schema/JSONSchemaReducer';

const defaultRulesValidationState = {};
const defaultFailedRuleValidation = {
  '': {
    message: 'Failed Rule Validation',
  },
};

/**
 * @callback BusinessRulePredicate
 * @param value
 * @returns {boolean}
 */

/**
 * @typedef ResultsValidObject
 * @property {boolean} [rulesValid]
 */

/**
 * @typedef {ResultsValidObject & Object.<string, *>} ValidationObject
 */

/**
 * @callback ValidatorFunction
 * @param {ValidationObject} validation Current validation result
 * @param {*} value Value to validate
 * @returns {ValidationObject} Updated validation object
 */

/**
 * Generates a validator function for Schema reducers.
 * @param rules {Array<[BusinessRulePredicate, ValidatorFunction]>} Array of business rules
 * @returns {ValidatorFunction} Validator function
 */
export const toDefaultValidator = (rules) => {
  return (state) => {
    if (!state) return defaultRulesValidationState;
    const { instance } = state;

    const { [SCHEMA_VALIDATION_PROPS]: schemaValidationProps } = state;

    const failedRule = rules.find(([pred]) => !pred(instance));
    if (!failedRule) {
      return state;
    }

    const [, reducer] = failedRule;
    const nextRulesValidationProps = reducer(state);

    const nextValidityState = toValidityState(
      schemaValidationProps,
      nextRulesValidationProps
    );

    return {
      ...state,
      [VALIDITY]: nextValidityState,
      [VALID]: Object.keys(nextValidityState).length == 0,
    };
  };
};

/**
 * Default Validator Function for business rule reducers
 * @type {ValidatorFunction}
 */
export const defaultBusinessRuleReducer = () => defaultFailedRuleValidation;
