import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from '../../../../components/Table';
import { Input } from '../../../../components/Input';
import { Label } from '../../../../components/Label';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { useXeRights } from '../../../../contexts/XeUserRightsContext';
import { EMPTY_OBJECT } from '../../../../constants';

export const OrganizationFields = (props) => {
  const {
    StaffID = EMPTY_OBJECT,
    onStaffUpdate,
    shouldSearchExistingProviders,
  } = props;

  const { EDIT_STAFF_EXTERNALID } = useXeRights();

  const { GivenName = '', NationalProviderID = '', ExternalID } = StaffID;

  const onGivenNameChange = useCallback(
    (value) => onStaffUpdate('GivenName', value),
    [onStaffUpdate]
  );

  const onExternalIDChange = useCallback(
    (value) => onStaffUpdate('ExternalID', value),
    [onStaffUpdate]
  );

  const onNationalProviderIDChange = useCallback(
    (value) => onStaffUpdate('NationalProviderID', value),
    [onStaffUpdate]
  );

  const labels = useXeLabels();

  return (
    <>
      <Table.Row>
        <Table.Cell className="align-right add-provider__input-label">
          <Label
            dataElementName="addProvider__organizationField__name__label"
            required
          >
            {labels.Name}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Input
            dataElementName="addProvider__organizationField__name"
            type="text"
            className="app-input"
            value={GivenName}
            onChange={onGivenNameChange}
            onBlur={() => shouldSearchExistingProviders(StaffID)}
          />
        </Table.Cell>
      </Table.Row>
      {EDIT_STAFF_EXTERNALID && (
        <Table.Row>
          <Table.Cell className="align-right add-provider__input-label">
            <Label dataElementName="addProvider__organizationField__id__label">
              {labels.ID}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Input
              dataElementName="addProvider__organizationField__id"
              type="text"
              className="app-input"
              value={ExternalID}
              onChange={onExternalIDChange}
            />
          </Table.Cell>
        </Table.Row>
      )}
      <Table.Row>
        <Table.Cell className="align-right add-provider__input-label">
          <Label dataElementName="addProvider__organizationField__npi__label">
            {labels.NPI}
          </Label>
        </Table.Cell>
        <Table.Cell>
          <Input
            dataElementName="addProvider__organizationField__npi"
            type="text"
            className="app-input"
            value={NationalProviderID}
            onChange={onNationalProviderIDChange}
          />
        </Table.Cell>
      </Table.Row>
    </>
  );
};

OrganizationFields.propTypes = {
  StaffID: PropTypes.object.isRequired,
  onStaffUpdate: PropTypes.func.isRequired,
  shouldSearchExistingProviders: PropTypes.func.isRequired,
};
