import { identity } from '../fp/fp';
import { isString, isEmpty, isNil } from '../fp/pred';
import { pluck } from '../fp/object';
import { localeFormat } from '../format/luxonToDisplayString';
import {
  XE_PATIENT_PROVIDER_ACTION_KEY,
  XE_PROVIDER_ASSIGNMENT_ACTION_KEY,
} from '../components/Dashboard/constants';
import { toDisplayDateFromISOString } from '../g11n/displayDates';
import { isoStringComparator } from './dates';
import { PatientNavigationCell } from '../components/Grid/FrameworkComponents/PatientNavigationCell';
import { ActionCell as DashboardActionCell } from '../components/Grid/FrameworkComponents/ActionCell';
import {
  basicComparator,
  defaultFormattedCellValue,
} from '../components/Grid/utils';
import { isoStrAsLuxon } from '../g11n/ISODates';
import { DateTime, Interval } from 'luxon';
import { Flexbox, Icon } from '../components';
import { ALERT_OUTLINE } from '../icons';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants';

/**
 * @typedef {import('services/generated/types').DashboardStructuredResponse} DashboardRow
 * @typedef {import('@ag-grid-community/core').ColDef} AgGridColumnDef
 * @typedef {import('services/generate/types').ColumnMetaData} ColumnMetaData
 */

const defaultComparator = (a = '', b = '') => {
  if (isString(a)) {
    return a.localeCompare(b);
  }

  const realizedA = isNaN(a) ? 0 : a;
  const realizedB = isNaN(b) ? 0 : b;

  if (realizedA < realizedB) {
    return -1;
  }
  if (realizedA > realizedB) {
    return 1;
  }
  return 0;
};

const DisplayNameComparator = (a, b) => defaultComparator(a, b);
const DisplayResultComparator = (a, b) => defaultComparator(a, b);
const DisplayDateComparator = (a, b) => isoStringComparator(a, b);
const AgeComparator = (a = '', b = '') => {
  const yearIntA = parseInt(a.split(' ')[0]);
  const yearIntB = parseInt(b.split(' ')[0]);

  if (isNaN(yearIntA)) return -1;
  if (isNaN(yearIntB)) return 1;

  if (yearIntA < yearIntB) {
    return -1;
  }
  if (yearIntA > yearIntB) {
    return 1;
  }
  return 0;
};

const COMPARATORS = {
  DisplayName: DisplayNameComparator,
  DisplayResult: DisplayResultComparator,
  DisplayDate: DisplayDateComparator,
  DisplayDateLocal: DisplayDateComparator,
  Age: AgeComparator,
  default: defaultComparator,
};

const defaultKeyCreator = ({ value }) => value || defaultFormattedCellValue;
const dateKeyCreator = ({ value }) =>
  toDisplayDateFromISOString(value, localeFormat.MEDIUM) ||
  defaultFormattedCellValue;
const ageKeyCreator = ({ value }) => {
  if (value == defaultFormattedCellValue || value.slice(-1) == 'y')
    return value;
  const birthday = isoStrAsLuxon(value);
  const currentDate = DateTime.now();
  if (birthday > currentDate) return defaultFormattedCellValue;
  const age = Interval.fromDateTimes(birthday, currentDate).length('years');
  return `${Math.floor(age)} y`;
};

// Keyed on the DisplayType
const KEY_CREATORS = {
  DateTimeFormat: dateKeyCreator,
  DateLocalFormat: dateKeyCreator,
  DateFormat: dateKeyCreator,
  Age: ageKeyCreator,
  default: defaultKeyCreator,
};

const DISPLAY_TYPE_NUMBER = 'numeric';
const DISPLAY_TYPE_DECIMAL = 'decimal';

/**
 * @description Function map to project data for display purposes
 */
const FORMATTERS = {
  DateLocalFormat: (dateTimeStr) => {
    return toDisplayDateFromISOString(dateTimeStr, localeFormat.MEDIUM);
  },
  DateTimeFormat: (dateTimeStr) => {
    return toDisplayDateFromISOString(dateTimeStr, localeFormat.LONG);
  },
  DateFormat: (dateTimeStr) => {
    return toDisplayDateFromISOString(dateTimeStr, localeFormat.MEDIUM);
  },
  default: (value) => `${value}`,
};

/**
 * @description Function map to project data to cell values for sorting, grouping, etc
 */
const VALUE_CONVERTERS = {
  [DISPLAY_TYPE_DECIMAL]: (value) => parseFloat(value),
  [DISPLAY_TYPE_NUMBER]: (value) => parseFloat(value),
  Age: (dateTimeStr) => {
    const birthday = isoStrAsLuxon(dateTimeStr);
    const currentDate = DateTime.now();
    if (birthday > currentDate) return defaultFormattedCellValue;
    const age = Interval.fromDateTimes(birthday, currentDate).length('years');
    return `${Math.floor(age)} y`;
  },
};

// Keyed on the DisplayStyle
const STYLE_KEYS = {
  OverdueNow: ({ value, valueFormatted, data }) => ({
    value,
    valueFormatted,
    data,
  }),
  OverdueToday: ({ value, valueFormatted, data }) => ({
    value,
    valueFormatted,
    data,
  }),
  Age: ({ value }) => value,
  default: ({ valueFormatted }) => valueFormatted,
};

const OverdueDate = (data) => {
  const { valueFormatted, offset } = data;
  const realizedOffset = !!offset ? ` (${offset})` : null;
  return (
    <Flexbox>
      <div color="red">
        {valueFormatted}
        {realizedOffset}
      </div>
      <Icon icon={ALERT_OUTLINE} color="red" className="margin-left-small" />
    </Flexbox>
  );
};

const CELL_RENDERERS = {
  OverdueNow: ({ value, valueFormatted, data }) => {
    if (!!value && value !== defaultFormattedCellValue) {
      const actual = pluck('Decision Actual', 'DisplayDate')(data);
      const luxonDate = isoStrAsLuxon(value);
      const currentDate = DateTime.now();
      const actualDate = actual && isoStrAsLuxon(actual);
      if (!actual && luxonDate <= currentDate) {
        const daysOffset = Math.trunc(
          Interval.fromDateTimes(luxonDate, currentDate).length('days')
        );
        const hoursOffset = Math.trunc(
          Interval.fromDateTimes(luxonDate, currentDate).length('hours')
        );

        const offsetSign = luxonDate !== currentDate ? '-' : null;
        const offset = !!daysOffset
          ? `${offsetSign}${daysOffset}d`
          : `${offsetSign}${hoursOffset}h`;

        return <OverdueDate valueFormatted={valueFormatted} offset={offset} />;
      }
      if (luxonDate <= actualDate) {
        const daysOffset = Math.trunc(
          Interval.fromDateTimes(luxonDate, actualDate).length('days')
        );
        const hoursOffset = Math.trunc(
          Interval.fromDateTimes(luxonDate, actualDate).length('hours')
        );

        const offsetSign = luxonDate !== actualDate ? '-' : null;
        const offset = !!daysOffset
          ? `${offsetSign}${daysOffset}d`
          : `${offsetSign}${hoursOffset}h`;

        return <OverdueDate valueFormatted={valueFormatted} offset={offset} />;
      }
    }
    return valueFormatted;
  },
  OverdueToday: ({ value, valueFormatted, data }) => {
    if (!!value && value !== defaultFormattedCellValue) {
      const actual = pluck('Decision Actual', 'DisplayDate')(data);
      const luxonDate = isoStrAsLuxon(value).startOf('day');
      const currentDate = DateTime.now().startOf('day');
      const actualDate = actual && isoStrAsLuxon(actual);
      if (!actual && luxonDate <= currentDate) {
        const daysOffset = Math.trunc(
          Interval.fromDateTimes(luxonDate, currentDate).length('days')
        );
        const hoursOffset = Math.trunc(
          Interval.fromDateTimes(luxonDate, currentDate).length('hours')
        );

        const offsetSign = luxonDate !== currentDate ? '-' : null;
        const offset = !!daysOffset
          ? `${offsetSign}${daysOffset}d`
          : `${offsetSign}${hoursOffset}h`;

        return <OverdueDate valueFormatted={valueFormatted} offset={offset} />;
      }
      if (luxonDate <= actualDate) {
        const daysOffset = Math.trunc(
          Interval.fromDateTimes(luxonDate, actualDate).length('days')
        );
        const hoursOffset = Math.trunc(
          Interval.fromDateTimes(luxonDate, actualDate).length('hours')
        );

        const offsetSign = luxonDate !== actualDate ? '-' : null;
        const offset = !!daysOffset
          ? `${offsetSign}${daysOffset}d`
          : `${offsetSign}${hoursOffset}h`;

        return <OverdueDate valueFormatted={valueFormatted} offset={offset} />;
      }
    }
    return valueFormatted;
  },
  default: identity,
};

const isDisplay = ({ Display }) => Display;

const isDisplayAction = ({ DisplayAction }) => DisplayAction;

const CELL_STYLES = {
  display: 'flex',
  'flex-direction': 'column',
  'justify-content': 'center',
};

const toDOMStyleString = (styles) =>
  Object.entries(styles)
    .map(([key, value]) => `${key}:${value}`)
    .join(';');

const verticalCellStyles = toDOMStyleString(CELL_STYLES);

// (TODO) Look into potentially deprecating this since we've upgraded to AG-Grid 18.2.0 (AZ)
/**
 * Creates a renderer that will display lines of data stacked vertically.
 *
 * toLines - A function that yields an array of "line" datas, with the shape: { value: string, formatter: string => string, styles?: object }
 * row - Data from ag-grid
 */
export const toVerticalLinesRenderer = (toLines) => (row) => {
  const data = toLines(row);

  const lines = data
    .filter(({ value }) => !!value)
    .map(({ value, formatter = identity, styles }) => {
      const spanStyle = !!styles ? ` style="${toDOMStyleString(styles)}"` : '';
      return `<span${spanStyle}>${formatter(value)}</span>`;
    })
    .join('');

  // We can't use `cellClass` in the columnDef because the wrapping element
  // is a `span`, which we can't style as a flex container.

  // (JDM) Should be using `class` since this is an html string.
  // For reasons unknown, when `className` is used here the element doesn't get
  // the actual className values applied, so no styles are used.

  return `<div style="${verticalCellStyles}">${lines}</div>`;
};

/**
 * @deprecated
 * @param {*} data
 * @param {*} options
 * @returns {*}
 */
export const toCellValue = (data, options = {}) => {
  const { formatted = false } = options;

  const DisplaySort = pluck('DisplaySort')(data);
  const DisplayType = pluck('DisplayType')(data);

  const resultFormatter = FORMATTERS[DisplayType] || FORMATTERS.default;

  const value = data[DisplaySort];

  return formatted && value ? resultFormatter(value) : value;
};

const toMetaDataBaseColumnDefinitions = (columns = []) => {
  const complexCellDisplayKeys = [
    'DisplayName',
    'DisplayDate',
    'DisplayResult',
  ];

  // Assume all normal display columns don't have a DisplayAction key
  return columns
    .filter(isDisplay)
    .filter((item) => !isDisplayAction(item))
    .map((column) => {
      const {
        DisplayColumn,
        DisplaySort,
        DisplayType,
        DisplayStyle,
        SortGroupColumn,
      } = column;

      // const comparator = COMPARATORS[DisplaySort] || COMPARATORS.default;
      const comparator =
        COMPARATORS[DisplayStyle] ||
        COMPARATORS[DisplaySort] ||
        COMPARATORS.default;
      const keyCreator =
        KEY_CREATORS[DisplayStyle] ||
        KEY_CREATORS[DisplayType] ||
        KEY_CREATORS.default;

      // This is weird (JDM)
      const toIsPrimitiveDisplayType = (dt) =>
        dt === 'decimal' || dt === 'number' || dt === 'string';

      const valueConverterForDisplayType =
        VALUE_CONVERTERS[DisplayType] || VALUE_CONVERTERS[DisplayStyle];

      return {
        headerName: DisplayColumn,
        // SNET-747: put field also as SortGroupColumn to account for ag-grid sort behavior
        // Revisit if grid exporting is borked (AZ)
        field: SortGroupColumn,
        colId: SortGroupColumn,
        valueGetter: ({ data } = {}) => {
          if (!data || !data[DisplayColumn]) {
            return '';
          }

          const value = data?.[DisplayColumn]?.[DisplaySort]; //no clue what this was supposed to do...

          return valueConverterForDisplayType
            ? valueConverterForDisplayType(value)
            : value;
        },
        valueFormatter: ({ data: currentRow, node: { group } } = {}) => {
          const data = currentRow[DisplayColumn];
          const valueToFormat = currentRow?.[DisplayColumn]?.[DisplaySort]; //no clue what this was supposed to do...

          const resultFormatterForDisplayType =
            group && (KEY_CREATORS[DisplayStyle] || KEY_CREATORS[DisplayType])
              ? (value) => keyCreator({ value })
              : FORMATTERS[DisplayStyle] ||
                FORMATTERS[DisplayType] ||
                FORMATTERS.default;

          const hasSingleValueToDisplay =
            !data ||
            complexCellDisplayKeys.filter((k) => k in data).length === 1;

          if (hasSingleValueToDisplay) {
            return valueToFormat
              ? resultFormatterForDisplayType(valueToFormat)
              : undefined;
          }

          const isPrimitiveDisplayType = toIsPrimitiveDisplayType(DisplayType);

          const toShouldFormatDisplayDate = (k) =>
            !isPrimitiveDisplayType && k === 'DisplayDate';
          const toShouldFormatDisplaySort = (k) =>
            isPrimitiveDisplayType && k === 'DisplaySort';
          const toShouldFormatKey = (k) =>
            toShouldFormatDisplayDate(k) || toShouldFormatDisplaySort(k);

          const arrayOfDisplayValues = complexCellDisplayKeys.reduce(
            (cell, displayKey) => {
              if (!(displayKey in data)) {
                return cell;
              }
              if (toShouldFormatKey(displayKey)) {
                const dataAtDisplayKey = data[displayKey];
                if (!dataAtDisplayKey) return cell;
                return [
                  ...cell,
                  resultFormatterForDisplayType(data[displayKey]),
                ];
              }
              return [...cell, data[displayKey]];
            },
            []
          );

          return arrayOfDisplayValues.join('\n');
        },
        keyCreator,
        comparator,
        autoHeight: true,
        cellRenderer: (data) => {
          const resultStylerForDisplayStyle =
            CELL_RENDERERS[DisplayStyle] || CELL_RENDERERS.default;

          const valueKey = STYLE_KEYS[DisplayStyle] || STYLE_KEYS.default;
          return resultStylerForDisplayStyle(valueKey(data));
        },
        cellStyle: {
          whiteSpace: 'pre-wrap',
        },
      };
    });
};

const toMetaRowActionColumnDefinitions = (columns = []) => {
  const actionList = columns.filter((item = EMPTY_OBJECT) => {
    // Assuming here that
    return item?.DisplayAction === 'Buttons';
  });
  return actionList.map(
    ({ DisplayAction, DisplayHeader, DisplaySort, DisplayType } = {}) => {
      const toKey = pluck('value', DisplaySort);
      return {
        headerName: DisplayHeader || '',
        suppressSizeToFit: true,
        cellClass: 'right',
        sortable: !!DisplaySort,
        enableRowGroup: !!DisplaySort,
        cellRenderer: DashboardActionCell,
        valueFormatter: toKey,
        field: `ActionList.${DisplayType}`,
        pinned: 'right',
        keyCreator: (x) => toKey(x) || '',
        comparator: (a, b) => {
          //need to default undefined so they sort to the same location
          return basicComparator(
            a?.[DisplaySort] || '',
            b?.[DisplaySort] || ''
          );
        },
        cellRendererParams: {
          actionKey: DisplayType,
        },
      };
    }
  );
};

const toMetaPatientIconColumnDefs = (columns, hidePatientButton) => {
  const [ipidColumn] = columns.filter((column) => {
    return column?.DisplayType == 'IPIDLink';
  });

  if (!!ipidColumn && !hidePatientButton) {
    return [
      {
        maxWidth: 50,
        resizable: false,
        suppressSizeToFit: true,
        headerName: '',
        cellClass: 'right ag-cell-patient-button',
        sortable: false,
        enableRowGroup: false,
        cellRenderer: PatientNavigationCell,
        ...lockDecorators,
      },
    ];
  }

  return [];
};

const toBaseColumnDefinitions = (columns = []) => {
  // What makes these "complex"? (JDM)
  const complexCellDisplayKeys = [
    'DisplayName',
    'DisplayDate',
    'DisplayResult',
  ];

  return columns.filter(isDisplay).map((data) => {
    const { DisplayColumn, DisplaySort, DisplayType, DisplayStyle } = data;

    const comparator =
      COMPARATORS[DisplayStyle] ||
      COMPARATORS[DisplaySort] ||
      COMPARATORS.default;
    const keyCreator =
      KEY_CREATORS[DisplayStyle] ||
      KEY_CREATORS[DisplayType] ||
      KEY_CREATORS.default;

    // This is weird (JDM)
    const toIsPrimitiveDisplayType = (dt) =>
      dt === 'decimal' || dt === 'number' || dt === 'string';

    return {
      headerName: DisplayColumn,
      valueGetter: ({ data } = {}) => {
        if (!data || !data[DisplayColumn]) {
          return '';
        }

        const { DisplayType, DisplaySort, ...restData } = data[DisplayColumn];

        const valueConverterForDisplayType =
          VALUE_CONVERTERS[DisplayType] || VALUE_CONVERTERS[DisplayStyle];

        const value = restData[DisplaySort];

        return valueConverterForDisplayType
          ? valueConverterForDisplayType(value)
          : value;
      },
      valueFormatter: ({ data: currentColumn, node: { group } = {} } = {}) => {
        const data = currentColumn[DisplayColumn];
        const { DisplayType, DisplaySort, ...restData } = data;

        const resultFormatterForDisplayType =
          group && (KEY_CREATORS[DisplayStyle] || KEY_CREATORS[DisplayType])
            ? (value) => keyCreator({ value })
            : FORMATTERS[DisplayStyle] ||
              FORMATTERS[DisplayType] ||
              FORMATTERS.default;

        const valueToFormat = restData[DisplaySort];

        const hasSingleValueToDisplay =
          complexCellDisplayKeys.filter((k) => k in data).length === 1;

        if (hasSingleValueToDisplay) {
          return valueToFormat
            ? resultFormatterForDisplayType(valueToFormat)
            : undefined;
        }

        const isPrimitiveDisplayType = toIsPrimitiveDisplayType(DisplayType);

        const toShouldFormatDisplayDate = (k) =>
          !isPrimitiveDisplayType && k === 'DisplayDate';
        const toShouldFormatDisplaySort = (k) =>
          isPrimitiveDisplayType && k === 'DisplaySort';
        const toShouldFormatKey = (k) =>
          toShouldFormatDisplayDate(k) || toShouldFormatDisplaySort(k);

        const arrayOfDisplayValues = complexCellDisplayKeys.reduce(
          (cell, displayKey) => {
            if (!(displayKey in data)) {
              return cell;
            }
            if (toShouldFormatKey(displayKey)) {
              const dataAtDisplayKey = data[displayKey];
              if (!dataAtDisplayKey) return cell;
              return [...cell, resultFormatterForDisplayType(data[displayKey])];
            }
            return [...cell, data[displayKey]];
          },
          []
        );

        return arrayOfDisplayValues.join('\n');
      },
      keyCreator,
      comparator,
      autoHeight: true,
      cellRenderer: (data) => {
        const resultStylerForDisplayStyle =
          CELL_RENDERERS[DisplayStyle] || CELL_RENDERERS.default;

        const valueKey = STYLE_KEYS[DisplayStyle] || STYLE_KEYS.default;
        return resultStylerForDisplayStyle(valueKey(data));
      },
      cellStyle: { whiteSpace: 'pre-wrap' },
    };
  });
};

/**
 * @param {Number} ipid
 * @param {Boolean} hidePatientButton
 * @returns {(modeledColumnDefinitions:AgGridColumnDef[]) => AgGridColumnDef[]}
 */
const toPatientIconColumnDefs = (ipid, hidePatientButton) => {
  // If there is an IPID at the TOP most node it means we should render an icon that is clickable.
  if (ipid && !hidePatientButton) {
    return [
      {
        maxWidth: 50,
        resizable: false,
        suppressSizeToFit: true,
        headerName: '',
        cellClass: 'right ag-cell-patient-button',
        sortable: false,
        enableRowGroup: false,
        cellRenderer: PatientNavigationCell,
        ...lockDecorators,
      },
    ];
  }

  return [];
};

//Per conversations all actions for a given grid will be included in the ActionList Object for the first row
/**
 * @param {DashboardRow} definitionRow
 * @returns {(modeledColumnDefinitions:AgGridColumnDef[]) => AgGridColumnDef[]}
 */
const toRowActionsColumnDefs = (definitionRow) => {
  const actionList = definitionRow.ActionList || {};

  //ActionList values all have a Sequence property which is non sequential, however actions should be displayed in sequence order
  const actionKeysMap = Object.keys(actionList)
    .sort((keyA, keyB) => {
      //Sequence is a string of a number per the schema
      const numA = actionList[keyA].Sequence;
      const numB = actionList[keyB].Sequence;
      if (numA < numB) return -1;
      if (numA > numB) return 1;
      return 0;
    })
    .map((key) => ({ key, ...actionList[key] }));

  return actionKeysMap.map(({ key, DisplayColumn, DisplaySort } = {}) => {
    const toKey = pluck('value', DisplaySort);
    return {
      headerName: DisplayColumn || '',
      suppressSizeToFit: true,
      cellClass: 'right',
      sortable: !!DisplaySort,
      enableRowGroup: !!DisplaySort,
      cellRenderer: DashboardActionCell,
      valueFormatter: toKey,
      field: `ActionList.${key}`,
      pinned: 'right',
      keyCreator: (x) => toKey(x) || '',
      comparator: (a, b) => {
        //need to default undefined so they sort to the same location
        return basicComparator(a?.[DisplaySort] || '', b?.[DisplaySort] || '');
      },
      cellRendererParams: {
        actionKey: key,
      },
    };
  });
};

/**
 * @param {string} globalAction
 * @returns {(modeledColumnDefinitions:AgGridColumnDef[]) => AgGridColumnDef[]}
 */
const toGlobalActionsColumnDefs = (globalActions = '') => {
  const associatedColumnDefsForGlobalActions = globalActions
    .split('|')
    .reduce((columnDefs, globalAction) => {
      switch (globalAction) {
        case 'PATIENT_PROV': {
          return [
            ...columnDefs,
            {
              checkboxSelection: (params) => {
                const { data: { ActionList } = {} } = params;

                // If there are no actions in this row don't have the checkbox
                if (isNil(ActionList) || isEmpty(ActionList)) return false;

                // We currently only support this global action so hard coding it for now
                const { Valid } =
                  ActionList[XE_PATIENT_PROVIDER_ACTION_KEY] || {};

                return Valid;
              },
              ...lockDecorators,
            },
          ];
        }
        case 'VISIT_PROV': {
          return [
            ...columnDefs,
            {
              checkboxSelection: (params) => {
                const { data: { ActionList } = {} } = params;

                // If there are no actions in this row don't have the checkbox
                if (isNil(ActionList) || isEmpty(ActionList)) return false;

                return ActionList[XE_PROVIDER_ASSIGNMENT_ACTION_KEY];
              },
              ...lockDecorators,
            },
          ];
        }
        default: {
          return columnDefs;
        }
      }
    }, []);

  return associatedColumnDefsForGlobalActions;
};

const LOCK_COLUMN_IDENTIFIER = 'Lock';
const PAGED_LOCK_COLUMN_IDENTIFIER = '__lock';
const lockDecorators = {
  pinned: 'left',
  suppressMovable: true,
};

/**
 * Locks all columnDefs that come before the columnDef called Lock if it exists
 * @param {DashboardRow} definitionRow
 * @returns {(baseColumnDefinitions:AgGridColumnDef[]) => AgGridColumnDef[]}
 */
const toInnerColumnDefs = (definitionRow = {}, baseColumnDefinitions = []) => {
  const { Result: columnCells } = definitionRow;
  const lockIndex = columnCells.findIndex(
    ({ DisplayColumn }) => DisplayColumn === LOCK_COLUMN_IDENTIFIER
  );

  if (lockIndex === -1) {
    return baseColumnDefinitions;
  }
  return baseColumnDefinitions.map((columnDef, columnDefIndex) => {
    return columnDefIndex < lockIndex
      ? { ...columnDef, ...lockDecorators }
      : columnDef;
  });
};

/**
 * Paged Version (AZ)
 * Locks all columnDefs that come before the columnDef called Lock if it exists
 * @param {ColumnMetaData} rowMetaData
 * @returns {(baseColumnDefinitions:AgGridColumnDef[]) => AgGridColumnDef[]}
 */

// SNET-980: Port of existing function, just to account for paging logic.  (AZ)
const toMetaInnerColumnDefs = (
  rowMetaData = EMPTY_ARRAY,
  baseColumnDefs = EMPTY_ARRAY
) => {
  const lockIndex = rowMetaData
    .filter(({ Display, SortGroupColumn }) => {
      if (SortGroupColumn === PAGED_LOCK_COLUMN_IDENTIFIER) return true;
      return !!Display;
    })
    .findIndex(({ SortGroupColumn } = EMPTY_OBJECT) => {
      return SortGroupColumn === PAGED_LOCK_COLUMN_IDENTIFIER;
    });

  if (lockIndex === -1) {
    return baseColumnDefs;
  }

  return baseColumnDefs.map((columnDef, columnDefIndex) => {
    return columnDefIndex < lockIndex
      ? { ...columnDef, ...lockDecorators }
      : columnDef;
  });
};

export const toColumnDefsFromMetadata = (
  rowMetaData = EMPTY_ARRAY,
  { globalAction, hidePatientButton = false } = {}
) => {
  if (!rowMetaData || !rowMetaData.length) return EMPTY_ARRAY;
  const patientIconColumnDefs = toMetaPatientIconColumnDefs(
    rowMetaData,
    hidePatientButton
  );
  const baseColumnDefinitions = toMetaDataBaseColumnDefinitions(rowMetaData);
  const innerColumnDefs = toMetaInnerColumnDefs(
    rowMetaData,
    baseColumnDefinitions
  );
  const rowActionsColumnDefs = toMetaRowActionColumnDefinitions(rowMetaData);
  const globalActionsColumnDefs = toGlobalActionsColumnDefs(globalAction);

  return [
    ...patientIconColumnDefs,
    ...innerColumnDefs,
    ...globalActionsColumnDefs,
    ...rowActionsColumnDefs,
  ];
};

// Each object in the array of data contains a `Results` field that specifies the columns.
// The business rule is that we use the first object as the "blueprint" for the columns.
// This function pulls out the head of the array and generates columnDefs based on the
// `Results` of that row. The columns must match for *all* of the data objects or this
// will break. This is a business rule and it is assumed that the backend will adhere to it.
/**
 *
 * @param {DashboardRow[]} arrayOfRows
 * @param {{ globalAction:String, hidePatientButton:boolean }} configParams
 * @returns
 */
export const toColumnDefs = (
  arrayOfRows = EMPTY_ARRAY,
  { globalAction, hidePatientButton } = {}
) => {
  const [firstRow] = arrayOfRows;
  if (!firstRow) {
    return EMPTY_ARRAY;
  }

  const { IPID, Result: columnCells } = firstRow;
  const baseColumnDefinitions = toBaseColumnDefinitions(columnCells);

  const rowActionsColumnDefs = toRowActionsColumnDefs(firstRow);
  const globalActionsColumnDefs = toGlobalActionsColumnDefs(globalAction);
  const innerColumnDefs = toInnerColumnDefs(firstRow, baseColumnDefinitions);
  const patientIconColumnDefs = toPatientIconColumnDefs(
    IPID,
    hidePatientButton
  );

  return [
    ...patientIconColumnDefs,
    ...globalActionsColumnDefs,
    ...innerColumnDefs,
    ...rowActionsColumnDefs,
  ];
};

const toModeledRow = (row) => {
  const { ActionList = {}, Result } = row;
  //This is all kinds of weird.... having to change it now to make anything work, but it seems dumb to me
  //Perhaps it is because we no longer get the redundant display on all of the other colums? If so though, it
  //is extra dumb as we already filter the column defs so why also do this here
  const resultPass = Result.reduce(
    (acc, cell) => {
      const { DisplayColumn } = cell;

      acc[DisplayColumn] = cell;
      return acc;
      // return {
      //   ...acc,
      //   [DisplayColumn]: cell,
      // };
    },
    {
      row,
    }
  );

  const actionListPass = Object.entries(ActionList).reduce(
    (nextResult, [actionKey, actionValue]) => {
      const { ActionList = {} } = nextResult;
      return {
        ...nextResult,
        ActionList: {
          ...ActionList,
          [actionKey]: actionValue,
        },
      };
    },
    {
      ...resultPass,
      ActionList: {},
    }
  );

  return actionListPass;
};

export const toRowData = (arrayOfRows = []) => {
  return arrayOfRows.map(toModeledRow);
};
