// Regex sites say we need an escape character but eslint says it's unnecessary. Not trusting eslint. (MM)
// Regex pulled from here: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
/*eslint-disable no-useless-escape*/
export const emailRegxString =
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";

export const phoneNumberRegxString =
  '((\\(\\d{3}\\)?)|(\\d{3}))([\\s-./]?)(\\d{3})([\\s-./]?)(\\d{4})|^$';

//per SYNUI-7937 we do not accept leading 0's or 1's
export const faxNumberRegxString =
  '(\\(*[2-9]\\d{2}\\)*)([\\s-./]?)(\\d{3})([\\s-./]?)(\\d{4})|^$';

export const zipCodeRegxString = '^(\\d{5}|\\d{9})$';

export const spacesOnlyRegexString = '^\\s+$';
