import PropTypes from 'prop-types';
import { useState } from 'react';
import { IconButton } from '../../components/IconButton';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { ADVANCED_SEARCH } from '../../icons';
import { MaskedComboBox } from '../components/MaskedComboBox';
import {
  SHOULD_SEARCH,
  SHOULD_UPDATE_IPID_FROM_ADVANCED_SEARCH,
  SHOULD_UPDATE_IPID_FROM_AUTOCOMPLETE,
} from './actions';
import XePatientSearchPicker from './picker';
import { useQueryClient } from 'react-query';
import { identity } from '../../fp/fp';
import { toDisplayDateFromISOString } from '../../g11n/displayDates';
import { localeFormat } from '../../format/luxonToDisplayString';

const betweenNewLinesRegExp = /(?<=(^|\n))(.*?)(?=(\n))/g;
const betweenCommasRegExp = /(?<=(^|,))(.*?)(?=(,|$))/g;
const wholeWordsRegExp = /(\w+)/g;

/**
 * @description Returns a function that projects a data structure to a formatted string.
 * If no data structure is provided, the raw format will be returned–useful for formats like `ff_currencySymbol` (eg. `$`).
 * @param {string} serverFormat
 * @returns {FormatterProjectFunction}
 */
const toFormatterFunction =
  (serverFormat) =>
  (...args) => {
    if (!args.length) return serverFormat;

    const [data] = args;

    if (!data) return '';

    const dataPropertyTokens = serverFormat.match(wholeWordsRegExp) ?? [];

    const formatterResult = dataPropertyTokens.reduce((result, token) => {
      // Special rule:
      // If a FamilyName is "(F)", it is equivalent to having an undefined FamilyName
      if (token === 'FamilyName' && data?.FamilyName === '(F)') {
        return result.replace(token, '');
      }

      // Special rule:
      // If DoB is token, format DoB to a date
      // Possibly more date properties should be added
      if (token === 'DoB' && data?.DoB) {
        return result.replace(
          token,
          toDisplayDateFromISOString(data.DoB, localeFormat.MEDIUM)
        );
      }

      return result.replace(token, data[token] || '');
    }, serverFormat);

    /**
     * Array of arrays representing each separate line in a multiline format
     */
    const lines = formatterResult.match(betweenNewLinesRegExp) ?? [
      formatterResult,
    ];

    const linesWithDelimiters = lines.map((line) => {
      /**
       * Array representing each group of text between commas
       */
      const commaGroups = line.match(betweenCommasRegExp) ?? [line];
      return commaGroups
        .map((cg) => cg.trim())
        .filter(identity)
        .join(', ');
    });

    // Join the lines (if any) and condense any consecutive whitespace
    return linesWithDelimiters.join('\n').replace(/(\s)+/g, '$1');
  };

const DefaultView = (props) => {
  const {
    dataElementName,
    dispatch,
    results,
    className = '',
    clearOnSelect = false,
    disabled = false,
    value,
    comboBoxRef,
    ...rest
  } = props;

  const [viewAdvancedSearch, setViewAdvancedSearch] = useState(false);
  const labels = useXeLabels();
  const queryClient = useQueryClient();

  return (
    <>
      <MaskedComboBox
        dataElementName={dataElementName}
        {...rest}
        comboBoxRef={comboBoxRef}
        clearOnSelect={clearOnSelect}
        disabled={disabled}
        value={value}
        labelFn={toFormatterFunction('FamilyName, GivenName (PatientID, DoB)')}
        data={results}
        onChange={(value) => {
          return dispatch({
            type: SHOULD_UPDATE_IPID_FROM_AUTOCOMPLETE,
            value,
          });
        }}
        onEnter={(value) => {
          dispatch({ type: SHOULD_SEARCH, value });
          queryClient.invalidateQueries(['xe-patients-svc', 'autoComplete']);
        }}
        className={className}
        data-xe-widget-name="XePatientSearchWidget"
      >
        <IconButton
          icon={ADVANCED_SEARCH}
          dataElementName="advancedSearch"
          description={labels.AdvancedSearch}
          onClick={() => setViewAdvancedSearch(true)}
          className="base-search-widget__icon-button margin-horizontal-small"
          look="flat"
          disabled={disabled}
        />
      </MaskedComboBox>
      {viewAdvancedSearch && (
        <XePatientSearchPicker
          onClose={() => setViewAdvancedSearch(false)}
          onChange={(value) => {
            dispatch({ type: SHOULD_UPDATE_IPID_FROM_ADVANCED_SEARCH, value });
            setViewAdvancedSearch(false);
          }}
          disableRouting
        />
      )}
    </>
  );
};

DefaultView.propTypes = {
  className: PropTypes.string,
  clearButton: PropTypes.bool,
  disabled: PropTypes.bool,
  formatter: PropTypes.func,
  clearOnSelect: PropTypes.bool,
  comboClassName: PropTypes.string,
};

export default DefaultView;
