import { identity } from '../fp/fp';
import { isEmpty, isNil } from '../fp/pred';

const currencyLocaleMap = {
  'en-US': {
    currencyChar: '$',
    position: 'left',
  },
};

export const toBoolString = (x) => (x === true ? 'y' : 'n');
export const isTrue = (x) => x === true || x === 'y';
export const isFalse = (x) => x === false || x === 'n';
export const fromStrTrueFalseToBoolTrueFalse = (x) => {
  if (x == null) {
    return undefined;
  }
  if (x.toLowerCase() === 'true' || x === true || x.toLowerCase() === 'y') {
    return true;
  }
  if (x.toLowerCase() === 'false' || x === false || x.toLowerCase() === 'n') {
    return false;
  }
  return undefined;
};

export const withEllipsis = (string) => `${string}...`;

/**
 * Creates an empty array of size `length`
 * @param {Number} length Size of the array
 * @returns {Array} New array
 */
export const toEmptyArrayOfLength = (length) => Array.from({ length });

/**
 * Formats a name
 * @param {Object} [name={}] Object with optional `FamilyName` and `GivenName` properties
 * @param {string} [format='F, G'] Format of the name output
 * @returns {string} Name formatted to the provided formatter
 * @example
 * formatStaffName({ FamilyName: 'Sample', GivenName: 'John' }, 'F, G');
 */
export const formatStaffName = (objectWithName = {}, format = 'F, G') => {
  const isResourceGroup = ({ FamilyName } = { FamilyName: '' }) => {
    return FamilyName === '(F)';
  };

  const isFacility = ({ ResourceTypeID } = { ResourceTypeID: '' }) => {
    return ResourceTypeID === 'FACILITY';
  };

  if (isFacility(objectWithName) || isResourceGroup(objectWithName)) {
    return formatCommonName(objectWithName, 'G');
  }

  return formatCommonName(objectWithName, format);
};

/**
 * Formats a name
 * @param {Object} [name={}] Object with optional `FamilyName` and `GivenName` properties
 * @param {String} [format=''] Format of the name output
 * @returns {String} Name formatted to the provided formatter
 * @example
 * formatCommonName({ FamilyName: 'Sample', GivenName: 'John' }, 'F, G');
 */
export const formatCommonName = (objectWithName = {}, format = '') => {
  if (!objectWithName || isEmpty(objectWithName)) return '';

  const { FamilyName, GivenName, SecondName } = objectWithName;

  if (!format || !format.length) {
    return `${GivenName} ${FamilyName}`;
  }

  return format
    .split('')
    .map((char) => {
      switch (char) {
        case 'G':
          return GivenName;
        case 'F':
          return FamilyName;
        case 'S':
          return SecondName;
        default:
          return char;
      }
    })
    .join('');
};

/**
 * @typedef Address
 * @property {string=} AddressLine1
 * @property {string=} AddressLine2
 * @property {string=} AddressLine3
 * @property {string=} City
 * @property {string=} StateProvince
 * @property {string|Number=} ZipPostalCode
 */

/**
 * Returns a standard formatted address
 * @param {({ [x: string]: any } & Address)?=} objectWithAddress
 * @param {string?} separator
 */
export const formatAddress = (objectWithAddress, separator = '\n') => {
  if (isNil(objectWithAddress)) return '';

  const { AddressLine1, AddressLine2, AddressLine3 } = objectWithAddress;

  const addressLines = [AddressLine1, AddressLine2, AddressLine3]
    .filter(identity)
    .join(separator);

  const toCityProvinceZipLine = (o) => {
    const { City, StateProvince, ZipPostalCode } = o;
    const provinceAndPostalCode = [StateProvince, ZipPostalCode]
      .filter(identity)
      .join(' ');

    if (!City) {
      return provinceAndPostalCode;
    }
    return `${City}, ${provinceAndPostalCode}`;
  };

  return addressLines.concat(
    separator,
    toCityProvinceZipLine(objectWithAddress)
  );
};

export const withoutPropagation = (handler) => (e) => {
  e.stopPropagation();
  return handler(e);
};

export const isEnterKeyPressEvent = ({ key, keyCode }) => {
  const KEYCODE_ENTER = 13;
  const KEY_ENTER = 'Enter';
  return key === KEY_ENTER || keyCode === KEYCODE_ENTER;
};

export const toCurrencyString = (value, locale = 'en-US') => {
  // Probably more issues I'm not thinking of yet
  if (!value) return null;
  const { currencyChar, position } = currencyLocaleMap[locale] || '';

  return position === 'left'
    ? `${currencyChar}${value.toFixed(2)}`
    : `${value.toFixed(2)}${currencyChar}`;
};
