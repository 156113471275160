import { IsUserCell } from './components/IsUserCell';
import { formatStaffName } from '../../../utils';
import { pluck } from '../../../fp/object';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants';
import { phoneNumberFormatter } from '../../../format/phoneNumberFormatter';

export const DEFAULT_ADVANCED_SEARCH_CONFIG = {
  searchZip: undefined,
  searchText: undefined,
  searchSpecialty: undefined,
  searchStatus: true,
};

const formatSpecialtyName = pluck('SpecialtyID', 'Name');
const toSpecialtyNameString = ({ data = EMPTY_OBJECT } = EMPTY_OBJECT) => {
  const { XeStaffSpecialty: specialty = EMPTY_ARRAY } = data;
  return specialty.map(formatSpecialtyName).join(', ');
};

const nameRenderer = ({ data = EMPTY_OBJECT } = EMPTY_OBJECT) =>
  formatStaffName(data);

const formatAddressString = ({
  AddressLine1 = '',
  AddressLine2 = '',
  AddressLine3 = '',
  City = '',
  StateProvince = '',
  ZipPostalCode = '',
} = EMPTY_OBJECT) =>
  `${AddressLine1} ${AddressLine2} ${AddressLine3} ${City} ${StateProvince} ${ZipPostalCode}`;

const hasContent = (str) => !!str && !!str.trim().length;

const toAddressString = ({ data = EMPTY_OBJECT } = EMPTY_OBJECT) => {
  const { XeStaffAddress: address = EMPTY_ARRAY } = data;
  return address
    .map((location) => formatAddressString(location))
    .filter(hasContent)
    .join(', ');
};

const toPhoneNumberString = ({ data = EMPTY_OBJECT } = EMPTY_OBJECT) => {
  const { XeStaffAddress: address = EMPTY_ARRAY } = data;
  return address
    .map((location) => phoneNumberFormatter(location?.Phone))
    .filter(hasContent)
    .join(', ');
};

export const advancedColumnDefs = [
  {
    headerLabelKey: 'Name',
    field: 'GivenName',
    cellRenderer: nameRenderer,
  },
  {
    headerLabelKey: 'IsUser',
    cellRenderer: IsUserCell,
    width: 100,
    resizable: false,
  },
  {
    headerLabelKey: 'ID',
    field: 'ExternalID',
  },
  {
    headerLabelKey: 'NPI',
    field: 'NationalProviderID',
  },
  {
    headerLabelKey: 'Address',
    field: 'XeStaffAddress',
    valueGetter: toAddressString,
  },
  {
    headerLabelKey: 'Phone',
    field: 'XeStaffAddress',
    valueGetter: toPhoneNumberString,
  },
  {
    headerLabelKey: 'Type',
    field: 'ResourceTypeID.Name',
  },
  {
    headerLabelKey: 'Specialty',
    field: 'XeStaffSpecialty',
    valueGetter: toSpecialtyNameString,
  },
  {
    headerLabelKey: 'Source',
    field: 'EnterpriseID.EnterpriseName',
    suppressSizeToFit: true,
  },
];
