import { isType } from '../../connection/toConnectedComponent';
import { EMPTY_OBJECT } from '../../constants';
import { when } from '../../predication/when';
import { SHOULD_SEARCH_BY_TEXT } from './actions';
import { toActionValueReducer } from '../../reducers';

export default [
  when((state) => state === undefined)(() => EMPTY_OBJECT),
  when(isType(SHOULD_SEARCH_BY_TEXT))(toActionValueReducer('searchText')),
];
