import { isType } from '../../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../../reducers';
import { when } from '../../../predication/when';
import { REMOVE_FEATURE } from '../../../hooks/scopedReducer';
import {
  RESPONSE_SEARCH_EXISTING_PROVIDERS,
  DID_UPDATE_NEW_PROVIDER,
} from './actions';
import { defaultState } from './constants';

export const setDefaultResourceInformation = (state) => {
  return {
    ...state,
    StaffID: {
      ResourceTypeID: 'STAFF',
    },
    existingProviders: [],
  };
};

export default [
  when((state) => state === undefined)(() => defaultState),
  when(isType(RESPONSE_SEARCH_EXISTING_PROVIDERS))(
    toActionValueReducer('existingProviders')
  ),
  when(isType(DID_UPDATE_NEW_PROVIDER))(toActionValueReducer('StaffID')),
  when(isType(REMOVE_FEATURE))(setDefaultResourceInformation),
];
