export const openUrlInNewTab = (url, windowName = '_blank') => {
  return new Promise((resolve) => {
    if (!url) return;

    const new_window = window.open(url, windowName);

    //This set interval construct is due to how one needs to handle cross-domain popups
    //We cannot simply register a handler on the popup and have it work in all cases
    //Do not change
    const interval = window.setInterval(() => {
      if (new_window == null || new_window.closed) {
        window.clearInterval(interval);
        resolve(windowName);
      }
    }, 1000);
  });
};

export const openFileInNewTab = (blob, windowName = '_blank') => {
  return new Promise((resolve) => {
    if (!blob) return;

    const url = URL.createObjectURL(blob);
    const new_window = window.open(url, windowName);

    if (new_window) {
      new_window.onbeforeunload = () => {
        URL.revokeObjectURL(url);
        resolve(windowName);
      };
    }
  });
};

//This needs to be fleshed out more similar to a URL version of our path.js
const urlRegex = /https?:\/\//;
export const resolveServerPath = (pathRoot, relativeFragment) => {
  if (urlRegex.test(relativeFragment)) {
    //URL is not actually relative, it's absolute including protocol
    return relativeFragment;
  }

  if (pathRoot.endsWith('/')) {
    return `${pathRoot}${relativeFragment}`;
  }

  return `${pathRoot}/${relativeFragment}`;
};
