import { SchemalessReducer } from '../../../../schema/SchemalessReducer';
import { identity } from '../../../../fp/fp';
import { and, or } from '../../../../fp/pred';
import { localeFormat } from '../../../../format/luxonToDisplayString';
import { useContext, useState } from 'react';
import { CellRendererContext } from '../../../../components/Grid/contexts/CellRendererContext';
import { Flexbox } from '../../../../components/Flexbox';
import { Grid } from '../../../../components/Grid';
import { Panel } from '../../../../components/Panel';
import { Popup, toDefaultPopupFooter } from '../../../../components/Popup';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { toDisplayDateFromISOString } from '../../../../g11n/displayDates';
import {
  CLOSE_POPUP,
  SHOULD_SEARCH,
  SHOULD_VIEW_ADD_PATIENT,
} from '../actions';
import { ClonePatientCellRenderer } from '../components/ClonePatientCellRenderer';
import PatientSearchAdvanced from '../components/PatientSearchAdvanced';
import PatientSearchHeader from '../components/PatientSearchHeader';
import '../styles.css';
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  NOOP_FUNCTION,
} from '../../../../constants';
import { WILDCARD_STRING } from '../../constants';

const PatientCellRenderer = (params = EMPTY_OBJECT) => {
  const { value, data = { EnterpriseID: '' } } = params;

  const { onClone = NOOP_FUNCTION } = useContext(CellRendererContext);

  return (
    <ClonePatientCellRenderer
      ipid={value}
      enterpriseId={data.EnterpriseID}
      onClone={onClone}
    />
  );
};

/**
 * @typedef {import('ag-grid-enterprise').ColDef[]}
 */
const columnDefs = [
  {
    headerLabelKey: 'PatientID',
    field: 'PatientID',
  },
  {
    headerLabelKey: 'FamilyName',
    field: 'FamilyName',
  },
  {
    headerLabelKey: 'GivenName',
    field: 'GivenName',
  },
  {
    headerLabelKey: 'MiddleName',
    field: 'SecondName',
  },
  {
    headerLabelKey: 'DateOfBirthShort',
    field: 'DoB',
    valueFormatter: ({ value } = {}) => {
      return toDisplayDateFromISOString(value, localeFormat.MEDIUM);
    },
  },
  {
    headerLabelKey: 'Gender',
    field: 'SexID',
  },
  {
    headerLabelKey: 'Zip',
    field: 'XePatientAddress[0].ZipPostalCode',
  },
  {
    headerLabelKey: 'City',
    field: 'XePatientAddress[0].City',
  },
  {
    headerLabelKey: 'Phone',
    field: 'XePatientAddress[0].Phone',
  },
  {
    headerLabelKey: 'Source',
    field: 'EnterpriseID',
  },
  {
    headerName: '',
    field: 'IPID',
    cellRenderer: PatientCellRenderer,
  },
];

const searchTextIsNotJustSpaces = (text = ' ') => text || !!text.trim();
const searchTextIsMinLengthOrWildCard = (text = ' ') =>
  text.trim().length > 2 || text.trim() == WILDCARD_STRING;
const searchParamExists = (searchParams) =>
  Object.values(searchParams).some(identity);

const BASE_INSTANCE = {};

const DefaultView = (props) => {
  const {
    identifierName,
    dispatch,
    patients = EMPTY_ARRAY,
    canAddPatient = false,
    onSelectPatient,
  } = props;

  const [hasSearched, setHasSearched] = useState(false);
  const [showAdvancedSearch, toggleShowAdvancedSearch] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [searchText, setSearchText] = useState(undefined);
  //TODO: This is a workaround to cacheBust the schemaless Reducer
  //So when clear the search we update this to force the key change (SG)
  const [lastCleared, setLastCleared] = useState(Date.now());

  const onChangeSearchParams = ({ instance }) => {
    const { searchText: instanceSearchText } = instance;
    // searchText is now an independent state variable, but searchText has to be set
    // to undefined in the searchParams object to avoid breaking the onChange function
    setSearchText(instanceSearchText);
    setSearchParams({ ...instance, searchText: undefined });
  };

  const searchEnabled =
    searchParamExists(searchParams) ||
    and(searchTextIsMinLengthOrWildCard, searchTextIsNotJustSpaces)(searchText);

  const onSearch = () => {
    // if searchText is valid and searchParams is also valid, user searchParams as default.
    if (searchEnabled) {
      const paramsValues = Object.values(searchParams);
      if (paramsValues.length > 0 && paramsValues.some(identity)) {
        dispatch({ type: SHOULD_SEARCH, value: searchParams });
      } else {
        dispatch({ type: SHOULD_SEARCH, value: { searchText: searchText } });
      }
      setHasSearched(true);
    }
  };

  const resetSearchParams = () => {
    setLastCleared(Date.now());
    setSearchParams({});
    setSearchText(undefined);
  };

  const labels = useXeLabels();

  return (
    <Popup
      dataElementName="patientSearchWidget__popup"
      FooterComponent={toDefaultPopupFooter({
        onClose: () => dispatch({ type: CLOSE_POPUP }),
      })}
      title={labels.PatientSearch}
      size="large"
    >
      <Flexbox className="flex-grow-1" direction="column">
        <SchemalessReducer
          initialValue={BASE_INSTANCE}
          key={lastCleared}
          onChange={onChangeSearchParams}
        >
          <Panel
            className="patient-search-fields"
            data-xe-feature-name={identifierName}
          >
            <PatientSearchHeader
              searchEnabled={searchEnabled}
              showAllFilters={showAdvancedSearch}
              allowAddPatient={hasSearched && canAddPatient}
              onToggleExpandFilters={toggleShowAdvancedSearch}
              onClear={resetSearchParams}
              onSearch={onSearch}
              onViewAddPatient={(value) =>
                dispatch({ type: SHOULD_VIEW_ADD_PATIENT, value })
              }
            />
            {showAdvancedSearch && <PatientSearchAdvanced />}
          </Panel>
        </SchemalessReducer>
        <Grid
          className="flex-1"
          onRowDoubleClicked={({ data } = {}) => onSelectPatient(data)}
          cellRendererContext={{
            onClone: (ipid) => {
              return onSelectPatient({ IPID: ipid });
            },
          }}
          columnDefs={columnDefs}
          data={patients}
          getRowId={({ data: { IPID } } = {}) => IPID}
        />
      </Flexbox>
    </Popup>
  );
};

export default DefaultView;
