import { isObjectLike } from '../fp/pred';
import { set, put, pluck, get } from '../fp/object';

import { toEmptyReferencedSchemas, findRelatedSchema } from './schemaTraversal';

import { transformDeclaration } from './schemaTransformer';
import { castSchema } from './schemaCaster';
import { pipe, identity } from '../fp/fp';

export const withSchemaMetadata = (schema, validator) => {
  const {
    baseSchema = {},
    toReferencedSchemas = toEmptyReferencedSchemas,
    registerSchema,
  } = schema;

  registerSchema(validator);

  const schemaFinder = findRelatedSchema([...toReferencedSchemas(), schema]);

  const transformer = (instance) => {
    return transformDeclaration(
      validator,
      schemaFinder,
      baseSchema,
      baseSchema,
      instance,
      undefined,
      instance
    );
  };

  return (instance) => {
    if (Array.isArray(instance)) {
      return instance.map(transformer);
    }

    if (isObjectLike(instance)) {
      return transformer(instance);
    }

    return instance;
  };
};

export const withoutSchemaMetadata = (instance) => {
  const innerWithoutMetaData = (instance) => {
    if (Array.isArray(instance)) {
      return instance.map(innerWithoutMetaData);
    }

    if (isObjectLike(instance) && Object.keys(instance).length > 0) {
      const entries = Object.entries(instance)
        .filter(([k]) => k !== '$schema')
        .map(([k, v]) => [k, innerWithoutMetaData(v)]);

      return Object.fromEntries(entries);
    }

    return instance;
  };

  return innerWithoutMetaData(instance);
};

const schemaLessObjectCreator = (nsValue, key, nsObject) => {
  if (Array.isArray(nsObject)) {
    const newArray = [...nsObject];
    newArray[key] = nsValue;
    return newArray;
  }

  return {
    ...nsObject,
    $schema: undefined,
    [key]: nsValue,
  };
};

export const schemaGet = (schema, path, object) => {
  return get(path, object);
};

export const schemaPluck = (schema, ...props) => {
  return pluck(...props);
};

export const schemaPick = (schema, ...props) => {
  return (o) => {
    return props.reduce((acc, prop) => {
      acc[prop] = o[prop];
      return acc;
    }, {});
  };
};

export const schemaSet = (
  schema,
  path,
  value,
  object,
  customizer = schemaLessObjectCreator
) => {
  const obj = set(path, value, object, customizer);
  return castSchema(schema)(obj);
};

export const schemaPut =
  (schema, ...props) =>
  (value, object, customizer = schemaLessObjectCreator) => {
    const obj = put(...props)(value)(object, customizer);
    return castSchema(schema)(obj);
  };

export const schemaUpdate = (schema, path, updater = identity, object = {}) => {
  const previous = get(path, object);
  const next = updater(previous);

  //no change, early bail
  if (previous === next) return object;

  return schemaSet(schema, path, next, object);
};

export const schemaPipe = (schema, customizer = schemaLessObjectCreator) => {
  return (...args) => {
    return pipe(
      ...args.map((fn) => (o) => fn(o, customizer)),
      castSchema(schema)
    );
  };
};
