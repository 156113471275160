const isStringANumber = (s) => s == parseInt(s);
const FUTURE_RANGE_CHAR = '+';
const toRangeAdjective = (isFutureRange) => (isFutureRange ? 'Next' : 'Past');
const unitOfTimeMap = {
  H: 'Hour',
  D: 'Day',
  M: 'Month',
  Y: 'Year',
  W: 'Week',
};

/*
 * The XeDateRangeWidget takes a dateKeys prop that consists of objects with key and range fields
 * If the key has a '+' means the date is in the future
 * If you pass the key 'Custom' on click it will be a popup allowing you to customize a range
 * If you pass the key 'Today' it will return {key, range:from: today, to: tomorrow}
 * If you pass the key 'YTD' it will return {key, range:from: beginningOfYear , to: today}
 * If you pass the key 'All' it will return {key, range:from: 'All', to: 'All'} TODO: Figure out how endpoints use all to maybe refactor how we handle all
 * D = days, M = Months, Y = years
 * onChange returns an object in the form of {key, range:{ to: 'yyyy-mm-dd', from: 'yyyy-mm-dd'}} except for ALL
 *
 * Example use -
  <XeDateRangeWidget
    dateKeys={[
      {key: '1M', range: { from, to }},
      {key: '2M', range: { from, to }},
      {key: '1Y', range: { from, to }}
      {key: 'Custom' range: { from, to}},
    ]}
    value={{key, range: { from, to}}}
    onChange={x => console.log('Range - ',x)}
    className='test-class' - OPTIONAL
  >
 */

/**
 * Generates a tooltip from a given time range label
 * @param {String} tabLabel
 */
export const toTooltip = (tabLabel = '') => {
  //check to see if there is a number in the string
  const isSpecificTimeRange = /\d/.test(tabLabel);
  if (!isSpecificTimeRange) return null;

  const labelWithoutFutureChar = tabLabel.replace(FUTURE_RANGE_CHAR, '');

  const unitOfTime = Array.from(labelWithoutFutureChar).find(
    (c) => !isStringANumber(c)
  );
  const [range, unit] = labelWithoutFutureChar.split(
    new RegExp(`(${unitOfTime})`, 'g')
  );

  return `${toRangeAdjective(tabLabel.includes(FUTURE_RANGE_CHAR))} ${range} ${
    unitOfTimeMap[unit]
  }${range > 1 ? 's' : ''}`;
};
