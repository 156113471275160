import { ofChangedPropWhenExists } from '../frp/operators/ofChangedPropWhenExists';
import { map } from 'rxjs/operators';

export const toMenuNodeRequestFn =
  (...reducers) =>
  (source$) => {
    return source$.pipe(
      ofChangedPropWhenExists('contexts', 'menuNode', 'requestFn'),
      map((baseRequestFn) => {
        return reducers.length
          ? baseRequestFn(...reducers)(/*no more reducers*/)
          : baseRequestFn(/*no more reducers*/);
      })
    );
  };
