import { toUIValid } from '../../schema/UIValidationProjection';
import { useSchema } from '../../schema/SchemaReducer';
import { useReducer, useRef, useEffect } from 'react';
import { identity } from '../../fp/fp';
import { useXeQuery } from '../../data/useXeQuery';
import { browse } from 'services/health-plan-payers/xe-health-plan-payers-svc.js';
import { MaskedComboBox } from '../components/MaskedComboBox';
import { pluck } from '../../fp/object';
import { isNil } from '../../fp/pred';
import { NOOP_FUNCTION } from '../../constants';

/**
 * @typedef {import('services/generated/types').AssetXeHealthPlanPayer} AssetXeHealthPlanPayer
 */

/**
 * @typedef XeHealthPlanPayerWidgetState
 * @property {string} searchText
 * @property {AssetXeHealthPlanPayer} value
 */

/**
 * @typedef XeHealthPlanPayerWidgetAction
 * @property {string} action
 * @property {string | number} value
 */

const SHOULD_SEARCH_BY_TEXT = 'healthplanpayer/shouldSearchByText';
const UPDATE_CURRENT_PLAN_ID = 'healthplanpayer/shouldSelectItem';

/**
 * @param {AssetXeHealthPlanPayer} AssetXeHealthPlanPayer
 * @returns {number}
 */
const valueFn = (AssetXeHealthPlanPayer) =>
  AssetXeHealthPlanPayer?.HealthPlanPayerID;

/**
 *
 * @param {XeHealthPlanPayerWidgetState} state
 * @param {XeHealthPlanPayerWidgetAction} action
 * @returns {XeHealthPlanPayerWidgetState}
 */

const reducer = (state, { type, value } = {}) => {
  if (type === SHOULD_SEARCH_BY_TEXT) {
    return { ...state, searchText: value };
  }

  if (type === UPDATE_CURRENT_PLAN_ID) {
    return { ...state, currentPlanId: value };
  }

  return state;
};

const INITIAL_STATE = {
  searchText: '',
};

export const XeHealthPlanPayerWidget = (props) => {
  const {
    value: valueProp,
    dataPath,
    onChange = NOOP_FUNCTION,
    className,
    onValidation = NOOP_FUNCTION,
    required: propsRequired,
    ...rest
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const { searchText, currentPlanId } = state;

  const {
    value = valueProp,
    onValueChange = onChange,
    onValidationChange = onValidation,
  } = useSchema(dataPath);

  // we need to populate the currentPlanId in state for render purposes whenever the value provided changes(AZ)
  useEffect(() => {
    dispatch({ type: UPDATE_CURRENT_PLAN_ID, value: value });
  }, [value]);

  const comboBoxRef = useRef();

  /**
   * @type {Array.<AssetXeHealthPlanPayer>}
   */
  const { data: results = [] } = useXeQuery(
    browse({ name: searchText, active: true }, identity),
    {
      enabled: searchText?.trim().length > 0,
    }
  );

  /**
   * @type {Array.<AssetXeHealthPlanPayer>}
   */
  const { data: [currentValue] = [] } = useXeQuery(
    browse({ healthPlanPayerId: currentPlanId, active: true }, identity),
    {
      enabled: !isNil(currentPlanId),
      isBackground: true,
    }
  );

  return (
    <MaskedComboBox
      {...rest}
      data-xe-widget-name="XeHealthPlanPayerWidget"
      comboBoxRef={comboBoxRef}
      value={currentValue}
      labelFn={pluck('Name')}
      data={results}
      onChange={(value) => {
        onValueChange(valueFn(value));
        onValidationChange(toUIValid(comboBoxRef.current.validity));
      }}
      onEnter={(value) => dispatch({ type: SHOULD_SEARCH_BY_TEXT, value })}
      className={`${className} flex-1`}
      required={propsRequired}
    />
  );
};
