export const getSchemaProperties = (schema) => {
  const {
    baseSchema: { properties },
  } = schema;

  return properties;
};

// Finds the date format string in a date schema node
export const toDateSchemaNodeFormat = (schemaNode = {}) => {
  if (schemaNode.format) {
    return schemaNode.format;
  }
  // To handle date schemas with oneOf a date type or a null type
  const { oneOf, anyOf } = schemaNode;
  const possibleFormats = oneOf || anyOf || [];
  const dateType =
    possibleFormats.find(({ format }) => format !== undefined) || {};
  return dateType.format;
};
