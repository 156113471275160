import {
  StaffSearchWidgetTypes,
  XeStaffSearchWidget,
} from '../XeStaffSearchWidget';
import { NetworkProviderPicker } from './picker';
import { EMPTY_OBJECT } from '../../constants';
import { isNil } from '../../fp/pred';

export const XeNetworkProviderWidget = (props) => {
  const {
    Picker = NetworkProviderPicker,
    initialFilters = EMPTY_OBJECT,
    ...rest
  } = props;
  const { patientPlanId, withNetworkStatus } = initialFilters;

  return (
    <XeStaffSearchWidget
      {...rest}
      type={StaffSearchWidgetTypes.PROVIDER}
      Picker={Picker}
      canAdd={false}
      initialFilters={initialFilters}
      enableSearchQuery={withNetworkStatus ? !isNil(patientPlanId) : true}
    />
  );
};

export default XeNetworkProviderWidget;
