import { ButtonBar, Panel, TextInput } from '../../../../components';
import { useXeLabels } from '../../../../contexts/XeLabelContext';

const toStatusToggleOptions = ({
  CurrentOnly,
  WithFormerOrTerminated,
} = {}) => [
  {
    label: CurrentOnly,
    value: true,
  },
  {
    label: WithFormerOrTerminated,
    value: false,
  },
];

const StaffSearchAdvanced = (props) => {
  const {
    zip,
    specialty,
    status,
    onUpdateZip,
    onUpdateSpecialty,
    onUpdateStatus,
  } = props;

  const labels = useXeLabels();

  const data = toStatusToggleOptions(labels);
  const selectedItem = data.find(
    (toggleOption) => toggleOption.value === status
  );

  const { Zip, Specialty, SearchStatus } = labels;
  return (
    <Panel
      data-component-name="StaffSearchAdvanced"
      className="staff-search-advanced"
    >
      <div className="display-grid staff-search-advanced__grid">
        <TextInput
          descriptor={Zip}
          descriptorClassName="staff-search-advanced__item"
          value={zip}
          onChange={(value) => onUpdateZip({ value })}
          dataElementName="staffSearchAdvanced__zip"
        />
        <TextInput
          descriptor={Specialty}
          descriptorClassName="staff-search-advanced__item"
          value={specialty}
          onChange={(value) => onUpdateSpecialty({ value })}
          dataElementName="staffSearchAdvanced__specialty"
        />
        <ButtonBar
          dataElementName="staffSearchAdvanced__searchStatus"
          descriptor={SearchStatus}
          descriptorClassName="staff-search-advanced__item"
          data={data}
          selectedItem={selectedItem}
          onSelect={({ value } = {}) => onUpdateStatus({ value })}
          labelFn={({ label } = {}) => label}
          keyFn={({ value } = {}) => value}
        />
      </div>
    </Panel>
  );
};

export default StaffSearchAdvanced;
