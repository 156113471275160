import { useEffect, useReducer } from 'react';
import { browse } from 'services/org-units/xe-org-units-svc';
import { isNil } from '../../fp/pred';
import { Flexbox, UIControlLabel } from '../../components';
import { useXeQuery } from '../../data/useXeQuery';
import { identity } from '../../fp/fp';
import { useSchema } from '../../schema/SchemaReducer';
import { MaskedComboBox } from '../components/MaskedComboBox';
import { NOOP_FUNCTION } from '../../constants';

/**
 * @typedef {import('services/generated/types').OrgUnitDetailResponse} OrgUnitDetailResponse
 */

/**
 * @typedef XeOrgUnitWidgetState
 * @property {string} searchText
 * @property {number} currentOrgUnitID
 */

/**
 * @typedef XeOrgUnitWidgetAction
 * @property {string} action
 * @property {string | number} value
 */

/**
 * @param {OrgUnitDetailResponse} OrgUnitDetailResponse
 * @returns {string}
 */
const OrgUnitDetailResponseRenderer = (OrgUnitDetailResponse) =>
  OrgUnitDetailResponse?.Name;

/**
 * @param {OrgUnitDetailResponse} OrgUnitDetailResponse
 * @returns {number}
 */
const valueFn = (OrgUnitDetailResponse) => OrgUnitDetailResponse?.OrgUnitID;

const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT';

const UPDATE_CURRENT_ORG_UNIT_ID = 'UPDATE_CURRENT_ORG_UNIT_ID';

/**
 *
 * @param {XeOrgUnitWidgetState} state
 * @param {XeOrgUnitWidgetAction} action
 * @returns {XeOrgUnitWidgetState}
 */
const XeOrgUnitWidgetStateReducer = (state, { type, value } = {}) => {
  if (type === UPDATE_SEARCH_TEXT) {
    return { ...state, searchText: value };
  }

  if (type === UPDATE_CURRENT_ORG_UNIT_ID) {
    return { ...state, currentOrgUnitID: value };
  }

  return { ...state };
};

const INITIAL_STATE = {
  searchText: '',
};

export const XeOrgUnitWidget = (props) => {
  const {
    dataElementName,
    value,
    onChange,
    className,
    clearButton,
    disabled,
    itemRender,
    clearOnSelect,
    comboClassName,
    descriptor,
    descriptorClassName,
    required: propsRequired,
    orgUnitTypeId,
    dataPath,
    ...rest
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const [state = {}, dispatch] = useReducer(
    XeOrgUnitWidgetStateReducer,
    INITIAL_STATE
  );

  const { searchText, currentOrgUnitID } = state;

  const {
    onValueChange = onChange,
    // onValidationChange = onValidation,
  } = useSchema(dataPath);

  /**
   * This use effect allows us to override the CurrentOrgUnitID if the OrgUnitID passed
   * in has changed.
   */
  useEffect(() => {
    dispatch({ type: UPDATE_CURRENT_ORG_UNIT_ID, value });
  }, [value]);

  /**
   * This query returns the search results as an array of OrgUnitDetailResponse's any time the search
   * text changes.
   */

  /**
   * @type {Array.<OrgUnitDetailResponse>}
   */
  const { data: searchResults = [] } = useXeQuery(
    browse(
      {
        name: searchText,
        orgUnitTypeId,
        active: true,
      },
      identity
    ),
    {
      enabled: searchText?.trim().length > 0,
    }
  );

  // This query returns an array containing the OrgUnitDetailResponse for the current currentOrgUnitID
  /**
   * @type {Array.<OrgUnitDetailResponse>}
   */
  const { data: [OrgUnitDetailResponse] = [] } = useXeQuery(
    browse(
      {
        orgUnitId: currentOrgUnitID,
        orgUnitTypeId,
        active: true,
      },
      identity
    ),
    { enabled: !isNil(currentOrgUnitID) }
  );

  const labelFn = OrgUnitDetailResponseRenderer;

  return (
    <>
      {descriptor && (
        <UIControlLabel className={descriptorClassName}>
          {descriptor}
        </UIControlLabel>
      )}
      <Flexbox
        direction="row"
        alignItems="center"
        inline={true}
        className={`org-unit-widget ${className}`}
        data-xe-widget-name="XeOrgUnitWidget"
      >
        <MaskedComboBox
          dataElementName={dataElementName}
          {...rest}
          clearOnSelect={clearOnSelect}
          disabled={disabled}
          value={OrgUnitDetailResponse}
          labelFn={labelFn}
          data={searchResults}
          onChange={(value) => {
            dispatch({ type: UPDATE_CURRENT_ORG_UNIT_ID, value: value });
            onValueChange(value);
          }}
          onEnter={(searchText) =>
            dispatch({ type: UPDATE_SEARCH_TEXT, value: searchText })
          }
          className={`${comboClassName} flex-1`}
          clearButton={clearButton}
          itemRender={itemRender}
          comboClassName={comboClassName}
          required={propsRequired}
        />
      </Flexbox>
    </>
  );
};

export default XeOrgUnitWidget;
