/* eslint-disable no-console */
import { isFunction } from '../fp/pred';
import { isShallowEqual } from '../fp/object';

const isCallback = (val, key) =>
  key.indexOf('on') === 0 && key.length > 2 && isFunction(val);

export const doBasicCallbacks = (props, state, prevProps, previousState) => {
  if (!(state && previousState)) return;
  if (isShallowEqual(state, previousState)) return;

  Object.entries(props)
    .filter(([name, value]) => isCallback(value, name))
    .map(([callbackName, fn]) => {
      const modeledName = `${callbackName
        .slice(2, 3)
        .toLowerCase()}${callbackName.slice(3)}`;
      return [modeledName, fn, callbackName];
    })
    .forEach(([modeledName, fn, callbackName]) => {
      if (state[modeledName] != previousState[modeledName]) {
        const { UniqueNodeName = 'Unknown' } = props;
        console.error(
          `modeled property callback ${callbackName} was invoked from ${UniqueNodeName}`
        );
        fn(state[modeledName]);
      }
    });
};
