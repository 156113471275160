import { useScopedSelector } from '../../../../../hooks/scopedReducer';
import { useReducer$ } from '../../../../../hooks/useReducer$';
import { useEffect$ } from '../../../../../hooks/useEffect$';
import { useMenuNode } from '../../../../../contexts/XeMenuNodeContext';
import { identity } from '../../../../../fp/fp';
import { pluck } from '../../../../../fp/object';
import { SchemalessReducer } from '../../../../../schema/SchemalessReducer';
import { schemaGet } from '../../../../../schema/schemaTypeBuilder';
import { useCallback, useMemo, useState } from 'react';
import { ofType } from 'redux-observable';
import {
  map,
  mergeMap,
  pluck as pluckFrp,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { clone } from 'services/patients/xe-patients-svc';
import { Flexbox, IconButton, TextArea } from '../../../../../components';
import { Popup, toDefaultPopupFooter } from '../../../../../components/Popup';
import { useXeLabels } from '../../../../../contexts/XeLabelContext';
import { DUPLICATE } from '../../../../../icons';
import PatientCloneResponse from 'services/schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientCloneResponse.json';
import './styles.css';
import { of } from 'rxjs';

/**
 * @typedef ClonePatientProps
 * @property {number} ipid
 * @property {string} enterpriseId
 * @property {(ipid: number) => void} onClone
 */

const toClonePatientEpic$ = (action$, state$, { menuNode$ }) => {
  return action$.pipe(
    ofType('clonePatient'),
    pluckFrp('value'),
    withLatestFrom(
      menuNode$.pipe(
        pluckFrp('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(
      ([value = { enterpriseId: '', clonedComment: '' }, toRequest$]) => {
        const { ipid, enterpriseId, clonedComment } = value;
        return clone(
          { ipid, enterpriseId, clonedComment },
          toRequest$({ fullRequest: true })
        );
      }
    ),
    map((response) => {
      const ipid = schemaGet(PatientCloneResponse, 'IPID', response);
      return {
        type: 'didClone',
        value: ipid,
      };
    })
  );
};

/**
 * @param {ClonePatientProps} props
 */
export const ClonePatientCellRenderer = (props) => {
  const { ipid, enterpriseId, onClone } = props;

  const schemalessReducerInstance = useMemo(() => {
    return {
      ipid,
      enterpriseId,
    };
  }, [ipid, enterpriseId]);

  const labels = useXeLabels();
  const menuNode = useMenuNode();

  const epicWithDeps = useCallback(
    (action$, state$) => {
      return toClonePatientEpic$(action$, state$, { menuNode$: of(menuNode) });
    },
    [menuNode]
  );

  /**
   * @type {string}
   */
  const userRightId =
    useScopedSelector(pluck('contexts', 'enterprise', 'userData', 'RightID')) ||
    '';
  const canClone = userRightId.includes('CLONE_PATIENT');
  const canCloneNoComment = userRightId.includes('CLONE_PATIENT_NO_COMMENT');

  /**
   * @type {string}
   */
  const userEnterpriseId =
    useScopedSelector(
      pluck('contexts', 'enterprise', 'userData', 'EnterpriseID')
    ) || '';
  const patientInCurrentEnterprise = enterpriseId === userEnterpriseId;

  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [hasValidComment, setHasValidComment] = useState(false);
  const [cloneRequestData, setCloneRequestData] = useState(false);

  const [, dispatch, action$] = useReducer$(identity, epicWithDeps);

  useEffect$(() => {
    return action$.pipe(ofType('didClone'), pluckFrp('value'), tap(onClone));
  }, [action$]);

  const buttonClickHandler = () => {
    if (canCloneNoComment) {
      return dispatch({
        type: 'clonePatient',
        value: cloneRequestData,
      });
    }
    return setShowCommentPopup(true);
  };

  if (
    patientInCurrentEnterprise ||
    (canClone == false && canCloneNoComment == false)
  ) {
    return '';
  }

  return (
    <SchemalessReducer
      initialValue={schemalessReducerInstance}
      onChange={({ instance }) => {
        setCloneRequestData(instance);
        const { clonedComment = '' } = instance;
        setHasValidComment(!!clonedComment.trim().length);
      }}
    >
      <IconButton
        description={labels.Copy}
        icon={DUPLICATE}
        onClick={buttonClickHandler}
      />
      {showCommentPopup ? (
        <Popup
          FooterComponent={toDefaultPopupFooter({
            onClose: () => {
              return setShowCommentPopup(false);
            },
            onConfirm: () => {
              return dispatch({
                type: 'clonePatient',
                value: cloneRequestData,
              });
            },
            disableConfirm: !hasValidComment,
          })}
          title={labels.Comments}
          size="auto"
        >
          <Flexbox direction="column">
            <TextArea
              descriptorClassName="clone-patient-cell-renderer__comments"
              descriptor={labels.Comments}
              required={true}
              dataPath="clonedComment"
            />
          </Flexbox>
        </Popup>
      ) : null}
    </SchemalessReducer>
  );
};
