import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { identity } from '../../fp/fp';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useEnterprise } from '../../contexts/XeEnterpriseContext';
import { IconButton } from '../../components';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { useXeQuery } from '../../data/useXeQuery';
import { SHOULD_SEARCH, SHOULD_UPDATE_STAFF_ID } from './actions';
import reducers from './reducers';
import './styles.css';
import {
  getProviderAndAddressByIds,
  searchProviders as searchStaffs,
  searchAsRightsUser as searchUsers,
} from 'services/staffs/xe-staffs-svc';
import { searchProviders } from 'services/providers/xe-providers-svc';
import { EMPTY_ARRAY, EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { RESOURCE_TYPE_IDS } from './constants';
import { ADD, ADVANCED_SEARCH } from '../../icons';
import { MaskedComboBox } from '../components/MaskedComboBox';
import { isEmpty, isNil, isObjectLike, isFunction } from '../../fp/pred';
import { formatStaffName } from '../../utils';
import { XeStaffSearchPicker } from './picker';
import { AddPopup } from './picker/components/AddPopup';

const staffRenderer = (resource) => {
  if (!resource || isEmpty(resource)) return '';

  const formattedName = formatStaffName(resource);

  const { ExternalID } = resource;
  if (!ExternalID) return formattedName;

  return `${formatStaffName(resource)} (${ExternalID})`;
};

const reducer = combinePredicatedReducers(...reducers);

export const StaffSearchWidgetTypes = {
  STAFF: 'staff',
  PROVIDER: 'provider',
  USER: 'user',
};

const SearchRequestMap = {
  [StaffSearchWidgetTypes.STAFF]: searchStaffs,
  [StaffSearchWidgetTypes.PROVIDER]: searchProviders,
  [StaffSearchWidgetTypes.USER]: searchUsers,
};

const DefaultInitialFilters = {
  resourceTypeId: RESOURCE_TYPE_IDS.ALL,
};

// TODO: (SYNUI-5368) `dataPath` support (JDM)
export const XeStaffSearchWidget = (props) => {
  const {
    dataElementName,
    value,
    enterpriseId,
    labelFn,
    valueFn = identity,
    onChange = NOOP_FUNCTION,
    className,
    disabled,
    clearOnSelect,
    type = StaffSearchWidgetTypes.STAFF,
    Picker = XeStaffSearchPicker,
    initialFilters = EMPTY_OBJECT,
    ['data-xe-widget-name']: dataXeWidgetName = 'XeStaffSearchWidget',
    children,
    enableSearchQuery = true,
    ...rest
  } = props;

  const searchRequestnFn = SearchRequestMap[type];

  const [viewAdvancedSearch, setViewAdvancedSearch] = useState(false);
  const [query, setQuery] = useState('');

  const {
    userData: { RightID: rights },
  } = useEnterprise();

  const [state, dispatch] = useReducer(reducer, EMPTY_OBJECT);
  const { searchText, selectedStaff = {} } = state;
  const { selectedStaffId, selectedStaffEnterpriseId } = selectedStaff;

  const labels = useXeLabels();

  const shouldSelectProvider = useCallback(
    (value) => {
      dispatch({ type: SHOULD_UPDATE_STAFF_ID, value });
    },
    [dispatch]
  );

  const { data: providerResults, isSuccess } = useXeQuery(
    searchRequestnFn(
      {
        ...DefaultInitialFilters,
        ...initialFilters,
        searchText: searchText,
        active: true,
      },
      (x) => x
    ),
    { enabled: enableSearchQuery && state.searchText?.trim().length > 0 }
  );

  const { data: [staffData] = EMPTY_ARRAY, isFetching } = useXeQuery(
    getProviderAndAddressByIds(
      {
        staffId: selectedStaffId,
        staffId_EnterpriseId: selectedStaffEnterpriseId || enterpriseId,
      },
      (x) => x
    ),
    { enabled: enableSearchQuery && !isNil(selectedStaffId) }
  );

  const lastProviderDataRef = useRef(staffData);

  useEffect(() => {
    if (lastProviderDataRef.current !== staffData && !isFetching) {
      lastProviderDataRef.current = staffData;
      onChange(valueFn(staffData));
    }
  }, [isFetching, staffData, valueFn, onChange]);

  useEffect(() => {
    // TODO: NOT GREAT. Widgets _should_ take ids only but we pass in objects
    // We should vet the places that are passing objects as values and fix them as we go,
    // but as it stands, many staff widgets are broken right now (09/15) (JDM)
    const _value = isObjectLike(value)
      ? value
      : {
          StaffID: value,
          EnterpriseID: {
            EnterpriseID: selectedStaffEnterpriseId,
          },
        };
    dispatch({ type: SHOULD_UPDATE_STAFF_ID, value: _value });
  }, [value]);

  return (
    <>
      <MaskedComboBox
        dataElementName={dataElementName}
        {...rest}
        className={`staff-search-widget ${className}`}
        data-xe-widget-name={dataXeWidgetName}
        clearOnSelect={clearOnSelect}
        disabled={disabled}
        value={staffData}
        labelFn={(value) => {
          if (isFunction(labelFn)) {
            return labelFn(value, staffRenderer(value));
          }
          return staffRenderer(value);
        }}
        data={providerResults}
        onChange={shouldSelectProvider}
        onEnter={(value) => dispatch({ type: SHOULD_SEARCH, value })}
        onFilterChange={setQuery}
      >
        {Picker ? (
          <IconButton
            dataElementName="advancedSearch"
            icon={ADVANCED_SEARCH}
            description={labels.AdvancedSearch}
            onClick={() => setViewAdvancedSearch(true)}
            className="base-search-widget__icon-button margin-horizontal-small"
            look="flat"
            disabled={disabled}
          />
        ) : null}
        {children}
        {viewAdvancedSearch && (
          <Picker
            onClose={() => setViewAdvancedSearch(false)}
            onChange={(value) => {
              shouldSelectProvider(value);
              setViewAdvancedSearch(false);
            }}
            initialFilters={{
              ...initialFilters,
              searchText: query,
              resourceTypeId:
                initialFilters.resourceTypeId ||
                Object.values(RESOURCE_TYPE_IDS).join('|'),
            }}
            initialData={providerResults}
          />
        )}
      </MaskedComboBox>
    </>
  );
};

export default XeStaffSearchWidget;
