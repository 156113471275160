import { Icon } from '../../../../components';
import { EMPTY_OBJECT } from '../../../../constants';

import { IS_USER } from '../../../../icons';

export const IsUserCell = ({ data = EMPTY_OBJECT }) => {
  const { XeUserData } = data;
  if (!XeUserData) return '';
  return <Icon icon={IS_USER} />;
};
