import PropTypes from 'prop-types';
import { Flexbox } from '../../../../components/Flexbox';
import { TextInput } from '../../../../components/TextInput';
import { IconButton } from '../../../../components/IconButton';
import { ToolBar } from '../../../../components/ToolBar';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { ADD } from '../../../../icons';
import { withEllipsis } from '../../../../utils';

const PatientSearchHeader = (props) => {
  const {
    showAllFilters,
    onSearch,
    onClear,
    onToggleExpandFilters,
    onViewAddPatient,
    searchEnabled,
    allowAddPatient,
  } = props;

  const labels = useXeLabels();

  return (
    <Flexbox
      data-component-name="PatientSearchHeader"
      justifyContent="space-between"
      alignItems="center"
    >
      <TextInput
        dataElementName="patientSearch"
        descriptor={labels.Search}
        className="margin-right-medium margin-vertical-small flex-1"
        placeholder={withEllipsis(labels.EnterNameOrID)}
        dataPath="searchText"
        autoFocus
        onEnter={onSearch}
      />
      <Flexbox alignItems="center">
        <ToolBar
          isClearDisabled={!searchEnabled}
          isSearchDisabled={!searchEnabled}
          onSearch={onSearch}
          onClear={onClear}
          onExpand={onToggleExpandFilters}
          isExpanded={showAllFilters}
          className="inline-flex"
        />
        {allowAddPatient && (
          <IconButton
            dataElementName="patientSearchHeader__add"
            icon={ADD}
            description={labels.AddPatient}
            onClick={onViewAddPatient}
          />
        )}
      </Flexbox>
    </Flexbox>
  );
};

PatientSearchHeader.propTypes = {
  showAllFilters: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onToggleExpandFilters: PropTypes.func.isRequired,
  onViewAddPatient: PropTypes.func.isRequired,
  searchEnabled: PropTypes.bool.isRequired,
  allowAddPatient: PropTypes.bool.isRequired,
};

export default PatientSearchHeader;
