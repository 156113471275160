import { ofType } from '../../../frp/operators/ofType';
import { combineWithLatestFrom } from '../../../frp/operators/combineWithLatestFrom';
import { map, mergeMap, pluck, filter } from 'rxjs/operators';
import { searchPatient } from 'services/patients/xe-patients-svc';
import {
  RESPONSE_SEARCH_PATIENTS,
  SHOULD_SEARCH,
  UPDATE_CAN_ADD_PATIENT,
} from './actions';
import { isString } from '../../../fp/pred';

const searchPatientsEpic$ = (action$, state$, { menuNode$ }) =>
  action$.pipe(
    ofType(SHOULD_SEARCH),
    pluck('value'),
    filter(({ searchText }) => {
      //Valid values are empty string or string with non space characters
      return (
        !searchText || (isString(searchText) && searchText.trim().length > 0)
      );
    }),
    combineWithLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([value = {}, toRequest$]) => {
      return searchPatient(
        { ...value, isUniquePersonId: true },
        toRequest$({ fullRequest: true })
      );
    }),
    pluck('results'),
    map((value) => {
      return {
        type: RESPONSE_SEARCH_PATIENTS,
        value,
      };
    })
  );

const canAddPatientEpic$ = (action$, state$, { enterprise$ }) =>
  enterprise$.pipe(
    pluck('userData', 'RightID'),
    map((rights) => ({
      type: UPDATE_CAN_ADD_PATIENT,
      value: rights.includes('ADD_PATIENT_FROM_SEARCH'),
    }))
  );

export default [searchPatientsEpic$, canAddPatientEpic$];
