import { DateTime, Duration } from 'luxon';
import { isoStrAsLuxon } from '../g11n/ISODates';

/**
 * @param {string} isoString1 - One of the three server ISO date string formats
 * @return {(isoString2:string) => number}
 */
export const isDateBefore = (isoString1) => (isoString2) => {
  return isoStrAsLuxon(isoString2) < isoStrAsLuxon(isoString1);
};

/**
 * @param {string} isoString1 - One of the three server ISO date string formats
 * @return {(isoString2:string) => number}
 */
export const isDateAfter = (isoString1) => (isoString2) => {
  return isoStrAsLuxon(isoString2) > isoStrAsLuxon(isoString1);
};

/**
 * @param {string} isoStringA - One of the three server ISO date string formats
 * @param {string} isoStringB - One of the three server ISO date string formats
 * @return {1|0|-1} comparison result for a JS sort() comparator
 */
export const isoStringComparator = (isoStringA = '', isoStringB = '') => {
  const luxonDateTimeA = isoStringA === '' ? null : isoStrAsLuxon(isoStringA);
  const luxonDateTimeB = isoStringB === '' ? null : isoStrAsLuxon(isoStringB);

  if (luxonDateTimeA < luxonDateTimeB) {
    return -1;
  }
  if (luxonDateTimeA > luxonDateTimeB) {
    return 1;
  }
  return 0;
};

const ADULT_AGE_FORMAT = `y'y'`;
const ADULT_MIN_AGE = Duration.fromObject({ years: 10 });

const CHILD_AGE_FORMAT = `y'y' M'm'`;
const CHILD_MIN_AGE = Duration.fromObject({ years: 1 });

const TODDLER_AGE_FORMAT = `M'm' d'd'`;
const TODDLER_MIN_AGE = Duration.fromObject({ months: 1 });

const INFANT_AGE_FORMAT = `d'd'`;
const INFANT_MIN_AGE = Duration.fromObject({ days: 1 });

const NEWBORN_AGE_FORMAT = `h'h'`;

const NEGATIVE_AGE_FORMAT = null;

/**
 * @desc ageProjections is an array of tuples consisting of a minimum duration and a projected format
 *
 * Last age projection is meant as a catchall for negative durations
 * (i.e. birthdate is in the future)
 */
const ageProjections = [
  [ADULT_MIN_AGE, ADULT_AGE_FORMAT],
  [CHILD_MIN_AGE, CHILD_AGE_FORMAT],
  [TODDLER_MIN_AGE, TODDLER_AGE_FORMAT],
  [INFANT_MIN_AGE, INFANT_AGE_FORMAT],
  [Duration.fromMillis(0), NEWBORN_AGE_FORMAT],
  [Duration.fromMillis(-Number.MAX_SAFE_INTEGER), NEGATIVE_AGE_FORMAT],
];

/**
 * @param {String} birthIsoStr - One of the three server ISO date string formats representing the birth date
 * @param {String} referenceIsoString - One of the three server ISO date string formats representing the reference date
 * @returns {String} - A string formatted according to the age format strings above
 */
export const isoStringToAge = (birthIsoStr, referenceIsoString) => {
  const birthLuxonDateTime = isoStrAsLuxon(birthIsoStr);
  const referenceLuxonDateTime = referenceIsoString
    ? isoStrAsLuxon(referenceIsoString)
    : DateTime.local();

  return luxonToAge(birthLuxonDateTime, referenceLuxonDateTime);
};

/**
 * @param {DateTime} birthLuxonDateTime - A Luxon DateTime representing the birth date
 * @param {DateTime} referenceLuxonDateTime - A Luxon DateTime representing the reference date
 * @returns {String} - A string formatted according to the age format strings above
 */
export const luxonToAge = (
  birthLuxonDateTime,
  referenceLuxonDateTime = DateTime.local()
) => {
  const duration = referenceLuxonDateTime.diff(birthLuxonDateTime);

  return durationToAge(duration);
};

/**
 * @param {Duration} luxonDuration - A Luxon Duration object
 * @returns {String} - A string formatted according to the age format strings above
 */
const durationToAge = (luxonDuration) => {
  const [, formatString] = ageProjections.find(
    ([minAge]) => luxonDuration > minAge
  );

  // Thrasys wants to render "negative" ages as empty strings (MM)
  if (!formatString) {
    return '';
  }

  return luxonDuration.toFormat(formatString);
};

/**
 *
 * @param {String} startDateString ISOString representing the starting date
 * @param {String} endDateString ISOString representing the end date
 * @param {import('luxon').DurationUnit|import('luxon').DurationUnit[]} unit
 * @param {import('luxon').DiffOptions} opts
 * @returns {Duration}
 */
export const toDurationFromISODateStrings = (
  startDateString,
  endDateString,
  unit,
  opts
) => {
  const startDate = isoStrAsLuxon(startDateString);
  const endDate = isoStrAsLuxon(endDateString);

  return endDate.diff(startDate, unit, opts);
};

/**
 * @param {String} isoDateString ISOString representation of a date
 * @param {import('luxon').DurationUnit|import('luxon').DurationUnit[]} unit
 * @param {import('luxon').DiffOptions} opts
 * @returns {Duration}
 */
export const toDurationFromISODateStringToNow = (isoDateString, unit, opts) =>
  isoStrAsLuxon(isoDateString).diffNow(unit, opts);

// /**
//  *
//  * @param {Duration} duration
//  */
// export const formatDuration = duration => {

// };
