import PropTypes from 'prop-types';
import { Flexbox } from '../../../components';
import { MaskedComboBox } from '../../components/MaskedComboBox';
import { SHOULD_UPDATE_QUERY_STRING } from '../actions';
import { NOOP_FUNCTION } from '../../../constants';

const toEnterpriseName = (enterprise) => {
  if (!enterprise) return '';

  const { EnterpriseName } = enterprise;
  if (!EnterpriseName) return '';

  return EnterpriseName;
};

export const DefaultView = (props) => {
  const {
    dispatch,
    onChange = NOOP_FUNCTION,
    className = '',
    formatter,
    clearOnSelect = false,
    comboClassName,
    results,
    value,
    ...rest
  } = props;

  const labelFn = formatter || toEnterpriseName;

  return (
    <Flexbox
      direction="row"
      alignItems="center"
      className={className}
      data-xe-widget-name="XeEnterpriseSearchWidget"
    >
      <MaskedComboBox
        {...rest}
        value={value}
        labelFn={labelFn}
        data={results}
        onChange={onChange}
        clearOnSelect={clearOnSelect}
        onEnter={(value) =>
          dispatch({ type: SHOULD_UPDATE_QUERY_STRING, value })
        }
        className={`${comboClassName} flex-1`}
      />
    </Flexbox>
  );
};

DefaultView.propTypes = {
  className: PropTypes.string,
  clearButton: PropTypes.bool,
  disabled: PropTypes.bool,
  formatter: PropTypes.func,
  clearOnSelect: PropTypes.bool,
  comboClassName: PropTypes.string,
  onChange: PropTypes.func,
};

export default DefaultView;
