import { identity } from '../fp/fp';
import { get, set } from '../fp/object';

export const toActionValueReducer =
  (key, projection = identity) =>
  (state, { value }) => {
    const existingValue = get(key, state);

    const newValue = projection(value);
    if (existingValue === newValue) return state;

    return set(key, newValue, state);
  };
