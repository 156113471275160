import {
  withSchemaMetadata,
  withoutSchemaMetadata,
} from '../schema/schemaTypeBuilder';
import { getSingletonAJVInstance } from '../schema/jsonSchemaValidator';
import { isObservable } from 'rxjs';
import { pipe } from '../fp/fp';
import { filter as filterObject } from '../fp/object';
import { isNil, isObjectLike } from '../fp/pred';
import { toFetchConfig, toConfigReducer, toHeaderReducer } from './data';
import { HEADER_CONTENT_TYPE } from './constants';

export const headerCleanupReducer = (config = {}) => {
  const { headers = {} } = config;

  const filteredHeaders = filterObject(headers, (h) => h !== undefined);

  return {
    ...config,
    headers: filteredHeaders,
  };
};

export const toContentTypeHeaderReducer =
  (contentTypeMap) =>
  (config = {}) => {
    const { method, headers, body } = config;
    const defaultContentTypeHeaderObject = contentTypeMap[method];

    const contentTypeHeaderObject =
      body instanceof FormData
        ? { [HEADER_CONTENT_TYPE]: undefined }
        : defaultContentTypeHeaderObject;

    if (!contentTypeHeaderObject) return config;

    return {
      ...config,
      headers: {
        ...headers,
        ...contentTypeHeaderObject,
      },
    };
  };

export { toConfigReducer, toHeaderReducer };

export const toCombinedRequestReducer = (...reducers) => {
  return (baseConfig) => {
    //this is a pipe.... but wanted the error handling
    return reducers.reduce((config, toReducer) => {
      if (typeof toReducer !== 'function') {
        console.error('toReducer$ is not a function');
      }
      const reduced = toReducer(config);

      if (isObservable(reduced)) {
        console.error('toReducer returned an Observable');
      }

      return reduced;
    }, baseConfig);
  };
};

export const toPathRootReducer = (pathRoot) => (config) => {
  return {
    ...config,
    url: `${pathRoot}${config.url}`,
  };
};

export const backgroundReducer = toConfigReducer('isBackground')(true);

// Reads response stream types defined in the Fetch API
// https://developer.mozilla.org/en-US/docs/Web/API/Body
const responseBodyReaders = {
  blob: (body) => body.blob(),
  json: (body) => body.json(),
  text: (body) => body.text(),
  arrayBuffer: (body) => body.arrayBuffer(),
  formData: (body) => body.formData(),
};

const toResponseBody = (request = {}, response = {}) => {
  const { responseType } = request;
  const reader = responseBodyReaders[responseType];
  const contentType = response.headers.get('content-type');
  // Server returns no content-type for some text responses when the request.responseType is 'json'
  // Server returns content-type 'application/json' for requests when the request.responseType is 'blob'
  // Server explicityly claims the contentType is text/plain, even though the responseType is 'json' (SYN-15746)
  //Temporary
  if (contentType === 'text/plain' && responseType === 'json') {
    console.warn(
      'Mixmatch of server response types. Treating response as text'
    );
  }

  if (!contentType || !reader || contentType === 'text/plain') {
    return response.text();
  }
  return reader(response);
};

const toErrorBody = (response = {}) => {
  const contentType = response.headers.get('content-type');
  const responseType = (contentType || '').includes('json') ? 'json' : 'text';
  const reader = responseBodyReaders[responseType];
  return reader(response);
};

const createSchemaCompilationSideEffect =
  (jsonSchemaValidator, responseSchema) => () => {
    if (!responseSchema) return;

    const { baseSchema, registerSchema } = responseSchema;

    //ensure it knows what this schema is all about (slow the first time)
    registerSchema(jsonSchemaValidator);

    //Called for compilation side effect
    jsonSchemaValidator.compile(baseSchema);
  };

const withValidationSideEffectAndDecoration = (
  jsonSchemaValidator,
  responseSchema,
  response
) => {
  if (!responseSchema) return response;

  const { baseSchema, registerSchema } = responseSchema;

  //ensure it knows what this schema is all about (slow the first time)
  registerSchema(jsonSchemaValidator);

  const result = !!response && !!response.results ? response.results : response;
  if (result === undefined) {
    return response;
  }

  const valid = Array.isArray(result)
    ? !result.some((v) => !jsonSchemaValidator.validate(baseSchema, v))
    : jsonSchemaValidator.validate(baseSchema, result);

  if (!valid) {
    // No decoration if response doesn't match the schema
    // Removed console warn. See SYNUI-8192
    return response;
  }

  const decorated = withSchemaMetadata(
    responseSchema,
    jsonSchemaValidator
  )(result);

  return !response.results
    ? decorated
    : {
        ...response,
        results: decorated,
      };
};

const withoutBodySchemaMetadata = (o) => {
  if (!isObjectLike(o)) return o;
  const { body } = o;
  //there are a broad range of built ins (e.g. FormData) which advertise as iterable but don't allow you to iterate their properties
  if (
    body === undefined ||
    !isObjectLike(body) ||
    Object.keys(body).length === 0
  )
    return o;
  return {
    ...o,
    body: withoutSchemaMetadata(body),
  };
};

//Currently the default behavior is put the error on the serviceError stream
//which will cause an error toast, using the data message if it exists. In the event
//it does not, we use the default repsonse message.
const displayAndRethrow = (err, serviceErrors$) => {
  const { message, response } = err;

  if (isNil(response)) {
    serviceErrors$.next({ ...err, message: response });
  } else {
    const { data } = response;
    serviceErrors$.next({ ...err, message: data || message });
  }

  throw err;
};

const rethrow = (err) => {
  throw err;
};

const schemaVerificationSideEffect = (jsonSchemaValidator, request) => {
  const { url, requestSchema, body, service = '', action = '' } = request;

  if (requestSchema) {
    const { baseSchema, registerSchema } = requestSchema;

    //ensure it knows what this schema is all about (slow the first time)
    registerSchema(jsonSchemaValidator);
    const valid = jsonSchemaValidator.validate(baseSchema, body);
    if (!valid) {
      console.error(
        '*******************************************************************'
      );
      console.error(
        `* Invalid Object Being Sent to Server via ${service}/${action}`
      );
      console.error(`* ${url}`);
      console.error(jsonSchemaValidator.errors);
      console.error(
        '*******************************************************************'
      );
    }
  }
};

const queryFn = (request) => {
  const { url, ...rest } = request;

  return fetch(url, rest).then((response = {}) => {
    if (response.ok) {
      return toResponseBody(request, response);
    }

    const { status } = response;
    return toErrorBody(response).then((error) => {
      if (isObjectLike(error)) {
        const { data, errorCode } = error;
        const message = String(data);
        console.error(request?.url, message);
        throw { status, message, response, errorCode };
      }

      const message = String(error);
      console.error(message);
      throw { status, message, response };
    });
  });
};

export const toRequestInvoker = ({ serviceErrors$, pathRoot }) => {
  /*toRequest is a complex function that is intended to be heavily prapplied and
    then passed downward for others to use. The following are descriptors of its arguments:

    ({responseType, headers={}, spy={}}={}, fullRequest=false) - The next is a set of overrides that can be applied to the request.
                    This is the first argument with which a feature/component developer may have routine interaction. The developer may
                    alter the response type to facilitate debugging and or add/remove headers. Headers are added by representing the
                    header in the headers object. If the header is represented with a value of undefined, it is removed before the
                    request is sent. Tthe spy propery accepts an object with three keys {requestSpy, responseSpy, errorSpy}.
                    All take the shape of an observer ({next:function, error:function, complete:function})

    serviceDefaultConfig - This request object is intended to work with the Thrasys service pattern which calls the object from within
                    a generated service function. This argument will always be passsed directly from the 'makeRequest' portion of the
                    generated service template and will provide the default configuration object for the service invocation
    */

  const jsonSchemaValidator = getSingletonAJVInstance();
  return (additionalData = {}) => {
    const { spy = {} } = additionalData;
    const { errorSpy = rethrow } = spy;

    return (config) => {
      //we want to run this on the config, when it still has the ids on each schema node
      schemaVerificationSideEffect(jsonSchemaValidator, config);

      const decoratedRequest = pipe(
        toPathRootReducer(pathRoot),
        headerCleanupReducer, //This is quasi-redundant... but keeping it here as we have multiple entrances to this function right now and its safer until that is cleaner
        withoutBodySchemaMetadata,
        toFetchConfig
      )(config);

      const queryFnPromise = queryFn(decoratedRequest);

      //It's plausible that we can do some of the work for our 'about to arrive' response while we are awaiting its arrival, instead of when it arrives
      const { responseSchema } = config;
      queueMicrotask(
        createSchemaCompilationSideEffect(jsonSchemaValidator, responseSchema)
      );

      return queryFnPromise
        .then((body) => {
          const { responseSchema } = config;

          const decoratedResponse = withValidationSideEffectAndDecoration(
            jsonSchemaValidator,
            responseSchema,
            body
          );

          return decoratedResponse;
        })
        .catch((errorResponse) => {
          //By default we log and rethrow.... but someone can now turn that frown upside down and make an error a response if they like
          return errorSpy(errorResponse, serviceErrors$);
        })
        .catch((errorResponse) => {
          const { name } = errorResponse;

          if (name !== 'AbortError') {
            //Do not display abort errors... these are okay and good, means we cancelled useless fetch requests
            return displayAndRethrow(errorResponse, serviceErrors$);
          }

          throw errorResponse;
        });
    };
  };
};
