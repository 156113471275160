import { isArray, isNumber, isObjectLike, isString } from '../fp/pred';
import { pluck } from '../fp/object';

export const getIpid = (ipid) => (isNumber(ipid) ? ipid : pluck('IPID')(ipid));
export const getIvid = (ivid, ipid) => {
  if (isNumber(ivid)) {
    return ivid;
  }
  const { IVID } = ivid || {};
  if (IVID) {
    return IVID;
  }
  if (isNumber(ipid)) return;
  return pluck('CurrentCaseIVID')(ipid);
};

export const getEnterpriseID = (x) => {
  if (!x) return null;
  const { EnterpriseID } = x;
  if (isString(EnterpriseID)) {
    return EnterpriseID;
  }
  if (isObjectLike(EnterpriseID)) {
    const { EnterpriseID: primitiveEnterpriseId } = EnterpriseID;
    return primitiveEnterpriseId;
  }
  return null;
};

export const getVisitAssessmentID = (x) => {
  if (!x) return null;
  const { VisitAssessmentID: outerVisitID } = x;
  if (isNumber(outerVisitID)) {
    return outerVisitID;
  }
  if (isArray(outerVisitID)) {
    const { VisitAssessmentID: innerVisitID } = outerVisitID[0] || {};
    return innerVisitID;
  }
  return null;
};

export const toStaffID = (x) => {
  if (!x) return null;

  return pluck('StaffID', 'StaffID')(x) || pluck('StaffID')(x) || x;
};
