import { get, isShallowEqual } from '../fp/object';
import { identity } from '../fp/fp';
import castPath from 'lodash/_castPath';

import {
  useReducer,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useRef,
  createContext,
} from 'react';

import {
  toJSONSchemaReducer,
  toVerbActionReducer,
  toValidityState,
  UPSERT,
  META_SCHEMA,
  INSTANCE,
  CHANGED_PROPS,
  COMPARISON_STATE,
  VALIDITY,
  DELETE,
} from './JSONSchemaReducer';

import { getSchemaNode } from './schemaTraversal';
import { BehaviorSubject } from 'rxjs';
import { useFactory } from '../hooks/useFactory';
import { isNil, isObjectLike } from '../fp/pred';

import { castDeclarationAtPath } from './schemaCaster';

const EMPTY_OBJECT = Object.freeze({});
const UI_ERROR_MESSAGE = 'Invalid per UI';

export const SchemaContext = createContext();
/*
"CodeTypeID" : {
  "oneOf" : [ {
    "type" : "string"
  }, {
    "$ref" : "com.thrasys.xnet.erp.assets.AssetXeAdmitCategory-AssetXeAppCodeType.json#"
  } ]
},

"XePatientAlertReaction" : {
  "items" : {
    "$ref" : "com.thrasys.xnet.erp.xmlobjects.patientalert.PatientAlertBrowseResponse-XePatientAlertReaction.json#"
  },
  "type" : "array"
}

"SeverityID" : {
  "$ref" : "com.thrasys.xnet.erp.xmlobjects.patientalert.PatientAlertBrowseResponse-SeverityID.json#"
},
*/

export const toSchemaNode = (path, schema = {}) => {
  const { baseSchema: { properties: schemaProperties = {} } = {} } = schema;

  return get(path, schemaProperties);
};

const isAcceptableSchemaType = (declaration, instanceName) => {
  const { ['$ref']: ref, items, type, oneOf, anyOf } = declaration;

  //We are worried about three shapes ('oneOf', 'array' and direct ref)
  if (ref !== undefined) {
    return `${instanceName}#` === ref;
  } else if (type === 'array') {
    return isAcceptableSchemaType(items, instanceName);
  } else if (Array.isArray(anyOf) || Array.isArray(oneOf)) {
    const oneOrAnyOf = anyOf || oneOf;
    return oneOrAnyOf.some((dec) => isAcceptableSchemaType(dec, instanceName));
  }

  return false;
};

const toFullPath = (parentPath = '', path) => {
  return parentPath.length > 0 ? `${parentPath}.${path}` : path;
};

export const SchemaProducer = (props) => {
  const {
    onChange,
    initialValue,
    validator,
    schema = EMPTY_OBJECT,
    toJsonReducer = toJSONSchemaReducer,
  } = props;

  const schemaReducer = useFactory(() => {
    return toJsonReducer(initialValue, schema, validator);
  }, [initialValue, schema, validator, toJsonReducer]);

  //consider redux pattern of calling with no action no state to get our initial reducer state...
  //maybe less than ideal but will work
  const [state, dispatch] = useReducer(schemaReducer, undefined, schemaReducer);

  const touchedReducer = useFactory(() => {
    return toVerbActionReducer({});
  }, []);

  const [touchedState, touchedDispatch] = useReducer(touchedReducer, {});

  const uiValidityReducer = useFactory(() => {
    const verbActionReducer = toVerbActionReducer({});
    return (state, action = {}) => {
      const { type, path = '', value } = action;
      // TODO: (SYNUI-5477) We need to revisit why we are doing this.
      // lodash makes assumptions with strings vs integers so we ended up with a uiValidityState represented
      // as an array instead of an object (JDM)
      const pathSegments = castPath(path);
      const stringSegments = pathSegments.map((ps) => `_${ps}`);
      const newAction = {
        type,
        value,
        path: stringSegments.join('.'),
      };
      return verbActionReducer(state, newAction);
    };
  }, []);

  const [uiValidityState, uiValidityDispatch] = useReducer(
    uiValidityReducer,
    {}
  );

  // NOTE: We made the conscious decision to only call onChange if the state changes,
  // not if a new onChange function is provided. This case did not seem to exist and this was
  // causing other concerns.
  useEffect(() => {
    if (onChange) {
      const {
        valid: schemaAndBusinessValid,
        validity: schemaAndBusinessValidity,
      } = state;

      const uiValid = Object.keys(uiValidityState).length === 0;
      const valid = schemaAndBusinessValid && uiValid;

      const validity = uiValid
        ? schemaAndBusinessValidity
        : toValidityState(uiValidityState, schemaAndBusinessValidity);

      onChange({ uiValidityState, ...state, valid, validity });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiValidityState, state]);

  const contextValue$ = useFactory(
    () =>
      new BehaviorSubject({
        dispatch,
        state,
        fullPath: '',
        schema,
        touchedState,
        touchedDispatch,
        uiValidityState,
        uiValidityDispatch,
      }),
    [schema, dispatch]
  );

  useEffect(() => {
    contextValue$.next({
      dispatch,
      state,
      fullPath: '',
      schema,
      touchedState,
      touchedDispatch,
      uiValidityState,
      uiValidityDispatch,
    });
  }, [
    dispatch,
    state,
    schema,
    touchedState,
    touchedDispatch,
    uiValidityState,
    uiValidityDispatch,
    contextValue$,
  ]);

  return (
    <SchemaContext.Provider value={contextValue$}>
      {props.children}
    </SchemaContext.Provider>
  );
};

export const SchemaConsumerProducer = (props) => {
  const { dataPath, schema = EMPTY_OBJECT } = props;
  const [, forceRender] = useReducer((s) => s + 1, 0);

  const parentContext$ = useContext(SchemaContext);
  const lastParentContextValue = useRef();

  useEffect(() => {
    const subscription = parentContext$.subscribe((value) => {
      const { fullPath: nextFullPath, state: { instance: nextInstance } = {} } =
        value || {};
      const { fullPath: prevFullPath, state: { instance: prevInstance } = {} } =
        lastParentContextValue.current || {};

      if (
        !lastParentContextValue.current ||
        !isShallowEqual(
          get(toFullPath(nextFullPath, dataPath), nextInstance),
          get(toFullPath(prevFullPath, dataPath), prevInstance)
        )
      ) {
        lastParentContextValue.current = value;
        forceRender({});
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [parentContext$, dataPath]);

  if (!parentContext$.value) {
    console.error(
      `SchemaReducer Declared with a dataPath of ${dataPath} but outside of a parent SchemaReducer`
    );
  }

  const {
    fullPath: parentPath,
    dispatch,
    schema: parentSchema,
  } = parentContext$.value || {};

  const fullPath = toFullPath(parentPath, dataPath);
  const { declaration: schemaNode, referencedSchemas } =
    getSchemaNode(parentSchema, dataPath) || EMPTY_OBJECT;

  useEffect(() => {
    const { name } = schema;

    if (name) {
      //We were directly provided a schema, so we should use that one
      //as the developer should know best
      const acceptable = isAcceptableSchemaType(schemaNode, name);
      if (!acceptable) {
        console.error(
          `Schema ${name} is not compatible with Schema at ${fullPath}`
        );
      } else {
        //This is a little strange but we can only know a subschema (in the case of things like an any)
        //Once we get to a schemahook that defines it. So, in this case, once we can define it, we go
        //ahead and tell the reducer about it... which changes the default values as well as the validation
        //rules
        dispatch({
          type: `${META_SCHEMA}/${UPSERT}`,
          path: fullPath,
          value: schema,
        });
      }
    } else {
      //We were not provided a schema.... we are attempting to infer it
      const { $ref } = schemaNode;
      const resolvedSchema = referencedSchemas.find(
        ({ name }) => `${name}#` === $ref
      );
      if (!resolvedSchema) {
        console.error(`Schema is not compatible with Schema at ${fullPath}`);
      } else {
        dispatch({
          type: `${META_SCHEMA}/${UPSERT}`,
          path: fullPath,
          value: resolvedSchema,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaNode, schema, fullPath, dispatch]);

  const currentParentValue = parentContext$.value;
  const contextValue$ = useFactory(() => {
    return new BehaviorSubject({
      ...currentParentValue,
      schema,
      fullPath,
    });
  }, [schema, dispatch]);

  useEffect(() => {
    contextValue$.next({
      ...currentParentValue,
      schema,
      fullPath,
    });
  }, [currentParentValue, schema, fullPath, contextValue$]);

  return (
    <SchemaContext.Provider value={contextValue$}>
      {props.children}
    </SchemaContext.Provider>
  );
};

export const SchemaReducer = (props) => {
  const {
    dataPath,
    initialValue,
    dangerouslyRetainCompletelyOutdatedState = false,
  } = props;

  let counter = useRef(0);

  const schemaCounter = useMemo(() => {
    counter.current++;

    const schemaKey = `schemaCounter_${counter.current}`;

    return schemaKey;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const additionalProps = !dangerouslyRetainCompletelyOutdatedState
    ? {
        key: schemaCounter,
      }
    : {};

  return dataPath === undefined ? (
    <SchemaProducer {...props} {...additionalProps} />
  ) : (
    <SchemaConsumerProducer {...props} />
  );
};

const emptyHook = {};

/**
 * @typedef SchemaHookValue
 * @property {string} fullPath
 * @property {any} value
 * @property {(action: { type: string, [x: string]: any }) => void} dispatch
 * @property {() => void} onValueChange
 * @property {() => void} onTouched
 * @property {() => void} onValidationChange
 * @property {() => void} onCleanup
 * @property {Object} schemaNode
 * @property {boolean} required
 * @property {boolean} changed
 * @property {boolean} touched
 * @property {string} validityMessage
 */

/**
 * @param {string} path
 * @return {SchemaHookValue}
 */
const InternalSchemaHook = (path) => {
  const contextValue$ = useContext(SchemaContext);
  if (!contextValue$) {
    throw new Error(
      'No schema contextValue$ is available in InternalSchemaHook. Are you trying to use schema features outside a SchemaReducer?'
    );
  }

  const lastContextValue = useRef();
  const [, forceRender] = useReducer((s) => s + 1, 0);

  useEffect(() => {
    const subscription = contextValue$.subscribe((value) => {
      const {
        fullPath: nextFullPath,
        state: { instance: nextInstance, [VALIDITY]: nextValidity } = {},
        touchedState: nextTouchedState,
      } = value || {};
      const {
        fullPath: prevFullPath,
        state: { instance: prevInstance, [VALIDITY]: prevValidity } = {},
        touchedState: prevTouchedState,
      } = lastContextValue.current || {};

      const nextPropPath = toFullPath(nextFullPath, path);
      const prevPropPath = toFullPath(prevFullPath, path);

      if (
        !(lastContextValue.current && lastContextValue.current.state) ||
        !(
          (
            isShallowEqual(
              get(nextPropPath, nextInstance),
              get(prevPropPath, prevInstance)
            ) && //instance changed
            isShallowEqual(
              get(nextPropPath, nextValidity),
              get(prevPropPath, prevValidity)
            ) && //validity changed
            isShallowEqual(
              get(nextPropPath, nextTouchedState),
              get(prevPropPath, prevTouchedState)
            )
          ) //touch changed
        )
      ) {
        lastContextValue.current = value;
        forceRender({});
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [contextValue$, path]);

  const {
    fullPath: parentPath = '',
    state,
    dispatch,
    schema,
    touchedState,
    touchedDispatch,
    uiValidityDispatch,
  } = contextValue$.value || {};

  const fullPath = toFullPath(parentPath, path);

  const {
    [INSTANCE]: instance,
    [VALIDITY]: validityProps,
    [CHANGED_PROPS]: changedProps,
    [COMPARISON_STATE]: comparisonState,
  } = state || {};

  const memoizedTouchedCallback = useCallback(() => {
    if (touchedDispatch) {
      touchedDispatch({ path: fullPath, type: UPSERT, value: true });
    }
  }, [touchedDispatch, fullPath]);

  const memoizedUIValidityCallback = useCallback(
    (value, message) => {
      if (uiValidityDispatch) {
        uiValidityDispatch({
          path: fullPath,
          type: value ? DELETE : UPSERT,
          value: message || UI_ERROR_MESSAGE,
        });
      }
    },
    [uiValidityDispatch, fullPath]
  );

  const memoizedValueCallback = useCallback(
    (value, type = UPSERT) => {
      if (dispatch) {
        dispatch({
          path: fullPath,
          value,
          type,
        });
      }
    },
    [dispatch, fullPath]
  );

  const defaultValue = get(fullPath, comparisonState);

  const memoizedCleanupCallback = useCallback(() => {
    if (dispatch) {
      /*
       * Delete the path only if the default value is nil. Otherwise, restore the default on cleanup
       * Safeguard to prevent data loss in the event of an erroneous render cycle (JDM)
       */
      if (isNil(defaultValue)) {
        dispatch({
          path: fullPath,
          type: DELETE,
        });
      } else {
        dispatch({
          path: fullPath,
          type: UPSERT,
          value: defaultValue,
        });
      }
    }

    if (uiValidityDispatch) {
      uiValidityDispatch({
        path: fullPath,
        type: DELETE,
      });
    }

    if (touchedDispatch) touchedDispatch({ path: fullPath, type: DELETE });
  }, [dispatch, defaultValue, fullPath, uiValidityDispatch, touchedDispatch]);

  //This breaks immediately if path itself is undefined. Since, technically, an empty string is valid,
  //we don't want to break, but warn.
  //if (path === undefined) return emptyHook;

  const value = get(fullPath, instance);
  const changed = get(fullPath, changedProps);
  const touched = get(fullPath, touchedState);
  const validityMessage = get(fullPath, validityProps);

  const { declaration: schemaNode, required } =
    getSchemaNode(schema, path) || EMPTY_OBJECT;

  if (
    contextValue$.value &&
    fullPath &&
    !schemaNode &&
    schema &&
    Object.keys(schema).length
  ) {
    console.warn(`Asked to reduce for invalid Schema Node at ${fullPath}`);
  }

  if (path === null) {
    console.warn(`Null value provided as path. This in invalid.`);
  }

  const hookValue = useMemo(() => {
    return {
      fullPath,
      value,
      dispatch,
      onValueChange: memoizedValueCallback, // callback
      onTouched: memoizedTouchedCallback, //onTouched
      onValidationChange: memoizedUIValidityCallback, //onValidated
      onCleanup: memoizedCleanupCallback,
      schemaNode,
      required,
      changed,
      touched,
      validityMessage,
    };
  }, [
    fullPath,
    value,
    dispatch,
    memoizedValueCallback,
    memoizedTouchedCallback,
    memoizedUIValidityCallback,
    memoizedCleanupCallback,
    schemaNode,
    required,
    changed,
    touched,
    validityMessage,
  ]);

  return hookValue;
};

/**
 * @param {string} [path]
 * @return {Partial<SchemaHookValue>}
 */
export const useSchema = (path) => {
  if (path === undefined) return emptyHook;

  return InternalSchemaHook(path);
};

/** @TODO Pretty sure this type def is wrong/copypasta... confirm/fix (CJP)  */
/**
 * @param {string} path
 * @return {Partial<SchemaHookValue>}
 */
export const useSchemaSelector = (selectorFn = identity) => {
  const contextValue$ = useContext(SchemaContext);
  if (!contextValue$) {
    throw new Error(
      'No schema contextValue$ is available in useSchemaSelector. Are you trying to use schema features outside a SchemaReducer?'
    );
  }

  const lastContextValue = useRef();
  const [, forceRender] = useReducer((s) => s + 1, 0);

  // Using this hook at the first level of depth in a schema reducer returns `undefined` since `fullPath` at this point is ''.
  // While ugly, this change should be fine but adding this note for reference in case it becomes a problem (JDM)
  const _get = (path, object) =>
    path === '' ? get(undefined, object) : get(path, object);

  useEffect(() => {
    const subscription = contextValue$.subscribe((value) => {
      const { fullPath: nextFullPath, state: { instance: nextInstance } = {} } =
        value || {};
      const { fullPath: prevFullPath, state: { instance: prevInstance } = {} } =
        lastContextValue.current || {};

      if (
        !(lastContextValue.current && lastContextValue.current.state) ||
        !isShallowEqual(
          selectorFn(_get(nextFullPath, nextInstance)),
          selectorFn(_get(prevFullPath, prevInstance))
        )
      ) {
        lastContextValue.current = value;
        forceRender({});
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [contextValue$, selectorFn]);

  const { fullPath, state } = contextValue$.value || {};

  const { [INSTANCE]: instance } = state || {};

  const hookValue = useMemo(() => {
    return selectorFn(_get(fullPath, instance));
  }, [fullPath, instance, selectorFn]);

  return hookValue;
};

export const useSchemaDispatch = (appendPath = true) => {
  const contextValue$ = useContext(SchemaContext);
  const lastContextValue = useRef();
  const [, forceRender] = useReducer((s) => s + 1, 0);
  useEffect(() => {
    const subscription = contextValue$.subscribe((value) => {
      const { dispatch } = value;
      if (
        !lastContextValue.current ||
        lastContextValue.current.dispatch !== dispatch
      ) {
        lastContextValue.current = value;
        forceRender({});
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [contextValue$]);

  const { value: { dispatch, fullPath, schema } = {} } = contextValue$;

  const memoizedDispatch = useCallback(
    (v) => {
      //if (!appendPath || !fullPath) return dispatch(v);
      if (!appendPath || !fullPath) {
        if (!isObjectLike(v) && !isObjectLike(v.value)) {
          return dispatch(v);
        }

        const { type, value, path } = v;
        const caster = castDeclarationAtPath(schema, path);

        const cast = {
          type,
          path,
          value: caster(value),
        };

        return dispatch(cast);
      }

      const { path: providedPath } = v;

      return dispatch({
        ...v,
        path:
          typeof providedPath === 'string'
            ? toFullPath(fullPath, providedPath)
            : fullPath,
      });
    },
    [appendPath, dispatch, fullPath, schema]
  );

  return memoizedDispatch;
};
