import { EMPTY_OBJECT } from '../../constants';
import {
  XeStaffSearchWidget,
  StaffSearchWidgetTypes,
} from '../XeStaffSearchWidget';

export const XeUserWidget = (props) => {
  const { canAdd = false, initialFilters = EMPTY_OBJECT, ...rest } = props;

  return (
    <XeStaffSearchWidget
      {...rest}
      canAdd={canAdd}
      type={StaffSearchWidgetTypes.USER}
      Picker={null}
      initialFilters={{
        resourceTypeId: undefined,
        ...initialFilters,
      }}
      data-xe-widget-name="XeCMUserSearchWidget"
    />
  );
};
