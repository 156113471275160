import { TextInput, IconButton, Flexbox } from '../../../../components';
import {
  SEARCH,
  CLEAR_FILTERS,
  EXPAND_COMPONENT,
  COLLAPSE_COMPONENT,
  ADD,
} from '../../../../icons';
import { useXeLabels } from '../../../../contexts/XeLabelContext';

const StaffSearchHeader = ({
  showAllFilters,
  onSearch,
  onClear,
  onToggleExpandFilters,
  onUpdateSearchText,
  staffName,
  searchEnabled,
  showAdd,
  shouldAddNewItem,
  isClearFilterDisabled,
}) => {
  const labels = useXeLabels();
  const { Name, Search, Expand, Collapse, AddStaff } = labels;

  const expandIcon = showAllFilters ? COLLAPSE_COMPONENT : EXPAND_COMPONENT;

  return (
    <Flexbox
      data-component-name="StaffSearchHeader"
      className="staff-search-header"
      alignItems="center"
    >
      <div className="flex-container margin-right-medium">
        <IconButton
          description={showAllFilters ? Collapse : Expand}
          className="staff-search-header__icon-button icon-button"
          onClick={onToggleExpandFilters}
          icon={expandIcon}
          dataElementName="staffSearchHeader__toggleExpand"
        />
        <IconButton
          icon={SEARCH}
          onClick={onSearch}
          description={Search}
          disabled={!searchEnabled}
          className="staff-search-header__icon-button icon-button"
          dataElementName="staffSearchHeader__search"
        />
        <IconButton
          icon={CLEAR_FILTERS}
          description={labels.Clear}
          disabled={isClearFilterDisabled}
          className="staff-search-header__icon-button icon-button"
          onClick={onClear}
          dataElementName="staffSearchHeader__clear"
        />
      </div>
      <div>
        <TextInput
          descriptor={Name}
          placeholder={`${Search}...`}
          value={staffName}
          onChange={(value) => onUpdateSearchText({ value })}
          autoFocus={true}
          onEnter={onSearch}
          dataElementName="staffSearchHeader__name"
        />
      </div>
      <div>
        {showAdd && (
          <IconButton
            dataElementName="add"
            icon={ADD}
            description={AddStaff}
            onClick={shouldAddNewItem}
          />
        )}
      </div>
    </Flexbox>
  );
};

export default StaffSearchHeader;
