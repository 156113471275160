import { isEmpty, isFunction, isNil } from '../../fp/pred';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState, cloneElement } from 'react';
import { ComboBox } from '../../components/ComboBox';
import { isEnterPress } from '../../components/ComboBox/utils';
import { useXeLabels } from '../../contexts/XeLabelContext';
import { castFunction, identity } from '../../fp/fp';
import { EMPTY_ARRAY, NOOP_FUNCTION } from '../../constants';

const baseClassName = 'xjs-masked-combo-box';

export const MaskedComboBox = (props) => {
  const {
    labelFn = identity,
    value,
    className,
    clearOnSelect = false,
    onFilterChange = NOOP_FUNCTION,
    dataElementName = '',
    clearButton,
    data = EMPTY_ARRAY,
    comboBoxRef,
    onChange = NOOP_FUNCTION,
    isLoading,
    isFetched,
    onEnter,
    ...rest
  } = props;

  const labels = useXeLabels();

  const [maskValue, setMaskValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [openedState, setOpenedState] = useState(false);
  const [query, setQuery] = useState('');

  const _internalComboBoxRef = useRef(null);
  const valueLabel = isNil(value) ? '' : labelFn(value);

  useEffect(() => {
    setMaskValue(clearOnSelect ? '' : valueLabel);
    setQuery(clearOnSelect ? '' : valueLabel);
  }, [valueLabel, setQuery, value, clearOnSelect]);

  useEffect(() => {
    if (isLoading && isFocused) {
      setOpenedState(true);
    }
  }, [isFocused, isLoading]);

  useEffect(() => {
    if (Array.isArray(data) && data.length === 1) {
      /*
       * This approach is meant to have a standard way of auto-selecting the first (if only)
       * item that comes back from the server. We've duplicated this in a lot of widgets, but that's fragile.
       * The combo box handles everything selection-related and we don't accurately cover all of the bases
       * in the places where we do the auto-selection. This is meant to defer to the combo box by
       * manually triggering the item click function (JDM)
       */
      const comboBoxRef = _internalComboBoxRef.current;
      const { handleItemClick } = comboBoxRef;

      handleItemClick(0, undefined);
    }
  }, [data]);

  const filter = isFocused ? query : maskValue;

  return (
    <ComboBox
      placeholder={`${labels.Search}...`}
      {...rest}
      opened={openedState}
      ref={(ref) => {
        _internalComboBoxRef.current = ref;
        if (comboBoxRef && 'current' in comboBoxRef) {
          return (comboBoxRef.current = ref);
        }
        if (isFunction(comboBoxRef)) {
          return comboBoxRef(ref);
        }
      }}
      data={data}
      clearOnSelect={clearOnSelect}
      clearButton={clearButton && !isEmpty(value) && !clearOnSelect}
      labelFn={labelFn}
      value={value}
      className={className ? `${baseClassName} ${className}` : baseClassName}
      filter={filter}
      listNoDataRender={(noDataElement) => {
        if (isLoading) {
          return cloneElement(noDataElement, undefined, labels.Loading);
        }
        return noDataElement;
      }}
      onFilterChange={(filter) => {
        setQuery(filter);
        onFilterChange(filter);
      }}
      onBlur={() => {
        if (!clearOnSelect) {
          setMaskValue(labelFn(value) || '');
        }
        setIsFocused(false);
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      onEnter={(ev) => {
        castFunction(onEnter)(ev);
        setOpenedState(true);
      }}
      onOpen={() => {
        if (isFetched || (isLoading && isFocused)) {
          setOpenedState(true);
        }
      }}
      onClose={(ev) => {
        const { nativeEvent } = ev;
        if (isEnterPress(nativeEvent)) {
          return;
        }
        setOpenedState(false);
      }}
      onChange={(nextValue) => {
        setOpenedState(false);
        return onChange(nextValue);
      }}
      dataElementName={dataElementName}
    />
  );
};

MaskedComboBox.propTypes = {
  ...ComboBox.propTypes,
  filter: PropTypes.string,
  clearOnSelect: PropTypes.bool,
  onFilterChange: PropTypes.func,
  dataElementName: PropTypes.string,
  data: PropTypes.array,
};
