import { Spinner } from '../components/Spinner';

import { createPortal } from 'react-dom';
import { createElement } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';

const toIndicator = (onShow, onHide) =>
  createElement(
    'div',
    {
      className: 'blocker flex align-center',
      key: 'Mask',
    },
    createElement(Spinner, { onShow, onHide })
  );

const foregroundQueryFilter = {
  predicate: (query) => {
    const { options: { isBackground = false } = {} } = query;

    return !isBackground;
  },
};

export const toLoadingMask = (maskRoot) => (props) => {
  const { onShow, onHide } = props;
  const isFetching = useIsFetching(foregroundQueryFilter);
  const isMutating = useIsMutating(foregroundQueryFilter);

  return isFetching || isMutating
    ? createPortal(toIndicator(onShow, onHide), maskRoot)
    : null;
};

export default toLoadingMask;
