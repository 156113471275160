import { SHOULD_CLOSE_ADD_PATIENT } from './actions';
import {
  toMenuNode,
  MenuNodeLeafFeature,
} from '../../../contexts/XeMenuNodeContext/components';
import { useEnterprise } from '../../../contexts/XeEnterpriseContext';

export const XeAddPatientPopupWrapper = (props) => {
  const { dispatch, onSelectPatient } = props;
  const { userData: { XeAppMenus = [] } = {} } = useEnterprise();

  // TODO: This is still questionable because we have to hard-code XePatientSearch here, in order to then search
  // through it's menu nodes. In the long-term, it might be better to just have the server defined a default add patient feature (JCM).
  const patientSearchNode = toMenuNode(
    XeAppMenus,
    ({ HtmlComponentName } = {}) => HtmlComponentName === 'XePatientSearch'
  );
  const addPatientNode = patientSearchNode?.XeAppMenus?.[0];

  if (!addPatientNode) return null;

  return (
    <MenuNodeLeafFeature
      menuNodeId={addPatientNode.MenuNodeID}
      onNewPatient={onSelectPatient}
      onClose={() => dispatch({ type: SHOULD_CLOSE_ADD_PATIENT })}
      popupOpen
    />
  );
};

export default XeAddPatientPopupWrapper;
