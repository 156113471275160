import { isFunction } from '../fp/pred';
import { identity } from '../fp/fp';

const toTrue = () => true;
const whenSymbol = Symbol('when');
const toLinearPred = (preds) => {
  if (!preds) return toTrue;
  return preds.filter(identity).reduce(
    (singlePred, pred) =>
      (...args) =>
        singlePred(...args) && pred(...args)
  );
};

const flatten = (acc, v) => (Array.isArray(v) ? [...acc, ...v] : [...acc, v]);

export const isWhen = ({ type }) => type === whenSymbol;
export const when = (...leftPreds) => {
  leftPreds.forEach((fn) => {
    console.assert(isFunction(fn), 'Non-predicate passed to when()');
  });

  return (...rightSides) => {
    //  Options,
    //  1) we have multiple preds on the right side
    //  2) we have a single pred on the right side
    //  3) we have a single projection on the right side
    //  4) we have a a mix of preds and projections
    const asLinearPreds = rightSides.reduce(flatten, []).map((rightSide) => {
      const { type } = rightSide || {};

      const { predicate: rightPredicate, fn: rightFn } =
        type === whenSymbol ? rightSide : { fn: rightSide };

      const predicate = toLinearPred([...leftPreds, rightPredicate]);
      return {
        predicate,
        fn: rightFn,
        type: whenSymbol,
      };
    });

    return asLinearPreds;
  };
};
