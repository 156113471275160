import { toUIValid } from '../../schema/UIValidationProjection';
import { identity } from '../../fp/fp';
import { isNil, isObjectLike } from '../../fp/pred';
import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { useSchema } from '../../schema/SchemaReducer';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useCleanup } from '../../hooks/useCleanup';
import { SHOULD_UPDATE_IPID } from './actions';
import DefaultView from './DefaultView';
import reducers from './reducers';
import { useXeQuery } from '../../data/useXeQuery';
import { autoComplete, getPatient } from 'services/patients/xe-patients-svc';
import { EMPTY_ARRAY, EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { WILDCARD_STRING } from './constants';

const reducer = combinePredicatedReducers(...reducers);

const toIsKendoComponentValid = (component) => {
  if (!component) return true;
  const {
    required,
    value,
    validity,
    validity: { valid },
  } = component;
  if (!required && valid) {
    return toUIValid({ ...validity, valueMissing: false });
  }
  // SYNUI-8095; For some reason, MaskedComboBox => ComboBox reports valueMissing as false when internal value is undefined (AZ)
  return toUIValid({ ...validity, valueMissing: !value });
};

export const XePatientSearchWidget = (props) => {
  const {
    dataElementName,
    value: propsValue,
    dataPath,
    valueFn = identity,
    onChange = NOOP_FUNCTION,
    className,
    clearButton,
    disabled,
    itemRender,
    required: propsRequired,
    autofocus,
    descriptor,
    descriptorClassName,
    onValidation = NOOP_FUNCTION,
    clearOnSelect = false,
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const {
    value = propsValue,
    onValueChange = onChange,
    onCleanup = NOOP_FUNCTION,
    onValidationChange = onValidation,
    required = false,
  } = useSchema(dataPath);

  const [{ selectedIPID, searchText = '' }, dispatch] = useReducer(
    reducer,
    EMPTY_OBJECT
  );

  //This is purely for managing changes from the outside world OR from the schema reducer
  useEffect(() => {
    // Values can come in as both objects and integers right now, so we need to compensate for both.
    // In a perfect world, widgets give and recieve only primary keys but that's not the case today (JDM)
    const { IPID: nextValue } = isObjectLike(value)
      ? value
      : {
          IPID: value,
        };
    dispatch({ type: SHOULD_UPDATE_IPID, value: nextValue });
  }, [value]);

  const [autoCompleteDataState, setAutoCompleteDataState] =
    useState(EMPTY_ARRAY);

  const {
    data: autoCompleteData,
    isLoading,
    isFetched,
  } = useXeQuery(
    autoComplete({ searchText, isUniquePersonId: true }, (x) => x),
    {
      enabled:
        searchText.trim().length > 2 || searchText.trim() == WILDCARD_STRING,
    }
  );

  const ipid = parseInt(selectedIPID);
  const { data: [patientData] = EMPTY_ARRAY, isFetching } = useXeQuery(
    getPatient({ ipid }, (x) => x),
    {
      enabled: !isNaN(ipid),
    }
  );

  useEffect(() => {
    setAutoCompleteDataState(autoCompleteData ?? EMPTY_ARRAY);
  }, [autoCompleteData]);

  useCleanup(onCleanup);

  const comboBoxRef = useRef();

  const lastPatientDataIPID = useRef(patientData?.IPID);
  const lastIPIDValidity = useRef(comboBoxRef?.current?.validity?.valid);

  useEffect(() => {
    /**
     * In cases where the patientData was modified and saved, the getPatient query will
     * invalidate causing a service call which will return a new instance of the patientData object.
     * In this case since the data objects are the same, just different instances, we do not count this
     * as a change from the XePatientSearch widget and will not call our value change callbacks.
     *
     * In certain cases the validation is changing after the patientData, so we also need a separate
     * check for that case (DAK)
     */
    if (lastPatientDataIPID.current !== patientData?.IPID && !isFetching) {
      lastPatientDataIPID.current = patientData?.IPID;
      if (!patientData) {
        onValueChange(null); // use null instead of undefined as null is a existy value w/ no value SNET-917 (AZ)
        setAutoCompleteDataState(EMPTY_ARRAY);
      } else {
        onValueChange(valueFn(patientData));
      }
    }
    const currentIPIDValidity = comboBoxRef?.current?.validity?.valid;
    if (lastIPIDValidity.current !== currentIPIDValidity) {
      lastIPIDValidity.current = currentIPIDValidity;
      onValidationChange(toIsKendoComponentValid(comboBoxRef.current));
    }
  }, [
    patientData,
    valueFn,
    onValueChange,
    onValidationChange,
    isFetching,
    required,
  ]);

  return (
    <DefaultView
      isLoading={isLoading}
      isFetched={isFetched}
      dataItemKey="IPID"
      value={patientData}
      results={autoCompleteDataState}
      comboBoxRef={comboBoxRef}
      autofocus={autofocus}
      dataElementName={dataElementName}
      dispatch={dispatch}
      className={className}
      clearButton={clearButton}
      clearOnSelect={clearOnSelect || isNil(value)}
      disabled={disabled}
      itemRender={itemRender}
      required={required || propsRequired}
      descriptor={descriptor}
      descriptorClassName={descriptorClassName}
    />
  );
};
