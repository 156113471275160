import { ButtonBar } from '../../../../components/ButtonBar';
import { Panel } from '../../../../components/Panel';
import { TextInput } from '../../../../components/TextInput';
import { Checkbox } from '../../../../components/Checkbox';
import { useXeLabels } from '../../../../contexts/XeLabelContext';
import { RESOURCE_TYPE_IDS } from '../../../XeStaffSearchWidget/constants';

const toScopeToggleOptions = ({ All, Organization, Individual } = {}) => [
  { label: All, value: RESOURCE_TYPE_IDS.ALL },
  { label: Organization, value: RESOURCE_TYPE_IDS.ORGANIZATION },
  { label: Individual, value: RESOURCE_TYPE_IDS.INDIVIDUAL },
];

export const ProviderSearchAdvanced = (props) => {
  const {
    zip,
    specialty,
    inNetworkOnly,
    resourceTypeId,
    active,
    onUpdateZip,
    onUpdateSpecialty,
    onUpdateInNetworkOnly,
    onUpdateScope,
    onUpdateActive,
  } = props;

  const labels = useXeLabels();

  const scopeOptions = toScopeToggleOptions(labels);
  const selectedScope = scopeOptions.find(
    (option) => option.value === resourceTypeId
  );

  const { Zip, Specialty, InNetwork, Scope, Active } = labels;
  return (
    <Panel
      data-component-name="StaffSearchAdvanced"
      className="staff-search-advanced"
    >
      <div className="display-grid staff-search-advanced__grid">
        <TextInput
          dataElementName="zipInput"
          descriptor={Zip}
          descriptorClassName="staff-search-advanced__item"
          value={zip}
          onChange={(value) => onUpdateZip({ value })}
        />
        <TextInput
          dataElementName="specialtyInput"
          descriptor={Specialty}
          descriptorClassName="staff-search-advanced__item"
          value={specialty}
          onChange={(value) => onUpdateSpecialty({ value })}
        />
        <ButtonBar
          dataElementName="scopeButtonBar"
          descriptor={Scope}
          descriptorClassName="staff-search-advanced__item"
          data={scopeOptions}
          selectedItem={selectedScope}
          onSelect={({ value } = {}) => onUpdateScope({ value })}
          labelFn={({ label } = {}) => label}
          keyFn={({ value } = {}) => value}
        />
        <Checkbox
          dataElementName="inNetwork"
          label={InNetwork}
          className="staff-search-advanced__item staff-search-advanced__checkbox"
          checked={inNetworkOnly}
          onChange={onUpdateInNetworkOnly}
        />
        <Checkbox
          dataElementName="searchStatusCheckbox"
          label={Active}
          className="staff-search-advanced__item staff-search-advanced__checkbox"
          checked={active}
          onChange={onUpdateActive}
        />
      </div>
    </Panel>
  );
};
