import { combinePredicatedReducers } from '../../connection/toConnectionDef';
import { isType } from '../../connection/toConnectedComponent';
import { toActionValueReducer } from '../../reducers';
import { when } from '../../predication/when';
import { isNil } from '../../fp/pred';
import { useReducer, useRef } from 'react';
import { searchResources } from 'services/user-datas/xe-user-datas-svc';
import { UIControlLabel } from '../../components';
import { EMPTY_ARRAY, EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { useEnterprise } from '../../contexts/XeEnterpriseContext';
import { useXeQuery } from '../../data/useXeQuery';
import { MaskedComboBox } from '../components/MaskedComboBox';
import { useSchema } from '../../schema/SchemaReducer';
import { identity } from '../../fp/fp';
import { toUIValid } from '../../schema/UIValidationProjection';

const REQUEST_STAFF = 'request/getStaff';
const RESPONSE_STAFF = 'response/getStaff';
const CLEAR_RESULTS = 'action/clearResults';

const resultReducers = [
  when(isType(RESPONSE_STAFF))(toActionValueReducer('staffResults')),
  when(isType(CLEAR_RESULTS))(toActionValueReducer('staffResults', () => [])),
  when(isType(REQUEST_STAFF))(toActionValueReducer('searchTerm')),
];

const reducer = combinePredicatedReducers(...resultReducers);

export const XeDelegationSearchWidget = (props) => {
  const {
    dataElementName = '',
    className = '',
    value,
    valueFn = identity,
    onChange = NOOP_FUNCTION,
    dataPath,
    descriptor,
    descriptorClassName,
    required,
    formRequired,
    isDirectorySearch = false,
    withColon,
    onValidation = NOOP_FUNCTION,
    filters = EMPTY_OBJECT,
    ...rest
  } = props;

  if (!!onChange && !(onChange == NOOP_FUNCTION) && dataPath) {
    console.warn(
      '[DEV WARNING] onChange callback will not work when specifiying a dataPath to ensure the SchemaReducer is the source of truth'
    );
  }

  const enterprise = useEnterprise();
  const {
    userData: { EnterpriseID: enterpriseId },
  } = enterprise;

  const [state = {}, dispatch] = useReducer(reducer, EMPTY_OBJECT);

  const { searchTerm = '' } = state;

  const comboBoxRef = useRef();

  const { onValueChange = onChange, onValidationChange = onValidation } =
    useSchema(dataPath);

  const { data: staffResults = EMPTY_ARRAY } = useXeQuery(
    searchResources(
      Object.assign(
        {},
        {
          searchTerm: searchTerm,
          resourceTypeId: 'STAFF',
          isDirectorySearch,
        },
        !isDirectorySearch ? { enterpriseId } : {},
        filters
      ),
      (x) => x
    ),
    { enabled: searchTerm.trim().length > 0 }
  );

  return (
    <>
      <UIControlLabel
        dataElementName={
          dataElementName !== '' ? dataElementName + '__label' : ''
        }
        className={descriptorClassName}
        required={formRequired}
        withColon={withColon}
      >
        {descriptor}
      </UIControlLabel>
      <MaskedComboBox
        {...rest}
        dataElementName={dataElementName}
        comboBoxRef={comboBoxRef}
        clearOnSelect={isNil(value)}
        value={value}
        // (SNET-850) Syntax here is to have the required prop absent if
        // formRequired is false, as opposed to undefined. DAK
        formRequired={formRequired}
        required={required}
        onEnter={(value) => dispatch({ type: REQUEST_STAFF, value })}
        onChange={(value) => {
          onValueChange(valueFn(value));
          dispatch({ type: CLEAR_RESULTS });
          onValidationChange(toUIValid(comboBoxRef.current.validity));
        }}
        data={staffResults}
        className={`${className} stretch-x`}
        data-xe-feature-name="XeDelegationSearchWidget"
        data-xe-widget-name="XeDelegationSearchWidget"
      />
    </>
  );
};

export default XeDelegationSearchWidget;
